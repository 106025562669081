import { create } from 'zustand'
import { getSessionId, isMe, MY_DEFAULT_UID } from '../utils'
import { getMyUid, imVIP, isMyProfileSet } from '../utils/my'
import { hideBadUrlsInDialog } from '../modules/chat/actions/checkChatMessage'
import { addUnreadDirectMessage, getDialog } from '../utils/chat/dialogUtil'
import { ALL_LOADED, LOADED, LOADING } from '../utils/api/dating/datingApi'
import { GETRequest, POSTRequest } from '../utils/api/requestWrapper'
import { getConfig } from '../utils/urlUtils'
import { getUser, loadUsers } from '../utils/userLoader'
import { isPanelType } from '../modules/app/actions'
import { NEW_DIALOG_PANEL } from '../components/Panels/types'
import { setStoreWrapper } from '../utils/StoreManager'
import { isCyber } from '../utils/cyber/cyberUtil'
import { MESSAGE_TYPE_SYSTEM, MESSAGE_TYPE_UPLOAD } from '../utils/manyConst'
import { usersVoices } from '../utils/ai/textToSpeech'
import { aiAvatarStore } from './aiAvatarStore'
import { checkAutoStartDialog } from '../utils/chat/autoDialog'
import { imAdmin, isSupport } from '../utils/adminUtil'
import { checkDialogMsgPermission, PRIVATE_MSG_PERMISSION } from '../utils/subscriptionUtil'
import { getChatDraft, setChatDraft } from '../utils/inputUtil'
import { checkOnline, getOnlineStatusForAmplitude, getStreamStatusForAmplitude } from '../modules/socket/actions'
import { datingNeedProfile } from '../modules/posts/signals/postLike'
import { checkNotification, giftNotificationWithTimeout } from '../modules/auth/actions/notificationUtil'
import { getLocaleValue } from '../utils/localeUil'
import { getInCollector } from './useMyStore'
import { getUserAgeRating } from '../utils/ageRating/ageRatingUtil'
import { isBuffActive } from '../utils/buffUtils'
import { sendAmplitudeEvent } from '../utils/GAEvent'
import { GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18 } from '../modules/buffs'
import { checkEnergy, ENERGY_CHAT, ENERGY_DIALOG, getEnergyDialog } from '../utils/energy/energyUtil'

export const ALL_MEDIA_DIALOG = 'allMediaDialog'
let typingTimeout
const TYPING_TIMEOUT = 20000

const useDialogsStore = create(set => ({
    dialogs: {},            //в формате uid: {} - объект с диалогами этого юзера
    dialogsPreviews: {},    //в формате uid: [] - массив с превью с диалогов этого юзера
    dialogsPage: {},
    loadingStatus: {},
    lastLoadedUid: '',
    lastLoadedDialog: {},
    typingCyber: '',
    companionUid: '',
    smoothScroll: '',
    unreadMessages: {},
    setCompanionUid: uid => {
        set({ companionUid: uid })
    },
    setTypingCyber: typingCyber => {
        set({ typingCyber })
    },
    clearMyDialogs: () => {
        set({
            dialogs: {},
            dialogsPreviews: {},
            dialogsPage: {},
            loadingStatus: {},
            lastLoadedUid: '',
            lastLoadedDialog: {},
            typingCyber: '',
            companionUid: '',
            smoothScroll: '',
            unreadMessages: {}
        })
    },
    getPage: (uid, preview) => {
        return useDialogsStore.getState().dialogsPage[uid + preview] || 1
    },
    setPage: (uid, preview, page) => {
        const { dialogsPage } = useDialogsStore.getState()
        dialogsPage[uid + preview] = page
        set({ dialogsPage })
    },
    getLoadingStatus: (uid, preview) => {
        const key = preview ? uid + 'preview' : uid
        return useDialogsStore.getState().loadingStatus[key]
    },
    setLoadingStatus: (uid, preview, status) => {
        const { loadingStatus } = useDialogsStore.getState()
        if (preview) {
            loadingStatus[uid + 'preview'] = status
        } else {
            loadingStatus[uid] = status
        }

        set({ loadingStatus })
    },
    setDialog: (dialogHash, dialog, fullUpdate) => {
        const { dialogs } = useDialogsStore.getState()
        let currentDialog
        if (!dialogs[dialogHash]) {
            currentDialog = {
                messages: dialog.dialog || [],
                last_read: dialog.last_read,
                dialog_id: dialog.dialog_id,
                users_in_dialog: dialog.users_in_dialog
            }
        } else {
            /** TODO защита от дубликатов сообщений, в теории их быть не должно
             dialogsPreviews[uid] = currentDialogs.concat(dialogs.filter(dialog => {
             return !currentDialogs.find(oldDialog => oldDialog.companionUid === dialog.companionUid)
             }))
             */
            currentDialog = dialogs[dialogHash]
            if (!currentDialog.dialog_id) {
                currentDialog.dialog_id = dialog.dialog_id
            }
            currentDialog.messages = currentDialog.messages.concat(dialog.dialog || [])
        }

        dialogs[dialogHash] = currentDialog
        set({ dialogs })
    },
    setDialogsPreviews: (uid, dialogs, fullUpdate) => {
        const { dialogsPreviews } = useDialogsStore.getState()
        const currentDialogs = (dialogsPreviews[uid] || [])
        /** защита от дубликатов дилогов, в теории их быть не должно, поэтому отключим
         dialogsPreviews[uid] = currentDialogs.concat(dialogs.filter(dialog => {
         return !currentDialogs.find(oldDialog => oldDialog.companionUid === dialog.companionUid)
         }))
         */
        dialogsPreviews[uid] = fullUpdate ? dialogs : (dialogs || []).concat(currentDialogs)
        set({ dialogsPreviews })
    },
    getDialogsPreviews: (uid) => {
        const { dialogsPreviews, loadDialogsPreviews, getLoadingStatus } = useDialogsStore.getState()
        //свои диалоги работают без UID что бы можно было грузить их не дожидаясь авторизации
        if (!uid) {
            uid = MY_DEFAULT_UID
        }
        if (!dialogsPreviews[uid] && !getLoadingStatus(uid, true)) {
            loadDialogsPreviews(uid)
        }
        return dialogsPreviews[uid] || []
    },
    getMyUnseenDialogs: () => {
        const { getDialogsPreviews } = useDialogsStore.getState()
        const dialogs = [...getDialogsPreviews()]
        return dialogs.filter(dialog => dialog.unread_message_count)
    },
    loadDialogsPreviewsOnScroll: () => {
        const {
            lastLoadedUid,
            loadDialogsPreviews
        } = useDialogsStore.getState()

        loadDialogsPreviews(lastLoadedUid)
    },
    getMySeenDialogs: () => {
        const { getDialogsPreviews } = useDialogsStore.getState()
        const dialogs = [...getDialogsPreviews()]
        return dialogs.filter(dialog => !dialog.unread_message_count)
    },
    loadDialogsPreviews: uid => {
        const { getLoadingStatus, setLoadingStatus, getPage, setPage, setDialogsPreviews } = useDialogsStore.getState()

        //свои диалоги работают без UID что бы можно было грузить их не дожидаясь авторизации
        if (!uid) {
            uid = MY_DEFAULT_UID
        }

        //если нет сессии то ничего не делаем
        if (isMe(uid) && !getSessionId()) {
            return
        }
        if (getLoadingStatus(uid, true)) return

        set({ lastLoadedUid: uid })
        setLoadingStatus(uid, true, LOADING)
        const page = getPage(uid, true)

        GETRequest(getConfig('dialogsUrl') + '/api/v1/dialogs?page=' + page + (isMe(uid) ? '' : '&uid=' + uid), (data) => {
            setPage(uid, true, page + 1)
            const dialogs = data.dialogs
            if (dialogs.length === 0) {
                setLoadingStatus(uid, true, ALL_LOADED)
                return
            }

            dialogs.forEach(d => {
                d.companionUid = d.users_in_dialog.find(u => isMe(uid) ? !isMe(u) : u !== uid) || getMyUid()
                d.message = hideBadUrlsInDialog(d.message)
            })

            const usersToLoad = dialogs.map(dialog => {
                if (dialog.unread_message_count && isMe(uid)) {
                    addUnreadDirectMessage(dialog.companionUid, dialog.unread_message_count)
                }
                return dialog.users_in_dialog
            }).flat(1)

            loadUsers(
                usersToLoad,
                () => {
                    setDialogsPreviews(uid, dialogs)
                    setLoadingStatus(uid, true, dialogs.length === 0 ? ALL_LOADED : '')
                }
            )
        })
    },
    addGiftMessage: (from, toUid, giftId) => {
        useDialogsStore.getState().addDialogMessage(toUid, {
        date: new Date().toISOString(),
            message: giftId,
            message_id: new Date().toISOString(),
            message_type: 'gift',
            uid: from
    })
    },
    addDialogMessage: (toUid, msgData) => {
        const {
            getDialogsPreviews,
            setDialogsPreviews,
            companionUid,
            dialogs,
            getDialogHash,
            getDialog
        } = useDialogsStore.getState()
        const dialogsPreviews = getDialogsPreviews()
        let dialogPreview = dialogsPreviews.find(dialog => dialog.companionUid === toUid)

        if (msgData && !msgData.message_id) {
            msgData.message_id = msgData.id
        }
        if (!dialogPreview) {
            dialogPreview = {
                companionUid: toUid,
                users_in_dialog: [toUid, getMyUid()],
                unread_message_count: 0
            }
            if (msgData.dialog_id) {
                dialogPreview.dialog_id = msgData.dialog_id
            }
            dialogsPreviews.push(dialogPreview)
        }
        dialogPreview.date = new Date().toISOString()
        dialogPreview.last_message_id = msgData.id
        dialogPreview.message = msgData.message
        dialogPreview.metadata = msgData.metadata
        dialogPreview.message_type = msgData.message_type
        dialogPreview.translations = msgData.translations
        dialogPreview.uid = msgData.uid
        if (!isMe(msgData.uid) && companionUid !== toUid) {
            dialogPreview.unread_message_count++
            addUnreadDirectMessage(companionUid, 1)
        }
        setDialogsPreviews(MY_DEFAULT_UID, dialogsPreviews, true)
        const dialog = getDialog(MY_DEFAULT_UID, dialogPreview.companionUid, dialogPreview.dialog_id)
        const dialogUniqCash = getDialogHash(MY_DEFAULT_UID, dialogPreview.companionUid, dialogPreview.dialog_id)
        if (dialog) {
            dialog.messages.unshift(msgData)
            dialogs[dialogUniqCash] = dialog
            set({ dialogs })
        }

        //для плавного скролла к сообщению
        set({ smoothScroll: msgData.message_id })
    },
    deleteDialog: (dialogId, companionUid) => {
        const {
            getDialogsPreviews,
            setDialogsPreviews
        } = useDialogsStore.getState()
        setDialogsPreviews(MY_DEFAULT_UID, getDialogsPreviews().filter(dialog => {
            if (dialogId) {
                return dialog.dialog_id !== dialogId
            }
            if (companionUid) {
                return dialog.companionUid !== companionUid
            }
        }), true)
    },
    setSeenDialog: uid => {
        const {
            getDialogsPreviews,
            setDialogsPreviews
        } = useDialogsStore.getState()
        const dialogsPreviews = getDialogsPreviews()
        const dialog = dialogsPreviews.find(dialog => dialog.companionUid === uid)
        if (!dialog) {
            return
        }
        dialog.unread_message_count = 0
        setDialogsPreviews(MY_DEFAULT_UID, dialogsPreviews, true)
    },
    getDialogHash: (dialogOwnerUid, dialogCompanion, dialogId) => {
        if (dialogOwnerUid === ALL_MEDIA_DIALOG) {
            return ALL_MEDIA_DIALOG
        }
        if (isMe(dialogOwnerUid)) {
            return dialogOwnerUid + dialogCompanion
        }
        return dialogOwnerUid + dialogCompanion + dialogId
    },
    loadDialog: (dialogOwnerUid, dialogCompanion, dialogId) => {
        const {
            getPage,
            getLoadingStatus,
            setPage,
            getDialogHash,
            setDialog,
            setLoadingStatus
        } = useDialogsStore.getState()

        if (!dialogOwnerUid) {
            dialogOwnerUid = MY_DEFAULT_UID
        }
        const dialogUniqCash = getDialogHash(dialogOwnerUid, dialogCompanion, dialogId)
        if (getLoadingStatus(dialogUniqCash)) return

        let url
        const page = getPage(dialogUniqCash)
        if (dialogOwnerUid === ALL_MEDIA_DIALOG) {
            url = getConfig('dialogsUrl') + '/api/v1/dialogs/media?page=' + page
        } else if (isMe(dialogOwnerUid)) {
            url = getConfig('dialogsUrl') + '/api/v1/dialogs/uid/' + dialogCompanion + '?page=' + page
        } else {
            url = getConfig('dialogsUrl') + '/api/v1/dialogs/' + dialogId + '?uid=' + dialogOwnerUid + '&page=' + page
        }
        set({ lastLoadedDialog: { dialogOwnerUid, dialogCompanion, dialogId } })
        setLoadingStatus(dialogUniqCash, undefined, LOADING)
        GETRequest(url, (data) => {
            setPage(dialogUniqCash, undefined, page + 1)
            // if (data.dialog.length === 0) {
            //     setLoadingStatus(dialogUniqCash, true, ALL_LOADED)
            //     return
            // }
            const usersToLoad = []
            if (dialogOwnerUid !== ALL_MEDIA_DIALOG) {
                usersToLoad.concat(data.users_in_dialog)
            }
            if (!data.dialog_id && data.dialog.length > 0) {
                data.dialog_id = data.dialog[0].dialog_id
                // data.users_in_dialog = data.users_in_dialog
                // dialog.message = dialog.messages[0].message
                // dialog.date = dialog.messages[0].date
            }

            data.dialog.forEach(msg => {
                //TODO загрузка списка юзеров для админского запроса

                if (dialogOwnerUid === ALL_MEDIA_DIALOG) {
                    usersToLoad.push(msg.uid)
                }
                if (isMe(msg.uid) || imAdmin()) return
                msg.message = hideBadUrlsInDialog(msg.message)
                if (msg.translations) {
                    for (const key in msg.translations) {
                        msg.translations[key] = hideBadUrlsInDialog(msg.translations[key])
                    }
                }
            })

            if (page === 1 && data.dialog.length < 20 && isCyber(dialogCompanion)) {
                data.dialog.push({
                    type: MESSAGE_TYPE_SYSTEM,
                    message_id: 'info',
                    text: 'ai_friend_descr_' + dialogCompanion
                })
            }
            if (data.to_user_ai_info) {
                if (data.to_user_ai_info.voice) {
                    usersVoices[dialogCompanion] = data.to_user_ai_info.voice
                }

                if (data.to_user_ai_info.avatar_upload_time) {
                    aiAvatarStore.getState().setUserVideoAvtar(dialogCompanion, {
                        'idle-video': 'https://ft-ai-video-avatars.akamaized.net/avatars/' + dialogCompanion + '/idle.mp4?v=' + data.to_user_ai_info.avatar_upload_time,
                        'talk-video': 'https://ft-ai-video-avatars.akamaized.net/avatars/' + dialogCompanion + '/talk.mp4?v=' + data.to_user_ai_info.avatar_upload_time
                    })
                }
            }
            loadUsers(
                usersToLoad,
                () => {
                    setDialog(dialogUniqCash, data, data.dialog_id)

                    setLoadingStatus(dialogUniqCash, false, data.dialog.length === 0 ? ALL_LOADED : '')
                    checkAutoStartDialog(dialogCompanion)
                }
            )
        })
    },
    getDialog: (dialogOwnerUid, dialogCompanion, dialogId) => {
        const { dialogs, getDialogHash, loadDialog, getLoadingStatus } = useDialogsStore.getState()
        if (!dialogOwnerUid || isMe(dialogOwnerUid)) {
            dialogOwnerUid = MY_DEFAULT_UID
        }
        const dialogUniqCash = getDialogHash(dialogOwnerUid, dialogCompanion, dialogId)
        if (!dialogs[dialogUniqCash] && !getLoadingStatus(dialogUniqCash)) {
            loadDialog(dialogOwnerUid, dialogCompanion, dialogId)
        }
        return dialogs[dialogUniqCash]
    },
    updateLastRead: (data)=> {
        const { dialogs, getDialogHash, getDialog } = useDialogsStore.getState()
        const dialog = getDialog(MY_DEFAULT_UID, data.uid)
        if (dialog) {
            if (!dialog.last_read) {
                dialog.last_read = {}
            }
            dialog.last_read[data.uid] = data.last_read
            dialogs[getDialogHash(MY_DEFAULT_UID, data.uid, '')] = dialog
            set({ dialogs })
        }
    },
    loadDialogOnScroll: () => {
        const {
            lastLoadedDialog,
            loadDialog
        } = useDialogsStore.getState()

        loadDialog(lastLoadedDialog.dialogOwnerUid, lastLoadedDialog.dialogCompanion, lastLoadedDialog.dialogId)
    },
    sendDialogMessage: (stickerId, fileData, messageMedia) => {
        const {
            companionUid,
            getDialogHash,
            addDialogMessage,
            getDialog
        } = useDialogsStore.getState()
        if (!checkDialogMsgPermission(companionUid)) {
            return
        }
        if (!checkEnergy(ENERGY_DIALOG, getEnergyDialog(companionUid))) {
            return
        }
        const dialogUniqCash = getDialogHash(MY_DEFAULT_UID, companionUid, '')
        const text = messageMedia ? messageMedia.message : getChatDraft(dialogUniqCash)
        if (!text && !stickerId && !fileData) {
            return
        }

        if (!isMyProfileSet() &&
            !imVIP() &&
            !isSupport(companionUid) &&
            !isMe(companionUid) &&
            !imAdmin() &&
            !isCyber(companionUid) &&
            checkOnline(companionUid)
        ) {
            datingNeedProfile('need_set_profile_message')
            return
        }

        checkNotification(companionUid)
        giftNotificationWithTimeout(companionUid)
        if (isCyber(companionUid)) {
            useDialogsStore.getState().setTypingCyber(companionUid)
            if (typingTimeout) {
                clearTimeout(typingTimeout)
                typingTimeout = setTimeout(() => {
                    if (useDialogsStore.getState().typingCyber) {
                        useDialogsStore.getState().setTypingCyber(null)
                    }
                }, TYPING_TIMEOUT)
            }
        }

        const message = {
            date: new Date().toISOString(),
            uid: getMyUid()
        }

        if (stickerId) {
            message.message = stickerId
            message.message_type = 'sticker'
        } else if (fileData && !messageMedia) {
            message.message = getLocaleValue('file_type_post')
            message.message_type = MESSAGE_TYPE_UPLOAD
            message.fileData = fileData
        } else {
            message.message = text
            message.message_type = messageMedia ? (messageMedia.message_type ||  'text') : 'text'
        }

        message.message_id = message.message + message.message_type
        //локальное добавление диалога
        if (!messageMedia) {
            addDialogMessage(companionUid, message)
        }

        setChatDraft(dialogUniqCash, '')

        //если это файлдата, то нужно показать сообщение только локально, это процесс выгрузки фото
        if (fileData && !messageMedia) {
            //TODO запрос на списание монет за отправку файла
            return
        }

        const value = {
            uids_to: [companionUid],//isMe(companionUid) ? [companionUid] : [companionUid, getMyUid()],
            message: message.message,
            message_type: message.message_type
        }

        // if (message.cost) {
        //     value.cost = message.cost
        // }

        if (getInCollector('aiMode') || isMe(companionUid) || isCyber(companionUid)) {
            value.ai_chat_mode = true
        }

        POSTRequest(getConfig('dialogsUrl') + '/api/v1/dialogs', value, (result) => {
            const { dialog_id, message_id } = result.result

            const dialog = getDialog(MY_DEFAULT_UID, companionUid, dialog_id)
            const dialogUniqCash = getDialogHash(MY_DEFAULT_UID, companionUid, dialog_id)
            if (dialog && dialog.messages) {
                dialog.messages.forEach(m => {
                    if (messageMedia) {
                        if (m.fileData && m.fileData.selectedFileUrl && fileData && m.fileData.selectedFileUrl === fileData.selectedFileUrl) {
                            m.message_id = message_id
                            m.dialog_id = dialog_id
                        }
                    } else if (m === message) {
                        m.message_id = message_id
                        m.dialog_id = dialog_id
                    }
                })

                const dialogs = useDialogsStore.getState().dialogs
                dialogs[dialogUniqCash] = dialog
                set({ dialogs })
            }
            if (!dialog.dialog_id) {
                dialog.dialog_id = dialog_id
            }

            const user = getUser(companionUid) || {}
            const amData = {
                sent_coins: 0,
                subscription_type: '-1',
                age_rating: getUserAgeRating(companionUid),
                online: getOnlineStatusForAmplitude(companionUid),
                stream: getStreamStatusForAmplitude(companionUid),
                cyber: isCyber(companionUid),
                counterparty_id: companionUid || '-1',
            }

            if (isMe(companionUid)) {
                amData.self_message = true
            }
            amData.message_category_1 = 'send_private'
            if (user.subscriptionSettings) {
                amData.subscription_type = user.subscriptionSettings[PRIVATE_MSG_PERMISSION] || '-1'
            }

            if (isBuffActive(companionUid, GOOD_FOR_16) ||
                isBuffActive(companionUid, GOOD_FOR_18)) {
                amData.message_category_2 = 'privateMsgToBroadcasterCostPlus'
                sendAmplitudeEvent('message', amData)
            } else if (isBuffActive(companionUid, GOOD_FOR_12) ||
                isBuffActive(companionUid, GOOD_FOR_14)) {
                amData.message_category_2 = 'privateMsgToBroadcasterCost'
                sendAmplitudeEvent('message', amData)
            } else {
                amData.message_category_2 = 'privateMsgCost'
                sendAmplitudeEvent('message', amData)
            }
        })
    }
}))

export default useDialogsStore
