import { create } from 'zustand'

export const NEW_MATCH_PARAM = 'newMatchCount'
export const NEW_FOLLOWER_PARAM = 'newFollowersCount'
export const NEW_POSTS_COUNT = 'newPostsCount'

const useMyStore = create(set => ({
    collector: {},
    setInCollector: (key, value) => {
        const { collector } = useMyStore.getState()
        collector[key] = value
        set({ collector })
    },
    clearCollector: () => {
        set({ collector: {} })
    }
}))

export function setInCollector(key, value) {
    useMyStore.getState().setInCollector(key, value)
}

export function getInCollector(key, defaultValue) {
    return useMyStore.getState().collector[key] || defaultValue
}

export const clearCollector = () => {
    useMyStore.getState().clearCollector()
}

export default useMyStore
