import { state } from 'cerebral'

export default ({ props, store, get }) => {
  if (!props.recipient ||
    props.recipient.length < 32 ||
    props.recipient == get(state`auth.uid`)) {
    store.set('chat.toId', '')
  } else {
    store.set('chat.toId', props.recipient)
  }
}
