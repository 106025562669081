import signals from './signals'

export default {
  state: {
    disable: false,
    successfullyLoaded: false,
    additionalShopMessage: '',
    haveBonus: false,
    openReason: null,
    lastClickedPack: null,
    items: []
  },
  sequences: signals
}
