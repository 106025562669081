import { getState, setStoreWrapper } from '../StoreManager'
import { getMyUid, loginCheckWithPanelOpen } from '../my'
import { checkDialogMsgPermission } from '../subscriptionUtil'
import config from '@/config'
import { getSessionId } from '../cookies'
import { sendDialogClick } from '../amplitudeLiveEvents'
import { createDialogPanel } from '../../components/Panels/createPanelUtil'
import useDialogsStore from '../../zustand/useDialogsStore'
import { MY_DEFAULT_UID } from '../checks'

const { dialogsUrl } = config

export function updateDialogFileData(dialog_id, messageId, fileInfo) {
    // // const dialogs = getState('chat.dialogs')
    //
    // const dialog = getDialogById(dialog_id)
    //
    // if (dialog && dialog.messages) {
    //     let msg
    //     for (let i = 0; i < dialog.messages.length; i++) {
    //         msg = dialog.messages[i]
    //         if (msg.message_id === messageId) {
    //             const msgNew = msg
    //             msgNew.metadata.file_info = fileInfo
    //             if (!msgNew.metadata.paid_info) {
    //                 msgNew.metadata.paid_info = {}
    //             }
    //             msgNew.metadata.paid_info.already_paid_uids.push(getMyUid())
    //             dialog.messages.splice(i, 1, msgNew)
    //             return
    //         }
    //     }
    // }
}

export function isPaidDialogMessage(uid, dialog_id, noVip) {

    return false
    //
    // if (isSupport(uid)) {
    //     return false
    // }
    //
    // if (imAdmin()) {
    //     return false
    // }
    //
    // if (getFanInfo(uid)) {
    //     return false
    // }
    //
    // //если какое то не стандратное ограничение, то нет блока про цену
    // if (needSubscription(uid, PRIVATE_MSG_PERMISSION)) {
    //     if (noVip) {
    //         return false
    //     }
    //     return !imVIP()
    // }
    //
    // const messages = getDialog(dialog_id, uid).messages
    // //если есть сообщения в диалоге и не системное, значит бесплатно
    // // && messages.find(message => message.message_type !== MESSAGE_TYPE_SYSTEM) убрал ограничение на не системное
    // if (messages.length) {
    //     return false
    // }
    //
    // return true
}

let newDialogsMap = {}

export function addUnreadDirectMessage(dialog_id, count) {
    newDialogsMap[dialog_id] = (newDialogsMap[dialog_id] || 0) + count
    setStoreWrapper('unreadDialogMessage', (getState('unreadDialogMessage') || 0) + count)
}

export function clearUnreadDirectMessage() {
    newDialogsMap = {}
    setStoreWrapper('unreadDialogMessage', null)
}

export function markReadUnreadDirectMessage(uid) {
    if (getState('unreadDialogMessage')) {
        useDialogsStore.getState().setSeenDialog(uid)
        const currentMessage = newDialogsMap[uid]
        if (currentMessage) {
            const unread = getState('unreadDialogMessage')
            if (unread >= currentMessage) {
                setStoreWrapper('unreadDialogMessage', (unread - currentMessage))
            }
            delete newDialogsMap[uid]
        }
    }
}

export async function deleteDialogMessage(messageId) {
    const response = await fetch(`${dialogsUrl}/api/v1/messages/${messageId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
        },
    })
    if (!response.ok) return
}

export async function deleteDialog(dialogId) {
    if (!dialogId) {
        return
    }
    useDialogsStore.getState().deleteDialog(dialogId)
    const response = await fetch(`${dialogsUrl}/api/v1/dialogs/${dialogId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
        },
    })
    if (!response.ok) return
}

export function onDialogClick(uid, reason, aiMode) {
    if (!loginCheckWithPanelOpen()) {
        return
    }

    if (!checkDialogMsgPermission(uid)) {
        return
    }
    setStoreWrapper('forceDialogID', null)
    createDialogPanel(uid, aiMode)
    sendDialogClick(uid, reason)
}

export function isMessageRead(sendTimestamp, dialog, companionUid) {
    const sendDate = new Date(sendTimestamp)
    if (!dialog || !companionUid) {
        return false
    }
    if (dialog.last_read && dialog.last_read[companionUid] && sendDate < new Date(dialog.last_read[companionUid])) {
        return true
    }
    return false
}
