import { object, string } from 'prop-types'
import { create, useStore } from 'zustand'
import React, { useEffect } from 'react'
import { getLoveLevel } from '../../../utils/cyber/loveLevelUtil'
import { showSnackbarNew } from '../../../modules/app/factories'
import { getLocaleValue } from '../../../utils/localeUil'

export const aiFriendStore = create((set) => ({
    friendsLevels: {},
    setFriendLevel: (uid, level) => {
        const levels = aiFriendStore.getState().friendsLevels
        levels[uid] = level
        set({ friendsLevels: levels })
    },
    getFriendLevel: (uid) => {
       return aiFriendStore.getState().friendsLevels[uid] || 0
    },
}))

const AIFriendLikeLVL = ({ uid, style }) => {
    const { getFriendLevel } = useStore(aiFriendStore)

    useEffect(() => {
        getLoveLevel(uid)
    }, [])

    const progress = getFriendLevel(uid)
    return <div onClick={()=>{
        showSnackbarNew(getLocaleValue('love_lvl_desrc'))
    }} style={{
        position: 'relative',
        width: 48,
        height: 48,
        borderRadius: '50%',
        background: '#f6f6f6',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
        ...style
    }}>
        <div style={{
            position: 'relative',
            width: 44,
            height: 44,
            top: 2, bottom: 2, right: 2, left: 2,
            borderRadius: '50%',
            backgroundImage: 'linear-gradient(to top, #F32179 ' + progress + '%,' +
                ' #cccccc ' + progress + '% ' + (100 - progress) + '%)',
            // ...style
        }}>
        <div style={{
            position: 'absolute',
            top: 4, bottom: 4, right: 4, left: 4,
            background: '#f6f6f6', border: '4px solid transparent', borderRadius: '50%'
        }}>
        </div>
        <i style={{ color: '#F32179',
            position: 'absolute',
            fontSize: 36,
            paddingBottom: 2,
            left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)' }}
           className={'icon-flirty-like-full'} />
        <span style={{ position: 'absolute', color: '#ffffff', left: '50%', top: '50%',
            fontSize: 12,
            transform: 'translate(-50%, -50%)'  }}>
            {progress}
        </span>
    </div>
    </div>
}

AIFriendLikeLVL.propTypes = {
    style: object,
    uid: string
}

export default AIFriendLikeLVL
