import { checkMySubscription } from './subscriptionUtil'
import { loginCheckWithPanelOpen } from './my'
import { createSubscriptionPanelPanel } from '../components/Panels/createPanelUtil'
import { subscribeOnLike } from '../modules/auth/actions/subscribeOnLike'

export function subscriptionButtonClick(uid, noUnsubscribe) {
  if (!loginCheckWithPanelOpen()) {
    return
  }

  if (checkMySubscription(uid)) {
    if (!noUnsubscribe) {
      createSubscriptionPanelPanel(uid)
    }
  } else {
    subscribeOnLike(uid)
  }
}

