import { getUserAgeRating } from '../ageRating/ageRatingUtil'
import { imFemale, imModel } from '../my'
import { isMe } from '../checks'
import { getUser } from '../userLoader'
import { getCurrentRoomId } from '../navigation/navigationUtil'
import { getState } from '../StoreManager'

export function isModel(uid) {
  if (isMe(uid)) {
    return imModel()
  }
  const user = getUser(uid)
  if (user && user.model > 0) {
    return true
  }
  return getUserAgeRating(uid) !== 0
}

export function isFemale(uid) {
  if (isMe(uid)) {
    return imFemale()
  }
  const user = getUser(uid)
  if (user && user.gender === 'female') {
    return true
  }

  return false
}

export function isInMyRoom(uid) {
  return isInRoom(uid, getCurrentRoomId())
}

export function isInRoom(uid, roomId) {
  const viewers = (getState('app.roomSpectators') || {})[roomId]
  return viewers && viewers.includes(uid)
}