import { GETRequest, POSTRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getState, setStoreWrapper } from '../StoreManager'
import { getSettings, getSettingsInt } from '../settingsUtil'
import { getExp } from '../levelUtil'
import { getMyUid } from '../my'
import { closeCurrentPanel, createEnergyPanel } from '../../components/Panels/createPanelUtil'
import { getCurrentRoomId, getRoomOwnerUid } from '../navigation/navigationUtil'
import { isMe } from '../checks'
import { sendAmplitudeEvent } from '../GAEvent'
import { getSessionId } from '../cookies'
import { getFanInfo } from '../../modules/fans/fansUtil'
import { isSupport } from '../adminUtil'
import { isCyber } from '../cyber/cyberUtil'

export function loadMyEnergy() {
  if (!getSessionId()) {
    return
  }
  ///api/v1/energy
  GETRequest(getCoreUrl('/api/v1/energy'), (data) => {
    updateMyEnergy(data)
  }, (data) => {
    updateMyEnergy({ energy: 0 })
    console.log('enegryError')
  })
}

export function updateMyEnergy(data) {
  setStoreWrapper('myEnergy', data.energy)
}

export function getMyMaxEnergy() {
  const myExp = getExp(getMyUid())
  let count = 0
  const expLevels = (getSettings('awards_slots_for_exp') || [])
  expLevels.forEach(expCap => {
    if (expCap > myExp) {
      return
    }
    count++
  })
  return getSettingsInt('energy_base') + count * getSettingsInt('energy_bonus_for_exp')
}

export const ENERGY_CHAT = 'energy_chat_cost'
export const ENERGY_NEW_DATING_PAGE = 'energy_new_dating_page'
export const ENERGY_DIALOG = 'energy_dialog_cost'
export const ENERGY_LIKE = 'energy_like_cost'

export function getMyEnergy() {
  // return 0
  return getState('myEnergy') || 0
}

//TODO проверка достаточности энергии для действия
export function checkEnergy(action, energy) {
  if ((getMyEnergy() - (energy || getEnergyCost(action))) < 0) {
    createEnergyPanel('energy_low')
    sendAmplitudeEvent('energy_low', { action })
    return false
  }
  return true
}

export function getEnergyDialog(uid) {
  if (getFanInfo(uid) || isSupport(uid) || isCyber(uid)) {
    return getSettingsInt('energy_dialog_cost_follower') || 5
  }
  return getEnergyCost(ENERGY_DIALOG)
}

export function getEnergyCost(action) {
  if (action === ENERGY_CHAT && isMe(getRoomOwnerUid(getCurrentRoomId()))) {
    return 0
  }
  return getSettingsInt(action)
}

export function checkAndSpentEnergy(action, successCB, energy, closePanel) {
  if (!checkEnergy(action, energy)) {
    return
  }

  if (closePanel) {
    closeCurrentPanel()
  }
  if (!energy) {
    energy = getEnergyCost(action)
  }

  POSTRequest(getCoreUrl('/api/v1/energy/spent'), { energy }, (data) => {
    if (data.status === 'success') {
      // updateMyEnergy({ energy: getMyEnergy() - energy })
      if (successCB) {
        successCB()
      }
    } else {
      createEnergyPanel('energy_low')
    }
  }, (data) => {
    createEnergyPanel('energy_low')
    // updateMyEnergy({ energy: 0 })
    // console.log('enegryError')
  })
}
