import { showSnackbarNew } from '../factories'
import { getLocaleValue } from '../../../utils/localeUil'

export default [
  ({ props }) => {
    navigator.clipboard.writeText(props.value.toString()).then(() => {
      showSnackbarNew(getLocaleValue('sr_copied'))
    }, (err) => {
      showSnackbarNew(getLocaleValue('support_problem_errorApp'), 5000, 'error')
    })
  },

]
