import { getState } from './StoreManager'

export function getLastOrSimilarPostUid(uid) {
  const usersPost = getState('posts.userPosts')
  if (usersPost && usersPost[uid] && usersPost[uid][0]) {
    return usersPost[uid][0]
  }
  const topPosts = getState('posts.topPosts')
  if (topPosts && topPosts[0]) {
    return topPosts[0]
  }

  return 1445456
}

