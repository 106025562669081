import { VIDEO_GIFT } from '../snackbarUtil'
import { showSnackbarNew } from '../../modules/app/factories'
import { getVideoGiftPost } from '../giftUtil'
import { getPostData } from '../postUtils'
import { getPostNew } from '../../modules/posts/signals/getPost'
import { preloadVideo } from '../post/preloadVideoUtil'

export function addOverlayVideoGift(giftId) {
  const postId = getVideoGiftPost(giftId)
  if (!postId) {
    return
  }
  getPostNew(postId, true, () => {
    const postData = getPostData(postId)
    preloadVideo(postData.original, () => {
      showSnackbarNew('', postData.videoLength * 1000, VIDEO_GIFT, {
        video: postData.original,
        videoGiftSubtitle: postId
      })
    })
  })
}
