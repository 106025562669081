import { state } from 'cerebral'

export const setMarketingMediums = ({ store, get }) => {
  const domainMarketingMediums = get(state`intl.appSourceConfig.marketingMediums`)
  const lang = get(state`intl.lang`)
  if (domainMarketingMediums && lang) {
    const marketingMediumsObj = {}
    domainMarketingMediums.map(value => {
      if (!marketingMediumsObj[value.platform]) {
        marketingMediumsObj[value.platform] = {}
      }
      if (value.language === lang) {
        marketingMediumsObj[value.platform][lang] = value
      }
      if (value.language === 'en') {
        marketingMediumsObj[value.platform].en = value
      }
    })
    const marketingMediums = []
    Object.keys(marketingMediumsObj).forEach(platform => {
      const item = marketingMediumsObj[platform]
      if (item[lang]) {
        marketingMediums.push(item[lang])
      } else if (item.en) {
        marketingMediums.push(item.en)
      }
    })
    if (marketingMediums.length) {
      store.set('intl.marketingMediums', marketingMediums)
    }
  }
}
