import { GETRequest, POSTRequest } from '../requestWrapper'
import { getAwardURL, getCoreUrl } from '../apiURLUtil'
import { getLang, getZLocaleValueF } from '../../../zustand/useAppStore'
import { getAppId, isTWA } from '../../twa'
import { callSequence, getState, setStoreWrapper } from '../../StoreManager'
import { getCookie, setSessionId } from '../../cookies'
import { setMyGender } from '../setGenderUtil'
import { ERROR_SNACKBAR, profileFillSnackbar } from '../../snackbarUtil'
import * as Sentry from '@sentry/react'
import { showSnackbarNew } from '../../../modules/app/factories'
import { closeAllPanels, createLoginPanel } from '../../../components/Panels/createPanelUtil'
import { uploadOneSignalId } from '../../OneSignal'
import { loadMyNewContentCounters } from '../../dating/datingNewContentUtil'
import { getTopPanelType, isPanelType } from '../../../modules/app/actions'
import { messagesRoute } from '../../routerHelpers'
import { getAwardsProgress } from '../../awards/awardsProgressUtil'
import { DAILY_COINS, DAILY_COINS_NOVIP, NO_DAILY_COINS } from '../../../modules/buffs'
import { imVIP, isMyProfileSet, setMyProfileId } from '../../my'
import isVip from '../../isVip'
import { HIDDEN_COUNTRY } from '../../geo/getLocation'
import { getMyLSProp, loadMyLSProps } from '../../LocalStorageUtil'
import { CHAT_DRAFT } from '../../inputUtil'
import { startLoadOnlineSubs } from '../../onlineSubsUtil'
import { getSettingsInt } from '../../settingsUtil'
import { imInRoom } from '../../appUtil'
import { goToRoom } from '../../navigateUtil'
import { getCurrentRoomId } from '../../navigation/navigationUtil'
import { GENDER_SELECT_PANEL } from '../../../components/Panels/types'
import { isAIDatingApp, isDatingApp } from '../../appConfigUtil'
import { datingNeedProfile } from '../../../modules/posts/signals/postLike'
import { prepareAwardConfig } from '../../awards/awardsConfig'
import { initABCTest, initGA } from '../../GAEvent'
import { authGameSocket } from '../../cardGame/cardGameWSS'
import { getUser, updateUser } from '../../userLoader'
import { isModeration } from '../../moderationUtil'
import { imAdmin } from '../../adminUtil'
import { generateFingerprintNew } from '../../../modules/auth/actions'
import { getAwardsShowcase } from '../../awards/awardAPI'

export function guestAuth(gender) {
    setStoreWrapper('auth.isAuthComplete', false)
    let token
    if (!getState('auth.token')) {
        token = generateFingerprintNew()
        setStoreWrapper('auth.token', token)
    } else {
        token = getState('auth.token')
    }

    const requestParams = {
        locale: getLang(),
        access_token: token,
    }
    requestParams.domain = location.host

    const partnerId = getState('auth.partnerId')
    const fromParam = getState('auth.fromParam') || getCookie('mrtigra.refer')

    const referrer = partnerId || fromParam

    if (referrer) {
        requestParams.referrer_id = referrer
    }
    setStoreWrapper('auth.female', gender === 'female')
    POSTRequest(getCoreUrl('/api/v1/auth/guest'), requestParams, (data) => {
        prepareAuthData(data, true)
        setMyGender(gender, () => {
            setTimeout(() => {
                profileFillSnackbar()
            }, 3500)
        })
    }, (data) => {
        authError('gu_auth_error', 'GU', data)
    })
}

export function getAuthBySid() {
    GETRequest(getCoreUrl('/api/v1/auth/by-sid'), (data) => {
        prepareAuthData(data)
    }, (data) => {
        authError('authorization_error', 'SID', data)
    })
}

export function getAuthByEmail(email, password) {
    setStoreWrapper('auth.isAuthComplete', false)
    POSTRequest(getCoreUrl('/api/v1/auth/email-password'), {
        email: email, password: password
    }, (data) => {
        prepareAuthData(data)
    }, (data) => {
        authError('rp_wrong_email', 'email', data, data.error === 'Invalid password or email provided')
    })
}

function needUpdateTWA() {
    if (!isTWA()) return false
    const settings = getState('intl.settings')
    const v = getState('app.twa_version', '')
    const buildNum = v.split('.').pop()
    const appid = getAppId()
    return buildNum && settings && settings[appid] && settings[appid] > parseInt(buildNum)
}

function prepareAuthData(data, noGenderChange) {
    console.log(data)
    if (data.sid) {
        setSessionId(data.sid)
        authGameSocket()
        callSequence('auth.getAuthInfo')
    }
    setStoreWrapper('genderSelectScreen', false)
    setStoreWrapper('auth.uid', data.uid)
    setStoreWrapper('auth.admin', data.admin)
    setStoreWrapper('auth.superAdmin', data.superAdmin)
    if (isPanelType('login') || isPanelType('loggedFromAnotherPlace') || isPanelType(GENDER_SELECT_PANEL)) {
        closeAllPanels()
    }
    if (data.fine) {
        setStoreWrapper('app.ban', true)
        setStoreWrapper('room.streamStopped', true)
        setStoreWrapper('app.banId', data.fine.fine_id || 'unknown')
        return
    }
    if (!imAdmin() && isModeration) {
        setStoreWrapper('auth.isAuthComplete', true)
        callSequence('auth.logOut')
        showSnackbarNew('Доступ запрещен')
        createLoginPanel()
        return
    }

    if (needUpdateTWA()) {
        setStoreWrapper('app.showUserRedirect', true)
        return
    }

    uploadOneSignalId()
    loadMyNewContentCounters()
    const publicData = data.public_user_data

    if (publicData.lang) {
        if (getLang() !== publicData.lang) {
            callSequence('intl.changeLocale', { locale: publicData.lang })
        }
    } else {
        POSTRequest(getCoreUrl('/api/v1/users/lang'), {
            lang: getLang()
        }, (data) => {
            console.log(data.result)
        })
    }
    setStoreWrapper('auth.isSuccessful', true)
    const customParams = {}

    Object.keys(data.customParams).map(key => {
        const val = data.customParams[key]
        const parsedData = {
            name: val.name
        }
        if (val.type === 'int') {
            parsedData.value = parseInt(val.value)
        } else if (val.type === 'float') {
            parsedData.value = parseFloat(val.value)
        } else {
            parsedData.value = val.value
        }
        customParams[key] = parsedData
    })
    setStoreWrapper('auth.customParams', customParams)

    const uid = data.uid
    const receivedCoins = data.receivedCoins || 0
    const purchasedCoins = data.purchasedCoins || 0
    getAwardsProgress(uid)
    GETRequest(getAwardURL('/api/v1/awards?uids=' + uid), (data) => {
            const user = getUser(uid)
            user.awards = data.awards[uid] || {}
            updateUser(uid, user)
        },
        () => {
        }
    )


    const usdExchangeRate = getSettingsInt('coinsToMoney', 5000)
    const usdBalance = (Math.trunc((receivedCoins / usdExchangeRate || 0) * 100) / 100) || 0

    if (publicData.birthday) {
        setStoreWrapper('myBDay', publicData.birthday * 1000)
    }

    for (const key in publicData) {
        if (key !== 'newFollowersCount' &&
            key !== 'newMatchCount') {
            setStoreWrapper(`auth.${key}`, publicData[key])
        }
    }

    for (const key in data) {
        if (key !== 'newFollowersCount' &&
            key !== 'newMatchCount') {
            setStoreWrapper(`auth.${key}`, data[key])
        }
    }

    const multi_buffs = data.multi_buffs

    if (!multi_buffs[NO_DAILY_COINS]) {
        //подарок только у VIP
        if (imVIP()) {
            multi_buffs[isVip() ? DAILY_COINS : DAILY_COINS_NOVIP] = [{
                start: Date.now() / 1000,
                buff_id: isVip() ? DAILY_COINS : DAILY_COINS_NOVIP
            }]
        }
        setStoreWrapper('auth.multi_buffs', multi_buffs)
    }
    publicData.multi_buffs = multi_buffs

    // loadMyData(uid, params, get)
    updateUser(uid, publicData)
    setStoreWrapper('auth.geoIpInfo', data.geoIpInfo)
    const storage = publicData.storage || {}
    const hiddenLocation = Boolean(storage.hidden_location)
    setStoreWrapper('auth.isLoggedIn', true)
    setStoreWrapper('auth.hiddenLocation', hiddenLocation)
    setStoreWrapper('selectedCountry', hiddenLocation ? HIDDEN_COUNTRY : null)
    setStoreWrapper('dateReg', data.dateReg)
    setStoreWrapper('auth.isPasswordSet', data.authMode === 'gu')
    setStoreWrapper('auth.email', data.email)
    setStoreWrapper('auth.donateLink', storage.donate_url)
    setStoreWrapper('auth.freeSendMedia', storage.free_incoming_media)
    setStoreWrapper('auth.storage', storage)
    setStoreWrapper('auth.referrer_userid', data.referrerId)
    setStoreWrapper('auth.referrerAgency', Boolean(data.referrerAgency))

    const chatDraft = getMyLSProp(CHAT_DRAFT)
    if (chatDraft) {
        setStoreWrapper(CHAT_DRAFT, chatDraft)
    }
    setStoreWrapper('auth.model', Boolean(publicData.model))
    setStoreWrapper('auth.coins', purchasedCoins)
    setStoreWrapper('auth.purchasedCoins', purchasedCoins)
    setStoreWrapper('auth.receivedCoins', receivedCoins)
    setStoreWrapper('auth.usdBalance', usdBalance)
    startLoadOnlineSubs()
    setStoreWrapper('auth.myLSProps', loadMyLSProps(uid))
    if (!noGenderChange) {
        setStoreWrapper('auth.female', publicData.sex === 0)
    }

    const profileId = storage.profilePost && storage.profilePost.postId
    if (profileId) {
        setMyProfileId(profileId)
    }

    if (imInRoom()) {
        setStoreWrapper('forceSendGoInRoom', true)
        goToRoom(getCurrentRoomId())
    }
    setStoreWrapper('allMyDataLoaded', true)
    setStoreWrapper('genderSelectScreen', false)
    setStoreWrapper('auth.isAuthComplete', true)

    if (isDatingApp() && !isMyProfileSet() && !isAIDatingApp()) {
        datingNeedProfile('need_set_profile')
    }

    // if (isDatingApp()) {
    //     clearAllPosters()
    //     callSequence('posts.getMorePosts')
    // }

    // callSequence('auth.loadSpam')
    prepareAwardConfig(true)

    initGA()
    initABCTest()

    if (getState('app.panelRoute') === 'dialog' && getTopPanelType() !== 'dialog') {
        callSequence('app.prepareAndRouteTo', {
            url: messagesRoute,
            urlValue: getState('app.url', '').split('/')[2]
        })
    }
}

function authError(key, type, data, noSentry) {
    if (!noSentry) {
        Sentry.captureMessage(type + ' auth error ' + JSON.stringify(data))
        Sentry.captureException(type + ' auth error ' + JSON.stringify(data))
    }
    setStoreWrapper('auth.isAuthComplete', true)
    callSequence('auth.logOut')
    showSnackbarNew(getZLocaleValueF(key), 5000, ERROR_SNACKBAR)
    createLoginPanel()
}
