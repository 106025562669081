import { create } from 'zustand'
import { ALL_LOADED, LOADING } from '../utils/api/dating/datingApi'
import { getConfig } from '../utils/urlUtils'
import { allBlogRatings, isOnlyApprovedPosts } from '../utils/ageRating/ageRatingUtil'
import { isMe } from '../utils'
import { getLang } from './useAppStore'
import { GETRequest } from '../utils/api/requestWrapper'
import { PROFILE_TAG } from '../utils/postUtils'
import { getAppTagsIdExcluded } from '../utils/appConfigUtil'

const POSTS_IN_PAGE = 30

const usePostsStore = create(set => ({
    posts: {},
    usersPosts: {},
    usersLoadStatus: {},
    usersPages: {},
    isLoading: uid => {
        return usePostsStore.getState().usersLoadStatus[uid] === LOADING
    },
    getUserPosts: uid => {
        const {
            usersPosts,
            loadUserPosts
        } = usePostsStore.getState()

        if (!usersPosts[uid]) {
            loadUserPosts(uid)
        }
        return usersPosts[uid] || []
    },
    loadUserPosts: uid => {
        const {
            usersLoadStatus,
            usersPosts,
            posts,
            usersPages
        } = usePostsStore.getState()

        if (usersLoadStatus[uid] === ALL_LOADED || usersLoadStatus[uid] === LOADING) {
            return
        }

        let queryString = getConfig('ftCoreUrl') + `/api/v1/posts/profile?fromUid=${uid}`
        //если не ТВА и не особый режим, или мои посты, то спросим все публикаци
        if (!isOnlyApprovedPosts() || isMe(uid)) {
            queryString += '&withOnModeration=true'
            queryString += '&withNotInteresting=true'
        }

        if (!isMe(uid)) {
            queryString += '&isNotAdult=true&ageRating=' + allBlogRatings
        } else {
            queryString += `&ageRating=${[12, 14, 16, 18]}`
        }

        const et = getAppTagsIdExcluded(PROFILE_TAG)
        et.push(PROFILE_TAG)
        queryString += '&excludeTags=' + et

        let page = usersPages[uid] || 0
        queryString += '&limit=' + POSTS_IN_PAGE
        queryString += `&page=${page}`

        page++
        usersPages[uid] = page
        usersLoadStatus[uid] = LOADING
        set({ usersPages, usersLoadStatus })

        queryString += `&lang=${getLang()}`

        GETRequest(queryString, data => {
            const loadedPosts = data.posts
            loadedPosts.forEach(post => {
                // if (post.postId && posts[post.postId] && posts[post.postId].error) {
                //     posts[post.postId] = null
                // } else {
                posts[post.postId] = post
                // }
            })
            usersPosts[uid] = (usersPosts[uid] || []).concat(loadedPosts)
            if (loadedPosts.length < POSTS_IN_PAGE) {
                usersLoadStatus[uid] = ALL_LOADED
            } else {
                delete usersLoadStatus[uid]
            }

            set({ usersLoadStatus, posts, usersPosts })
        }, () => {
            delete usersLoadStatus[uid]
            set({ usersLoadStatus })
        })
    },
    reloadUserPosts: uid => {
        const {
            usersLoadStatus,
            usersPosts,
            usersPages
        } = usePostsStore.getState()

        delete usersLoadStatus[uid]
        delete usersPosts[uid]
        delete usersPages[uid]

        set({ usersLoadStatus, usersPages, usersPosts })
        loadUserPosts(uid)
    }
}))

export default usePostsStore

export function loadUserPosts(uid) {
    usePostsStore.getState().loadUserPosts(uid)
}

export function getZPostData(postId) {
    return usePostsStore.getState().posts[postId]
}
