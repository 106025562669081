import signals from './signals'
import { ACH, ADVCASHE, BITSAFE, CARD_RUSSIA, CT, IMPS, PAXUM, PAYTM, QIWI, SEPA, SWIFT, USDT, YANDEX } from '@/config'
import { GCASH, GRABPAY, PAGBANK, PAYMAYA, PYONEER } from '../../config'

export default {
  state: {
    pymentTypeOrder: [
      // INVOICE,
      // BITCOIN,
      // YANDEX,
      CARD_RUSSIA,
      // CARD,
      SEPA,
      BITSAFE,
      // QIWI,
      SWIFT,
      CT,
      ACH,
      USDT,
      PYONEER,
      ADVCASHE,
      PAXUM,
      // ALIPAY
      PAYTM,
      IMPS,
      PAGBANK,
      GCASH,
      GRABPAY,
      PAYMAYA
    ],
    pymentDataUser: {
      pymentTypeUser: {}
    },
    purashedVipCoinsLoading: false,
  },
  sequences: signals
}
