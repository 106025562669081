import { showSnackbarNew } from '../../modules/app/factories'
import { CONFIRM_SNACKBAR } from '../snackbarUtil'
import { createGetVipPanel } from '../../components/Panels/createPanelUtil'
import { getLocaleValue } from '../localeUil'

export function deleteWithConfirm(key, cb) {
    showSnackbarNew(getLocaleValue(key), 5000, CONFIRM_SNACKBAR, {
        buttonKey: 'confirm',
        onClick: () => {
            cb()
        },
    })
}

export function vipOlyOption(key, cb) {
    showSnackbarNew(getLocaleValue(key || 'vip_only_option'), 5000, CONFIRM_SNACKBAR, {
        buttonKey: 'vip_buy',
        onClick: () => {
            createGetVipPanel()
        },
    })
}

export const PERMANENT_DELETE = 'permanent_delete'
