import signals from './signals'

export default {
  state: {
    data: [],
    page: 0,
    isLoading: false,
    allLoaded: false,
    selectedSponsors: false,
  },
  sequences: signals
}
