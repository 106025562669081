export const postersRoute = '/p'
export const createRoute  = '/c'
export const messagesRoute = '/m'
export const postRoute = '/p'
export const shopRoute = '/shop'
export const getVipRoute = '/vip'
export const postProfileRoute = '/postProfile'
export const postModerationRoute = '/postModeration'
export const payoutRoute = '/payout'
export const searchRoute = '/search'
export const fansRoute = '/f'
export const subsRoute = '/s'
export const settingsRoute = '/settings'
export const additionRoute = '/add'
export const roomRoute = ''
export const contentModerationRoute = '/moder'
