import sendChatMessage from './sendChatMessage'
import selectRecipient from './selectRecipient'
import updateInputText from './updateInputText'
import inputKeyPress from './inputKeyPress'
import banMsg from './banMsg'
import banRoomChat from './banRoomChat'
import clearChat from './clearChat'
import resetInput from './resetInput'
import openMsgActions from './openMsgActions'
import setInputH from './setInputH'
import setCompanyNotificationReaded from './setCompanyNotificationReaded'
import updateUnreadComments from './updateUnreadComments'
import dialogSendMediaClick from './dialogSendMediaClick'
import sendDialogMessageLocal from './sendDialogMessageLocal'

export default {
    updateUnreadComments,
    banMsg,
    banRoomChat,
    clearChat,
    openMsgActions,
    sendChatMessage,
    selectRecipient,
    updateInputText,
    inputKeyPress,
    resetInput,
    setInputH,
    setCompanyNotificationReaded,
    dialogSendMediaClick,
    sendDialogMessageLocal
}
