import { increment } from 'cerebral/factories'
import { state } from 'cerebral'
import prepareAndRouteTo from '../../app/signals/prepareAndRouteTo'
import { postersRoute } from '@/utils/routerHelpers'

export default [
  ({ store, get, props }) => {
    if (props.resetTags) {
      store.set('posts.userTags', [])
      store.set('posts.selectedTags', [])
    }

    if (props.selectTag) {
      let tagsArray = get(state`posts.selectedTags`)
      tagsArray.push(props.selectTag)
      tagsArray = tagsArray.filter((item, pos) => tagsArray.indexOf(item) === pos)//remove duplicates

      store.set('posts.selectedTags', tagsArray)
    } else if (props.tag) {
      props.tag = parseInt(props.tag)
      const tagsArray = get(state`posts.selectedTags`)
      if (tagsArray.indexOf(props.tag) !== -1) {
        tagsArray.splice(tagsArray.indexOf(props.tag), 1)
      } else {
        tagsArray.push(props.tag)
      }
      store.set('posts.selectedTags', tagsArray)
      store.set('posts.userTags', [])
    } else if (props.userTag) {
      const userTags = get(state`posts.userTags`).map(userTag => {
        return userTag
      })
      const userTagIndex = userTags.indexOf(props.userTag)
      if (userTagIndex === -1) {
        userTags.push(props.userTag)
      } else if (props.deselectUserTagOnDoubleClick) {
        userTags.splice(userTagIndex, 1)
      }
      store.set('posts.userTags', userTags)
      store.set('posts.selectedTags', [])
    } else {
      store.set('posts.userTags', [])
      store.set('posts.selectedTags', [])
    }

    store.set('posts.loadingPostsStatus', 'not_loaded')
    store.set('posts.topPosts', [])
  },
  increment(state`posts.filterCounter`),
  () => ({ url: postersRoute }),
  prepareAndRouteTo,
]
