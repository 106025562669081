import config from '@/config'
import { getSessionId } from '@/utils'

const { ftCoreUrl } = config

export const getCountCheckedPost = async ({ get, store }) => {
  const sid = getSessionId()
  const queryString = `${ftCoreUrl}/api/v1/posts/moderation/approved/count?sid=${sid}`
   try {
    const response = await fetch(queryString)
    const result = await response.json()
    store.set('moderation.countCheckedPost', result.approved_posts)
  } catch (e) {
    console.warn('@@@ load moderations error:', e)
  }
  return
}
