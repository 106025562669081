import { isFemale } from '../user/userInfos'
import { POSTRequest } from '../api/requestWrapper'
import config from '@/config'
import { isCyber } from '../cyber/cyberUtil'
import { isMe, MY_DEFAULT_UID } from '../checks'
import { PLAY_STATUS_LOADING, PLAY_STATUS_PLAYING, playAudio } from '../soundUtil'
import { setStoreWrapper } from '../StoreManager'
import { MD5 } from '../MD5'
import { imVIP } from '../my'
import { vipOlyOption } from '../api/accountUtil'
import { sendAmplitudeEvent } from '../GAEvent'
import useDialogsStore from '../../zustand/useDialogsStore'
import { createGetVipPanel } from '../../components/Panels/createPanelUtil'

const { postUploadUrl } = config

const MALE_ONYX = 'onyx'
const FEMALE_NOVA = 'nova'
const FEMALE_SHIMMER = 'shimmer'

const voicingCash = {}

export function textToSpeech(message, voice, cb) {
    if (!message || !message.length) {
        if (cb) {
            cb()
        }
        return
    }
    const messageId = MD5(message + voice)
    setStoreWrapper('playingButton', messageId)
    if (voicingCash[messageId]) {
        playAudio(voicingCash[messageId])
        if (cb) {
            cb()
        }
        return
    }

    setStoreWrapper('playStatus', PLAY_STATUS_LOADING)

    const sendParams = { message, voice }
    if (imVIP()) {
        sendParams['hd'] = true
    }
    POSTRequest(postUploadUrl + '/api/v1/message-to-speech', sendParams, (params) => {
        console.log('voice')
        if (params.result) {
            voicingCash[messageId] = params.result
            playAudio(params.result)
            if (cb) {
                cb()
            }
        }
    }, () => {
        console.log('ERROR /api/v1/message-to-speech ')
        if (cb) {
            cb()
        }
    })
}

export function playUserVoiceOnClick(message, uid, cb) {
    sendAmplitudeEvent('sound_click',
        {
            type: imVIP() ? 'voiced' : 'not_vip',
            cyber: isCyber(uid)
        }
    )// && !isCyber(uid)
    if (!imVIP()) {
        createGetVipPanel('vip_only_voice')
        // vipOlyOption('vip_only_voice')
        return
    }
    if (isMe(uid)) {
        if (cb) {
            cb()
        }
        return
    }
    // sendAmplitudeEvent('sound_click', { type: 'voiced' })
    textToSpeech(message, getVoice(uid), cb)
}

export function voiceLastMessage(uid) {
    if (!isCyber(uid)) {
        return
    }
    const dialog = useDialogsStore.getState().getDialog(MY_DEFAULT_UID, uid)
    if (!dialog || !dialog.messages) {
        return
    }
    const messages = dialog && dialog.messages && dialog.messages.reverse()

    let message
    for (let i = 0; i < messages.length; i++) {
        message = messages[i]
        if (message && message.uid === uid) {
            playUserVoiceOnClick(message.message, uid)
            break
        }
    }
}

export let stopPlayingTimeout

export function playUserVoice(message, uid, cb) {
    if (isCyber(uid)) {
        setStoreWrapper('playStatus', PLAY_STATUS_PLAYING)
        stopPlayingTimeout = setTimeout(() => {
            setStoreWrapper('playStatus', null)
        }, 10000)
    }

    //уберем авто плей
    if (cb) {
        cb()
    }
    // if (isMe(uid) || is.safari()) {
    //     if (cb) {
    //         cb()
    //     }
    //     return
    // }
    // //тут возможно потом добавится условие на VIP
    // if (isCyber(uid)) {
    //     if (isAIDollsVoicingActive()) {
    //         textToSpeech(message, getVoice(uid), cb)
    //     } else {
    //         if (cb) {
    //             cb()
    //         }
    //     }
    //     return
    // }
    // // if (imInRoom(uid) && isRoomOwnerVoicingActive()) {
    // //     textToSpeech(message, getVoice(uid), cb)
    // //     return
    // // }
    //
    // if (cb) {
    //     cb()
    // }
}

// async function playSound(soundUrl) {
//   try {
//     const s = new Audio(soundUrl)
//     await s.play()
//   } catch (e) {
//     console.log('sound not allowed')
//   }
// }

export const SOPHIA = '84b3ae7e36ae4858317d4852c8e1148f'
const RED_HOOD = '9172af5a1de76511bebcc4dc470b07cc'
const NAOMI = '96cfc673aba81ad5541c9cd55c36acbb'
const AURORA = 'f1b4da81fdb653e871a3ea31516da4b5'
const SAKURA = 'cd22ddb0c85b233e86a91607f9053dec'
export const TARZ = 'b6ffcc83f42558f9dc74501a5d79795a'

export const CUSTOM_VOICE = '1'

export const usersVoices = {}

export function getVoice(uid) {
    if (usersVoices[uid]) {
        return usersVoices[uid]
    }
    if (uid === SOPHIA) {
        return '12'
    }
    if (uid === RED_HOOD) {
        return 'w5'
    }
    if (uid === AURORA) {
        return 'w2'
    }
    if (uid === SAKURA) {
        return 'w3'
    }
    if (uid === NAOMI) {
        return 'w1'
    }

    //TODO добавить условие использования кастом голоса

    return isFemale(uid) ? FEMALE_NOVA : MALE_ONYX
}
