import { state } from 'cerebral'
import { set } from 'cerebral/factories'
import { dispatchUrlChangeToParent } from '../actions'
import { handleRoute } from './routed'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import getStreamMetadata from '@/utils/getStreamMetadata'
import askRateApp from '@/utils/askRateApp'
import isVip from '../../../utils/isVip'
import { callSequence } from '../../../utils/StoreManager'
import { getPostersScrollPosition } from '../../../utils/scrollRememberUtil'

export default [
  ({ get }) => {
    const roomId = get(state`app.roomId`)
    const metadata = getStreamMetadata(roomId)
    callSequence('app.setAnimatedTopBar', { withAnimate: false })
    callSequence('app.hideTopBar', { value: getPostersScrollPosition() > 300 })
    if (metadata && metadata.theme) {
      return {
        roomId,
        themeId: (metadata.postThemeId || '-1') + '',
        isInPrivate: get(state`app.wasInPrivate`)
      }
    }
  },
  set(state`app.wasInPrivate`, null),
  () => ({ route: 'home' }),
  dispatchUrlChangeToParent,
  handleRoute,
  ({ get, props, store }) => {
    const firstViewed = get(state`auth.firstViewed`)
    //поcылаем аналитику c 10% вероятноcтью, если это не VIP юзер и не приват
    if (firstViewed && (!isVip() && Math.random() > 0.1 && !props.isInPrivate)) {
      return
    }

    const streamData = get(state`room.streamData`)
    if (!streamData) {
      return
    }

    const roomEnterTime = get(state`room.roomEnterTime`)
    if (roomEnterTime === 0) {
      return
    }


  },
  ({ store }) => store.set('room.streamData', null)
]
