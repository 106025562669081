import routed from './routed'
import postersRouted from './postersRouted'
import postModerationRouted from './postModerationRouted'
import dialogsRouted from './dialogsRouted'
import dialogRouted from './dialogRouted'
import postRouted from './postRouted'
import coinsRouted from './coinsRouted'
import getVipRouted from './getVipRouted'
import payoutRouted from './payoutRouted'
import searchRouted from './searchRouted'
import fansRouted from './fansRouted'
import settingsRouted from './settingsRouted'
import subsRouted from './subsRouted'
import roomRouted from './roomRouted'
import invalidRouted from './invalidRouted'
import addContentRouted from './addContentRouted'
import redirectToRoom from './redirectToRoom'
import contentModerationRouted from './contentModerationRouted'

const rawRoutes = {
  '/': 'routed',
  '/p': 'postersRouted',
  '/postModeration/:post': 'postModerationRouted',
  '/m': 'dialogsRouted',
  '/m/:userId': 'dialogRouted',
  '/moder': 'contentModerationRouted',
  '/p/:post': 'postRouted',
  '/f': 'fansRouted',
  '/s': 'subsRouted',
  '/shop': 'coinsRouted',
  '/vip': 'getVipRouted',
  '/payout': 'payoutRouted',
  '/search': 'searchRouted',
  '/settings': 'settingsRouted',
  '/add': 'addContentRouted',
  '/:userId': 'roomRouted',
  '/room/:userId': 'redirectToRoom',
}

const routes = Object.keys(rawRoutes).map(path => ({
  path: new RegExp('^' + path.replace(/:[^\s/]+/g, '([\\w-]+)') + '\/?$'),
  module: rawRoutes[path]
}))

export const eraseSeoParamsFromUrl = route =>
  route
  .split('/')
  .map(s => s.indexOf('_') !== -1 ? s.substring(0, s.indexOf('_')) : s)
  .join('/')

const getLocaleFromUrl = url => {
  const splited = url.split('/')
  const [empty, locale, ...other] = splited
  const joined = [empty, ...other].join('/')
  return [locale, joined.indexOf('/') === -1 ? '/' : joined]
}

const mapRoutes = url => {
  const [locale, cleanUrl] = getLocaleFromUrl(eraseSeoParamsFromUrl(url))
  for (let index = 0; index < routes.length; index++) {
    const route = routes[index]
    const found = cleanUrl.match(route.path)
    if (found) {
      return {
        module: route.module,
        url: cleanUrl,
        args: found.slice(1),
        locale
      }
    }
  }
  return {
    module: 'invalidRouted',
    args: ''
  }
}

export default [
  ({ path, store }) => {
    const pathname = location.pathname.indexOf('/new-chat') !== -1
      ? location.pathname.substring(9)
      : location.pathname

    let urlOriginal = location.href
    if (urlOriginal.indexOf('#') !== -1 && urlOriginal.indexOf('appid') !== -1){
      location.href = urlOriginal.replace('#','')
    }

    store.set('app.urlGetVariables', location.search)
    const { module, url, args, locale } = mapRoutes(pathname)
    return path[module]({ routeArgs: args, locale, url })
  }, {
    routed,
    postersRouted,
    postModerationRouted,
    dialogsRouted,
    dialogRouted,
    postRouted,
    coinsRouted,
    getVipRouted,
    payoutRouted,
    subsRouted,
    searchRouted,
    fansRouted,
    settingsRouted,
    roomRouted,
    addContentRouted,
    invalidRouted,
    redirectToRoom,
    contentModerationRouted
  }
]
