import { state } from 'cerebral'
import { getMyLSProp, setMyLSProps } from '../../../utils/LocalStorageUtil'
import { setStoreWrapper } from '../../../utils/StoreManager'

export default ({ props, store, get }) => {
  const msgId = props.messageId

  const allChats = get(state`chat.roomsMessages`)
  for (const roomId in allChats) {
    const roomMessages = allChats[roomId]

    for (let i = 0; i < roomMessages.length; i++) {
      const msg = roomMessages[i]
      if (msg.postId === msgId) {
        roomMessages.splice(i, 1)
        break
      }
    }
  }

  if (props.isDialog) {
    const bannedMSG = getMyLSProp('bannedMSGid') || {}
    bannedMSG[msgId] = msgId
    setMyLSProps('bannedMSGid', bannedMSG)
    setStoreWrapper('bannedMSGid', bannedMSG)
  }

  store.set('chat.roomsMessages', allChats)
}
