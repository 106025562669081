const cashedUrls = {}

export function setCashedUrl(id, url) {
  cashedUrls[id] = url
}

export function getCashedUrl(id) {
  return cashedUrls[id]
}

