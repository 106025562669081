import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import { getMyUid } from '../../../utils/my'
import askRateApp from '../../../utils/askRateApp'
import { startAnimateGift } from '../../../components/Levels/animatedLike'
import { checkCurrentRoomId } from '../../../utils/navigation/navigationUtil'
import { scrollOnAction } from '../../../utils/chat/chatMessagesManager'
import { isMe } from '../../../utils'
import { getLoveLevel } from '../../../utils/cyber/loveLevelUtil'
import useDialogsStore from '../../../zustand/useDialogsStore'

const { soundUrl } = config

export default ({ props, store, get }) => {
    //giftSent:
    //{"gift":"wedding_rings","set":"romantic","from":"ead9c7682515efa1853415be2240aa70","to":"a146f2d6394603433e4a70943b4fb969", "cost":20000,}
    //giftSetSent
    //{"set":"romantic","from":"ead9c7682515efa1853415be2240aa70","to":"a146f2d6394603433e4a70943b4fb969", "cost":59000}
    if (!props.params) {
        return
    }

    const myUid = get(state`auth.uid`)
    const curRoom = get(state`app.roomId`) || myUid
    const msg = props.params

    if (msg.from !== myUid && msg.to !== myUid && !checkCurrentRoomId(msg.to)) {
        return
    }
    if (isMe(msg.from)) {
        getLoveLevel(msg.to)
    }
    const text = props.params.msg === 'undefined' ? null : props.params.msg

    loadUsers([msg.from, msg.to],
        users => {
            msg.fromName = users[msg.from].name
            msg.toName = users[msg.to].name
            msg.postId = Date.now().toString(36)
            msg.fromNameTsc = users[msg.from].total_subscription_cost
            msg.toNameTsc = users[msg.to].total_subscription_cost

            const messages = get(state`chat.roomsMessages.${curRoom}`) || []
            messages.push(msg)

            const len = messages.length
            //эта магия c % 2 нужна, чтобы изменялоcь кол-во cообщений
            //в маccиве при каждом новом cообщении
            while (messages.length > 24 && len % 2 === 0) {
                messages.shift()
            }

            startAnimateGift(msg.from, msg.gift, text)
            scrollOnAction()
            store.set(`chat.roomsMessages.${curRoom}`, messages)

            if (msg.from !== myUid && msg.to !== myUid) {
                return
            }

            //если я отправитель то сообщение лкоально уже отправлено
            if (isMe(msg.from)) {
                return
            }

            //сообщение в диалог
            useDialogsStore.getState().addGiftMessage(msg.from, msg.to, msg.gift)
        }
    )

    if (msg.to === myUid) {
        //дополнительный таймаут в 7 секунд
        setTimeout(() => {
            askRateApp('gift_received')
        }, 7000)

        try {
            const url = msg.cost < 5000 ?
                soundUrl + 'gift1.mp3' :
                soundUrl + 'gift2.mp3'
            const s = new Audio(url)
            s.play().catch(() => console.warn('sound not allowed'))
        } catch (e) {
            console.warn('sound not allowed')
        }
    }
}
