import React from 'react'
import styled from '@emotion/styled'
import propsOverrideCss from '@/utils/propsOverrideCss'
import { keyframes } from '@emotion/react'
import is from 'is_js'

const spinnerAnimation = keyframes({
  to: { transform: 'rotate(360deg)' }
})

const Loader = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  backgroundColor: '#fff'
})

export const Spinner = styled.div(
  ({ borderColor, borderTopColor, left, size }) =>
    ({
      position: 'absolute',
      top: '50%',
      left: left || '50%',
      '&::before': {
        content: '\'\'',
        position: 'absolute',
        top: '50%',
        boxSizing: 'border-box',
        left: left || '50%',
        width: size || 36,
        height: size || 36,
        marginTop: -(size / 2 || 18),
        marginLeft: -(size / 2 || 18),
        borderRadius: '50%',
        border: `solid ${size / 6 || 6}px ${borderColor || '#dddddd'}`,
        borderTopColor: borderTopColor || '#0084FF',
        animation: `${spinnerAnimation} 1.0s linear infinite`
      }
    }),
  propsOverrideCss
)

export const AppLoading = () =>
  <Loader id={'mainLoader'}>
    {/*<SidebarLoader />*/}
    <Spinner/>
  </Loader>
