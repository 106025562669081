import App from 'cerebral'
import Devtools from 'cerebral/devtools'
import modules from './modules'
import is from 'is_js'

const devtools = isDev && is.chrome() &&
  Devtools({
    host: 'localhost:8484',
    bigComponentsWarning: 0,
  })

const controller = App(
  {
    modules,
    providers: {
      router: {
        goTo: path => {
          window.history.pushState(null, '', path)
          controller.getSequence('app.processingOfRoutes')()
        },
        redirect: path => {
          window.history.replaceState(null, '', path)
          controller.getSequence('app.processingOfRoutes')()
        }
      },
    },
    catch: [
      [
        TypeError, ({ props }) => {
        if (props.error.message === 'Failed to fetch') {
          console.warn('catched error', props.error)
          return
        }
        console.error('cant catch error', props.error)
        throw props.error
      }
      ]
    ]
  }, {
    devtools
  })

export default controller
