import controller from '@/controller'

export function setStoreWrapper(key, value) {
  try {
    if (!key) {
      return
    }
    // console.log('k ' + key + ' v ' + value)
    const storeSetUtil = getSequence('app.storeSetUtil')
    storeSetUtil({ key: key, value: value })
  } catch (e) {
    console.error('storeSetUtil ' + e)
  }
}
export function setStoreWrapperIfChanged(key, value) {
  if (!key) {
    return
  }
  if(getState('key') === value){
    return
  }
  setStoreWrapper(key, value)
}

export function pushStoreWrapper(key, value) {
  try {
    const storeSetUtil = getSequence('app.storePushUtil')
    storeSetUtil({ key: key, value: value })
  } catch (e) {
    console.error('storePushUtil ' + e)
  }
}

export function shiftStoreWrapper(key) {
  try {
    const data = getState(key)
    let val
    if (data) {
      val = data[0]
    }
    const storeUtil = getSequence('app.storeShiftUtil')
    storeUtil({ key: key })
    return val
  } catch (e) {
    console.error('shiftUtil ' + e)
  }
}

export function unshiftStoreWrapper(key, value) {
  try {
    const storeUnshiftUtil = getSequence('app.storeUnshiftUtil')
    storeUnshiftUtil({ key: key, value: value })
  } catch (e) {
    console.error('storeUnshiftUtil ' + e)
  }
}

export function mergeStoreWrapper(key, value) {
  try {
    const storeMergeUtil = getSequence('app.storeMergeUtil')
    storeMergeUtil({ key: key, value: value })
  } catch (e) {
    console.error('storeMergeUtil ' + e)
  }
}

export function getState(key, defaultValue) {
  return controller.getState(key) || defaultValue
}

export function getSequence(key) {
  return controller.getSequence(key)
}

export function callSequence(key, props) {
  controller.getSequence(key)(props)
}

