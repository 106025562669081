import { showSnackbar } from '../factories'
import { props } from 'cerebral'

export default [
  ({ props })=>{
    if (!props.additionalInfo) {
      props.additionalInfo = null//undefined не воспринимается адекватно
    }
  },
  showSnackbar(props`text`, props`ms` || 7000, props`type`, props`additionalInfo`)
]
