import { showSnackbar } from '../../app/factories'
import { changeEmailPasswordPanel } from '@/components/Panels/types'
import { props, state } from 'cerebral'
import { checkEmailRequest } from '../actions/checkEmailRequest'
import createPanel from '../../app/signals/createPanel'

export default [
  checkEmailRequest('auth.changeEmailForm'), {
    wrongEmail: [],
    notExists: [
      () => ({ options: changeEmailPasswordPanel.options }),
      createPanel
    ],
    exists: showSnackbar(state`intl.content.sr_already_use`, 3000, 'error'),
    error: showSnackbar(props`error`, 3000, 'error')
  }
]
