import { props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { send } from '../../socket/actions'
import { messagesRoute } from '@/utils/routerHelpers'
import { getVipRoute, shopRoute } from '../../../utils/routerHelpers'

export default [
  when(props`url`, state`app.isInPrivate`,
    (url, isInPrivate) => isInPrivate && url.indexOf(isInPrivate) === -1 && url.indexOf(messagesRoute) !== 3 && url.indexOf(shopRoute) !== 3 && url.indexOf(getVipRoute) !== 3
  ), {
    true: [
      ({ store, get }) => {
        const roomId = get(state`app.isInPrivate`)
        store.unset(`posters.raw.${roomId}`)
      },
      () => ({ method: 'directCallEnd' }),
      send,
      ({ get, store }) => {
        store.set('app.wasInPrivate', get(state`app.isInPrivate`))
      },
      set(state`app.isInPrivate`, null),
    ],
    false: []
  },
  ({ store, get, props, router }) => {
    let url = props.url.indexOf('/new-chat') !== -1
      ? props.url.substring(9)
      : props.url
    const urlGetVariables = props.urlGetVariables || get(state`app.urlGetVariables`)
    const parsed = new URLSearchParams(urlGetVariables)

    const stringifyed = '?' + parsed.toString()
    store.set('app.urlGetVariables', stringifyed)
    if (stringifyed.length > 1) {
      url += stringifyed
    }
    router.goTo(url)
  }
]
