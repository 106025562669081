import { set, when } from 'cerebral/factories'
import { state } from 'cerebral'

export default [
  when(state`payment.purashedVipCoinsLoading`, value => value === false), {
    true: [
        set(state`payment.purashedVipCoinsLoading`, true)
    ],
    false: []
  }
]
