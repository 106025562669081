import setFrontCamera from './setFrontCamera'
import setShareScreen from './setShareScreen'
import setVideoDimensions from './setVideoDimensions'
import sendCreateBroadcast from './sendCreateBroadcast'
import setStreamInRoom from './setStreamInRoom'
import setCodec from './setCodec'
import setVideoDevices from './setVideoDevices'
import clearRoomVariables from './clearRoomVariables'
import setStreamData from './setStreamData'
import canDoPrivate from './canDoPrivate'
import addPopup from './addPopup'
import sendGoInRoom from './sendGoInRoom'
import changeCamera from './changeCamera'
import changeMic from './changeMic'
import sendGift from './sendGift'
import stopStream from './stopStream'
import setProBroadcast from './setProBroadcast'
import toogleMic from './toogleMic'
import toogleCam from './toogleCam'
import closeChat from './closeChat'
import openChat from './openChat'
import webrtcFailed from './webrtcFailed'
import setSoundMuted from './setSoundMuted'
import setFullscreen from './setFullscreen'
import endPrivateShow from './endPrivateShow'
import setNeedUserIteraction from './setNeedUserIteraction'

export default {
    addPopup,
    webrtcFailed,
    setSoundMuted,
    setStreamData,
    setFullscreen,
    setCodec,
    openChat,
    closeChat,
    stopStream,
    sendGift,
    toogleMic,
    toogleCam,
    changeCamera,
    changeMic,
    sendGoInRoom,
    canDoPrivate,
    setFrontCamera,
    setShareScreen,
    endPrivateShow,
    setStreamInRoom,
    clearRoomVariables,
    setVideoDimensions,
    sendCreateBroadcast,
    setNeedUserIteraction,
    setProBroadcast,
    setVideoDevices
}
