import config from '@/config'
import { getSessionId, wait } from '@/utils'
import controller from '@/controller'
import { state } from 'cerebral'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { showSnackbar } from '../../app/factories'
import { addMeInTopUids } from '../../../utils/topBar/topBarFillUtil'

const { ftCoreUrl } = config

export default [
  async ({ get, props, store }) => {
    const openShop = controller.getSequence('app.openShop')
    const panelsOnPop = controller.getSequence('app.panelsOnPop')

    try {
      if (get(state`payment.purashedVipCoinsLoading`) === false) {
        const response = await fetch(`${ftCoreUrl}/api/v1/vip/buy`, {
          method: 'POST',
          body: new URLSearchParams({
            sid: getSessionId(),
            item: props.item,
          })
        })
        const res = await response.json()

        if (res.detail && res.detail === 'not enough coins') {
          let message = get(state`intl.content.need_more_coins`)
          const reason = 'click-vip-panel'
          const coins = get(state`auth.coins`)
          message = message.replace('{0}', props.cost - coins)
          panelsOnPop({ hideAll: true })
          await wait(250)
          openShop({ message, reason })
        } else {
          store.set('auth.vip_subscription', res.vip_subscription)
          sendAmplitudeEvent('buy_vip', {
            coins: props.cost,
            product: props.duration === 7 ? 'week' : 'month',
            type: 'init'
          })

          addMeInTopUids()
          showSnackbar(state`intl.content.purchase_completed`, 5000, 'success')
        }
        store.set('payment.purashedVipCoinsLoading', false)
      }
    } catch (e) {
      console.log(e)
    }
  }
]
