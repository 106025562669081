import { props, sequence, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { generateFingerprint } from '../actions'

export const getFingerprint = sequence('setNewToken', [
  when(state`auth.token`), {
    true: [],
    false: [
      generateFingerprint,
      set(state`auth.token`, props`fingerprint`)
    ]
  }
])
