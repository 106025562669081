import { state } from 'cerebral'
import { Ws } from '@/utils'
import { sendToWS2 } from '../../../utils'

export const send = ({ store, get, props, path }) => {
  const { method, params } = props
  if (!method) {
    return
  }
  const data = JSON.stringify(params ? { method, params } : { method })

  try {
    const ws = Ws()
    const socketIsConnected = get(state`socket.isConnected`)
    const authIsSuccessful = get(state`auth.isSuccessful`)
    if (ws && ws.readyState !== undefined && ws.readyState === ws.OPEN && ws.readyState !== ws.CONNECTING && socketIsConnected && authIsSuccessful) {
      ws.send(data)
      sendToWS2(data)
      if (path && path.done) {
        return path.done()
      }
    } else {
      store.push('socket.mustSend', data)
      if (path && path.fail) {
        return path.fail()
      }
    }
  } catch (e) {
    store.push('socket.mustSend', data)
  }
}
