import { set, wait, when } from 'cerebral/factories'
import { state } from 'cerebral'
import routeToRoom from './routeToRoom'
import panelsOnPop from './panelsOnPop'
import { isBroadcastBlocked } from '@/utils/buffUtils'
import { buffPanel } from '../../../components/Panels/types'
import createPanel from '../../app/signals/createPanel'
import sendCreateBroadcast, { saveMetadata } from '../../room/signals/sendCreateBroadcast'
import { topicCreate } from '../../../utils/amplitudeLiveEvents'
import { checkMediaDevices } from '../../../utils/stream/streamUtil'
import { showSnackbarNew } from '../factories'
import { getLocaleValue } from '../../../utils/localeUil'

export default [
  ({ path }) => {
    const buffId = isBroadcastBlocked()
    if (!checkMediaDevices()) {
      return path.noMedia()
    }
    if (buffId) {
      return path.forbidden({ buffId })
    } else {
      return path.allowed()
    }
  }, {
    noMedia: [
      () => {
        showSnackbarNew(getLocaleValue('no_streaming_available_2'), 5000, 'error')
      }
    ],
    forbidden: [
      ({ props, get }) => buffPanel(props.buffId, get(state`auth.uid`), null),
      createPanel,
    ],
    allowed: [
      () => ({ hideAll: true }),
      panelsOnPop,
      wait(260),
      ({ get }) => ({ roomId: get(state`auth.uid`) }),
      routeToRoom,
      wait(600),
      when(state`room.streamInRoom`), {
        true: sendCreateBroadcast,
        false: [
          set(state`room.streamInRoom`, state`auth.uid`),
          topicCreate,
          saveMetadata
        ]
      },
    ]
  },
]
