import { state } from 'cerebral'
import isVip from '../../../utils/isVip'
import showInRoom from '../../posters/showInRoom'

export const setPaidTranslations = ({ props, store, get, path }) => {
  const paidBroadcasts = props.params
  const allBroadcasts = get(state`posters.raw`) || {}
  const route = get(state`app.route`)
  const myUid = get(state`auth.uid`)
  const roomId = get(state`app.roomId`)
  const coins = get(state`auth.coins`) || 0

  if (props.params.uid) {
    if (allBroadcasts[props.params.uid]) {
      allBroadcasts[props.params.uid].cost = props.params.cost
    }
    const paidRooms = get(state`app.paidRooms`)
    paidRooms[props.params.uid] = props.params.cost
    store.set('app.paidRooms', paidRooms)
  } else {
    store.set('app.paidRooms', paidBroadcasts)
  }
  store.set('posters.raw', allBroadcasts)

  if (route !== 'room' || myUid === roomId) {
    return path.doNothing()
  }

  const currentBroadcast = get(state`posters.raw.${roomId}`)
  const broadcastCost = get(state`app.paidRooms.${roomId}`) || 0
  if (!currentBroadcast || !broadcastCost || !showInRoom(currentBroadcast)) {
    return path.doNothing()
  }

  const isInPrivate = get(state`app.isInPrivate`)
  if (isInPrivate && isInPrivate === roomId) {
    return path.doNothing()
  }
  const privateShow = get(state`posters.privates.${roomId}`)
  if (privateShow && (privateShow.model === myUid || privateShow.viewer === myUid)) {
    return path.doNothing()
  }

  if (coins < broadcastCost && !isVip(myUid)) {
    return path.needCoins()
  }

  return path.doNothing()
}
