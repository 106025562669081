import config from '@/config'
import { getSessionId } from '@/utils'
import { delBuff } from '../actions'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'

const { ftCoreUrl } = config

export default [
  ({ props, path }) => {
    const buff = {
      toUid: props.uid,
      cardType: props.buffId,
      startTime: Date.now()
    }
    if (props.method === 'DELETE') {
      return path.delete({ buff })
    } else {
      return path.add({ buff })
    }
  }, {
    delete: delBuff,
    add: []
  },
  ({ props }) => {
    fetch(getCoreUrl('/api/v1/buffs'), {
      method: props.method,
      body: JSON.stringify({
        to_uid: props.uid,
        buff_id: parseInt(props.buffId),
      }),
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    }).catch(e => console.warn('catch error', e))
  }
]
