import { state } from 'cerebral'
import controller from '@/controller'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { isPostFixed } from '../../../utils/isFixedPost'

import config from '@/config'
import { getSessionId } from '@/utils'

const { ftCoreUrl } = config

export default [
  ({ props, store, get, path }) => {
    const postId = props.postId
    const postData = get(state`posts.postsData.${postId}`)
    const index = postData.tags.indexOf(1063614)

    fetch(`${ftCoreUrl}/api/v1/posts/${postId}/anchor`, {
      method: isPostFixed(postData) ? 'DELETE' : 'PUT',
      body: new URLSearchParams({
        sid: getSessionId(),
      })
    }).catch(e => console.warn('catch error', e))

    if (isPostFixed(postData)) {
      const showSnackbar = controller.getSequence('app.showSnackbar')
      showSnackbar({
        text: get(state`intl.content.ui_kit_post_unpinned`),
        ms: 500,
        type: 'unpin'
      })
      postData.tags.splice(index, 1)
    } else {
      postData.tags.push(1063614)
    }
    store.set(`posts.postsData.${postId}`, postData)

    //Пересортировка постов юзера, стираем, чтобы при рендере вызвался getUserPosts
    const uid = get(state`auth.uid`)
    store.unset(state`posts.allUserPostsLoaded.${uid}`)
    store.unset(state`posts.userPosts.${uid}`)

    return path.closePanel()
  },
  {
    closePanel: [
      panelsOnPop,
      /*wait(250),
      ({ get }) => ({ roomId: get(state`auth.uid`) }),
      routeToRoom,*/
    ],
  }
]
