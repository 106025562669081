import { getUser } from '@/utils/userLoader'

import controller from '@/controller'

const isVip = (uid = null) => {
  let vip_subscription = null
  if (!uid || uid === controller.getState('auth.uid')) {
    vip_subscription = controller.getState('auth.vip_subscription')
  }
  if (!vip_subscription) {
    const user = getUser(uid || controller.getState('auth.uid'))
    if (user) vip_subscription = user.vip_subscription
  }

  if (vip_subscription && vip_subscription.date_until) {
    const parsed = Date.parse(vip_subscription.date_until)
    if (Date.now() < parsed && vip_subscription.status !== 'NOT_ACTIVE') {
      return vip_subscription
    }
  }
  return false
}

export default isVip
