import { sequence, state } from 'cerebral'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { merge } from 'cerebral/factories'
import controller from '@/controller'

export const showSnackbar = (text, ms = 5000, type = 'success', additionalInfo = null) =>
  sequence('showSnackbar', [
    merge(state`app.snackbar`, { state: 'in', text, type, additionalInfo }),
    ({ props }) => {
      let timeout = getState('snackbarTimeout', 0)
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        setStoreWrapper('app.snackbar', { state: 'out', text: '', type: 'success' })
      }, props.ms || 7000)

      setStoreWrapper('snackbarTimeout', 0)
    }
  ])

export function showSnackbarNew(text, ms = 5000, type = 'success', additionalInfo = null) {
  const showSnackbar = controller.getSequence('app.showSnackbar')
  showSnackbar({
    text: text,
    ms: ms,
    type: type,
    additionalInfo: additionalInfo
  })
}

