import { callSequence, setStoreWrapper } from '../StoreManager'
import { getPostAuthor } from '../postUtils'
import { checkMySubscription } from '../subscriptionUtil'
import { isUID } from '../isUID'
import { checkSelectedFeed } from '../../modules/filters/actions/feedUtil'
import { TYPE_FANS } from '../../modules/posts/signals/getPosts'
import { reduceFansCount } from '../dating/datingNewContentUtil'
import { rememberScrollPosition } from '../scrollRememberUtil'

const LOAD_MORE_GAP = 5

export function getProfileDatingCardID(postId){
  return 'profilePost' + postId
}

export function scrollToNextPost(postId) {
  const currentElem = document.getElementById(getProfileDatingCardID(postId))
  if (!currentElem || !currentElem.parentNode || !currentElem.parentNode.children) {
    return
  }
  if (checkSelectedFeed(TYPE_FANS)) {
    reduceFansCount()
  }

  const children = currentElem.parentNode.children
  let element
  for (let i = 0; i < children.length; i++) {
    element = children[i]
    if (element === currentElem) {
      for (let t = i + 1; t < children.length; t++) {
        const nextElement = children[t]
        if (nextElement) {
          if (nextElement.id === 'profilePostundefined' || nextElement.id === 'emptyILike') {
            setStoreWrapper('currentPostId', -1)
            setStoreWrapper('currentUser', null)
            setTimeout(() => {
              nextElement.scrollIntoView({ behavior: 'smooth' })
              // setTimeout(()=>{ rememberScrollPosition() }, 1000)
            }, 250)
            return
          }
          const postId = elemIdToPostId(nextElement.id)
          if (!checkMySubscription(isUID(postId) ? postId : getPostAuthor(postId))) {
            setTimeout(() => {
              nextElement.scrollIntoView({ behavior: 'smooth' })
              // setTimeout(()=>{ rememberScrollPosition() }, 1000)
            }, 250)
            //если осталось не отсмотренными 3 профиля, то грузим следующую страницу
            if (t + LOAD_MORE_GAP > children.length) {
              callSequence('posts.getMorePosts')
            }
            setStoreWrapper('currentPostId', postId)
            setStoreWrapper('currentUser', isUID(postId) ? postId : getPostAuthor(postId))
            // setStoreWrapper('scrollNext', null)
            return
          }
        }
      }
    }
  }
  // setStoreWrapper('scrollNext', null)
}


export function scrollToPost(postId) {
  const currentElem = document.getElementById(getProfileDatingCardID(postId))
  if (currentElem) {
    currentElem.scrollIntoView()
  }
}

function elemIdToPostId(elemId) {
  return elemId.replace('profilePost', '')
}
