import { getSessionId } from '../cookies'
import { getCoreUrl } from './apiURLUtil'
import { sendAmplitudeEvent } from '../GAEvent'

export async function sendForNeuralCheckMedia(mediaId, callback) {
  try {
    const response = await fetch(getCoreUrl('/api/v1/photos/check-media/' + mediaId), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    if (!response.ok) {
      if (callback) {
        callback('error', {})
      }
      return
    }
    const result = await response.json()
    if (callback) {
      callback('success', result)
    }
  } catch (e) {
    console.error('error neuro ' + e)
    if (callback) {
      callback('error', {})
    }
  }
}

export async function sendForNeuralCheck(url, callback) {
  if (!url) {
    sendAmplitudeEvent('error_neural_url')
    return
  }
  try {
    const response = await fetch(getCoreUrl('/api/v1/photos/check?url=' + url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    if (!response.ok) {
      if (callback) {
        callback('error', {})
      }
      return
    }
    const result = await response.json()
    if (callback) {
      callback('success', result)
    }
  } catch (e) {
    console.error('error neuro ' + e)
    if (callback) {
      callback('error', {})
    }
  }
}
