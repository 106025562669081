import { getState, setStoreWrapper } from './StoreManager'
import { setMyLSProps } from './LocalStorageUtil'
import useDialogsStore from '../zustand/useDialogsStore'
import { MY_DEFAULT_UID } from './checks'

export const CHAT_DRAFT = 'chatDraft'

export function getChatDraft(chatKey) {
  if (!getState(CHAT_DRAFT)) {
    return ''
  }
  return getState(CHAT_DRAFT)[chatKey] || ''
}

export function setChatDraft(chatKey, value) {
  const drafts = getState(CHAT_DRAFT) || {}
  drafts[chatKey] = value
  setStoreWrapper(CHAT_DRAFT, drafts)

  setMyLSProps(CHAT_DRAFT, drafts)
}

export function getChatKey(id, isDialog = false) {
  if (!isDialog) {
    return id
  }

  return useDialogsStore.getState().getDialogHash(MY_DEFAULT_UID, id, '')
}

export function getDialogDraft(id) {
  return getChatDraft(getChatKey(id, true))
}
