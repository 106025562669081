import { getMyLSProp } from '../LocalStorageUtil'
import { isBuffActive } from '../buffUtils'
import { ROOM_CHAT_BAN } from '../../modules/buffs'
import { getMyUid } from '../my'

export function isMessageBan(msgId) {
  const bannedMSG = getMyLSProp('bannedMSGid') || {}
  return bannedMSG[msgId]
}

export function isUserMessageBanned(uid) {
  return isBuffActive(uid, ROOM_CHAT_BAN, getMyUid())
}

