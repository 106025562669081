import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'
import { handlePanelRoute } from '../actions/handlePanelRoute'

export default [
  ({ props }) => ({
    panelRoute: 'dialog'
  }),
  dispatchUrlChangeToParent,
  handleRoute,
  handlePanelRoute
]
