import { state } from 'cerebral'

export const checkIfUserModelOrViewer = ({ store, get, props, path }) => {
  const user = get(state`auth.uid`)
  if (user == props.model) {
    return path.model()
  } else if (user == props.viewer) {
    return path.viewer()
  } else {
    return path.other()
  }
}
