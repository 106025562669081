import { push, wait, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { panelsOnPop } from '../../app/actions'
import { showSnackbar } from '../../app/factories'
import { postersRoute } from '@/utils/routerHelpers'
import prepareAndRouteTo from '../../app/signals/prepareAndRouteTo'

export default [
  push(state`posts.complaints`, props`postId`),
  panelsOnPop,
  wait(250),
  when(state`app.route`, route => route === 'post'), {
    true: [
      () => ({ url: postersRoute }),
      prepareAndRouteTo,
    ],
    false: []
  },
  //filterPosts,
  showSnackbar(state`intl.content.claim_success`, 3000, 'success'),
]
