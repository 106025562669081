import { state } from 'cerebral'
import { send } from '../../socket/actions'
import isVip from '../../../utils/isVip'
import { isMe } from '../../../utils'
import { isUID } from '../../../utils/isUID'
import { getRoomOwnerUid } from '../../../utils/navigation/navigationUtil'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { clearCardGame } from '../../../utils/cardGame/cardGameAPI'
import { sendProfileView } from '../../../utils/userUtil'
import { roomEntranceAction } from '../../../utils/amplitudeLiveEvents'

export default [
  ({ get, path, store }) => {
    let roomId = get(state`app.roomId`)
    const lastRoomId = get(state`app.lastRoomId`)

    if (roomId) {
      const privateShow = get(state`posters.privates.${getRoomOwnerUid(roomId)}`)
      if (privateShow) {
        if (isUID(roomId) && !isMe(privateShow.viewer) && !isMe(roomId)) {
          roomId = 'specialPublic_' + roomId
        }
      } else {
        if (!isUID(roomId)) {
          roomId = getRoomOwnerUid(roomId)
        }
      }
    }
    if (roomId === lastRoomId && !get(state`forceSendGoInRoom`)) return path.doNothing()

    roomEntranceAction(roomId)
    store.set('forceSendGoInRoom', false)
    store.set('animateBoss', null)
    if (!roomId) return path.sendData({ method: 'goInRoom' })

    const broadcast = get(state`posters.raw.${state`app.roomId`}`)
    if (!broadcast) {
      return path.sendData({ method: 'goInRoom', params: { roomId } })
    }
    // if (!showInRoom(broadcast)) {
    //   return path.doNothing()
    // }

    const myUid = get(state`auth.uid`)
    const allowedPaidStreamId = get(state`room.allowedPaidStreamId`)
    const broadcastCost = get(state`app.paidRooms.${roomId}`) || 0
    const needCoins = allowedPaidStreamId !== roomId && broadcast && broadcastCost && roomId !== myUid && !isVip()

    if (needCoins) {
      // store.set('app.lastRoomId', null)
      return path.doNothing()
    }

    return path.sendData({ method: 'goInRoom', params: { roomId } })
  }, {
    doNothing: [],
    sendData: [
      () => {
        clearCardGame()
      },
      send,
      ({ props, store }) => {
        if (props.params && props.params.roomId) {
          store.set('chat.chatRoomId', props.params.roomId)
          if (!isUID(props.params.roomId)) {
            sendAmplitudeEvent('go_in_special_room')
          } else {
            if (!isMe(props.params.roomId)) {
              sendProfileView(props.params.roomId)
            }
          }
        } else {
          store.set('chat.chatRoomId', null)
        }
        store.set('app.lastRoomId', props.params ? props.params.roomId : null)
      }
    ]
  },
]
