import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import { send } from '../../socket/actions'

export default [
  ({ store, get }) => {
    const roomId = get(state`app.isInPrivate`)
    store.unset(`posters.raw.${roomId}`)
  },
  () => ({ method: 'directCallEnd' }),
  send,
  ({ get, store }) => {
    store.set('app.wasInPrivate', get(state`app.isInPrivate`))
  },
  set(state`app.isInPrivate`, null),
  set(state`room.callingTo`, null),
]
