import { setStoreWrapper } from '../../utils/StoreManager'
import { getProfilePostId } from '../../utils/userUtil'
import { getPostData } from '../../utils/postUtils'
import { sendPercentAmplitudeEvent } from '../../utils/GAEvent'

export function openFullscreenPlayer (fileData, type) {
  setStoreWrapper('app.fullscreenFileData', fileData)
  setStoreWrapper('app.showFullscreenPlayer', true)
  sendPercentAmplitudeEvent('fullscreen', { type: type || 'player' })
}

export function closeFullscreenPlayer () {
  setStoreWrapper('app.fullscreenFileData', null)
  setStoreWrapper('app.showFullscreenPlayer', false)
}

export function openUserProfilePic(uid) {
  const profileId = getProfilePostId(uid)
  if (!profileId){
    return
  }
  const pd = getPostData(profileId)
  if (pd){
    openFullscreenPlayer(pd)
  }
}
