import { getChatDraft } from '../../../utils/inputUtil'
import useDialogsStore from '../../../zustand/useDialogsStore'

export default ({ props, store, get, path }) => {
  const currentText = getChatDraft(props.chatKey)
  if (props.key === 'Enter' && currentText.trim().length > 0) {
    if (useDialogsStore.getState().companionUid) {
      useDialogsStore.getState().sendDialogMessage()
      return path.doNothing()
    }
    return path.sendChatMessage()
  }
  if (props.key === 'Backspace' && currentText === '') {
    store.set('chat.toId', '')
  }
  return path.doNothing()
}
