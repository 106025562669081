import signals from './signals'

export default {
  state: {
    complaints: [],
    myLikes: [],
    viewedPhotos: [],
    topPosts: [],
    fixedPosts: {},
    fixedPostsLoadedPages: {},
    userPosts: {},
    userPostsLoadedPages: {},
    similarPosts: {},
    postsData: {},
    watchedUsers: {},
    filterCounter: 0,
    filters: [
      {
        period: [{
          period: 'recently',
          localeKey: 'recent',
          id: 'recent',
          priority: 997
        }, {
          period: 'weekly',
          localeKey: 'weekly',
          id: 'weekly',
          priority: 996,
          setIndefault: 7
        }, {
          period: 'monthly',
          localeKey: 'monthly',
          id: 'monthly',
          priority: 995,
          setIndefault: 25
        }
        ]
      },
      {
        type: [{
          localeKey: 'live_only',
          id: 'live_only',
          priority: 1000
        }, {
          localeKey: 'photo_only',
          postType: [0],
          id: 'photo_only',
          priority: 999
        }, {
          localeKey: 'post_kind_videochat_video',
          postType: [1],
          id: 'video_only',
          priority: 998
        }
        ]
      },
      {
        tags: [
          2, 3, 5, 6, 7, 8, 9,
          10, 17, 19, 21, 22, 23,
          24, 25, 26, 27, 28, 29,
          30, 34, 35, 36, 40, 41,
          49, 83407, 1021552, 1017214
        ]
      }
    ],
    selectedTags: [],
    priorityTags: [],
    tagsIdExcluded: [],
    userTags: [],//теги из описания постов
    loadingPostsStatus: 'not_ready',//not_loaded, loading, loaded, loaded_all
    loadingFixedPostsStatus: 'not_ready',//not_loaded, loading, loaded, loaded_all
    loadingUserPostsStatus: 'not_ready',//not_loaded, loading, loaded, loaded_all
    loadingSimilarPostsStatus: 'not_ready',//not_loaded, loading, loaded, loaded_all
    postsLoaded: false,
    allUserPostsLoaded: {},
    allFixedPostsLoaded: {},
    allSimilarPostsLoaded: {},
    allSimilarPostsPages: {},
    buyingPostId: -1,
    selectedPostId: null,
    selectedVideoUrl: null,
    isHDQualitySelected: false,
  },
  sequences: signals
}
