import { getUserPrivateThemes } from '../directCallThemeUtil'
import { createAskPrivatePanel, createGetVipPanel } from '../../components/Panels/createPanelUtil'
import { imVIP } from '../my'

export function callToUserClick(toUid) {
  if (!imVIP() && onlyVIPCall()) {
    createGetVipPanel('call_vip_only')
    return
  }
  const privates = getUserPrivateThemes(toUid)
  if (privates.length) {
    createAskPrivatePanel(toUid)
  }
}

export function onlyVIPCall() {
  return false
}
