import { state } from 'cerebral'
import config from '@/config'
import { getSessionId } from '@/utils'
import { getUserSubsPosts } from '../../posts/signals/getPosts'
import { setNewPostsCount } from '../../../utils/newPostsCountUtli'
import { getMyUid } from '../../../utils/my'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'

const { ftCoreUrl } = config

const loadSubsAndFans = async context => {
  context.store.set('auth.fansLoadStatus', '')
  context.store.set('auth.subsLoadStatus', '')
  context.store.set('auth.paidFans', {})
  context.store.set('auth.fans', {})
  context.store.set('auth.subs', {})

  getUserSubsPosts(context)

  updateSubsAndFansCount(context)
  await loadNextSubs(context)
  await loadAllPaidFans(context, true)
}

let errorTime
const updateSubsAndFansCount = async context => {
  // context.store.set('auth.subsCount', 0)
  // context.store.set('auth.fansCount', 0)
  // if (!getSessionId() || !getMyUid() || (errorTime && errorTime - Date.now() < 5000)) {
  //   return
  // }
  //
  // try {
  //   const fResponse = await fetch(`${ftCoreUrl}/api/v1/subscriptions/followers/count?uid=${getMyUid()}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'ft-sid': getSessionId()
  //     },
  //   })
  //   const fans = await fResponse.json()
  //   const sResponse = await fetch(`${ftCoreUrl}/api/v1/subscriptions/authors/count?uid=${getMyUid()}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'ft-sid': getSessionId()
  //     },
  //   })
  //   const subs = await sResponse.json()
  //
  //   context.store.set('auth.subsCount', subs.count || 0)
  //   context.store.set('auth.fansCount', fans.count || 0)
  // } catch (e) {
  //   errorTime = Date.now()
  //   console.log('@@@ load counts error')
  // }
}

const startLoad = async (type, statePath, { store, get }, page, isPaid = false, online = false) => {
  if (!getSessionId()) {
    return false
  }

  try {
    let url = `${ftCoreUrl}/api/v1/subscriptions/${type}?page=${page}`
    if (isPaid) {
      url += '&paid=true'
    }
    if (online) {
      url += '&only_online=true'
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    const users = (await response.json() || {})[type]
    //если это мои подписки, то проверим есть ли новые посты
    if (statePath === 'auth.subs') {
      const subs = getState('auth.subs', {})
      Object.keys(users).map(uid => {
        subs[uid] = users[uid]
        if (users[uid].postsCount) {
          setNewPostsCount(uid, users[uid].postsCount)
        }
      })
      setStoreWrapper('auth.subs', subs)
    } else {
      store.set(statePath, { ...get(state`${statePath}`), ...users })
    }

    if (Object.keys(users).length < 50) {
      return true
    }
  } catch (e) {
    console.warn(`@@@ load ${type} error:`, e)
    return true
  }
  return false
}

export const loadAllPaidFans = async context => {
  if (context.get(state`auth.paidFansLoadStatus`) !== '') {
    return
  }
  context.store.set('auth.paidFansLoadStatus', 'loading')
  const fans = context.get(state`auth.paidFans`) || {}
  const page = Math.floor(Object.keys(fans).length / 50)
  const loadedAll = await startLoad('followers', 'auth.paidFans', context, page, true)

  context.store.set('auth.paidFansLoadStatus', loadedAll ? 'all_loaded' : '')
  if (!loadedAll) {
    setTimeout(() => loadAllPaidFans(context), 1000)
  }
}

export const loadNextSubs = async context => {
  if (context.get(state`auth.subsLoadStatus`) !== '') {
    return
  }
  context.store.set('auth.subsLoadStatus', 'loading')
  const subs = context.get(state`auth.subs`)
  const page = Math.floor(Object.keys(subs).length / 50)
  const loadedAll = await startLoad('authors', 'auth.subs', context, page)

  context.store.set('auth.subsLoadStatus', loadedAll ? 'all_loaded' : '')
}

export const loadNextFans = async context => {
  if (context.get(state`auth.fansLoadStatus`) !== '') {
    return
  }
  context.store.set('auth.fansLoadStatus', 'loading')
  const fans = context.get(state`auth.fans`)
  const page = Math.floor(Object.keys(fans).length / 50)
  const loadedAll = await startLoad('followers', 'auth.fans', context, page)

  context.store.set('auth.fansLoadStatus', loadedAll ? 'all_loaded' : '')
}

export const loadNextOnline = async context => {
  if (context.get(state`auth.onlineLoadAuthorsStatus`) !== '') {
    return
  }
  context.store.set('auth.onlineLoadAuthorsStatus', 'loading')
  const subs = context.get(state`online.authors`)
  const page = Math.floor(Object.keys(subs).length / 50)
  const loadedAll = await startLoad('match-authors', 'online.authors', context, page)

  context.store.set('auth.onlineLoadAuthorsStatus', loadedAll ? 'all_loaded' : '')
}

export default context => loadSubsAndFans(context)
