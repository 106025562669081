import { push, shift, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { send } from '../../socket/actions'
import { subscribeOnLike } from '../../auth/actions/subscribeOnLike'
import { getPostAuthor } from '../../../utils/postUtils'
import { getSequence, getState } from '../../../utils/StoreManager'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { moderEditPanel } from '../../../components/Panels/types'
import { createProfileCreationPanel } from '../../../components/Panels/createPanelUtil'
import { POSTRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'

export function datingNeedProfile(key) {
  sendAmplitudeEvent('dating', {
    action: key//'need_set_profile'
  })
  createProfileCreationPanel(key)
  // getSequence('moderation.postModerationOpen')({ isProfile: true })
  // getSequence('app.createPanel')(moderEditPanel(null, 740, true, key))
}

export default [
  ({ get, path, store, props }) => {
    if (getState('posts.myLikes').indexOf(props.postId) === -1) {
      return path.sendLike()
    }

    return path.dislike()
  }, {
    dislike: [
      ({ get, store, props }) => {
        const likes = get(state`posts.myLikes`)
        const index = likes.indexOf(props.postId)
        likes.splice(index, 1)
        store.set('posts.myLikes', likes)
      }
    ],
    sendLike: [
      // ({ props }) => ({
      //   params: { postId: Number(props.postId) },
      //   method: 'postLike'
      // }),
      ({ props }) => {
        subscribeOnLike(getPostAuthor(props.postId))
        POSTRequest(getCoreUrl('/api/v1/posts/' + props.postId + '/like'), {
          }, (data) => {
          console.log(data)
        })
      },
      // send,
      push(state`posts.myLikes`, props`postId`),
      when(state`posts.myLikes`, myLikes => myLikes.length > 100), {
        true: [
          shift(state`posts.myLikes`)
        ],
        false: []
      }
    ],
    cantLike: [
      () => {
        datingNeedProfile('need_set_profile')
      }
    ]
  },

  when(state`posts.myLikes`, props`postId`, (myLikes, postId) => myLikes.indexOf(postId) === -1), {
    true: [],
    false: []
  },
]
