import { state } from 'cerebral'
import config from '@/config'

const { httpApiUrl } = config

export const resetPasswordRequest = async ({ path, get }) => {
  const { email, password } = get(state`auth.resetPasswordForm`)

  const fd = new FormData()
  fd.append('email', email)
  fd.append('password', password)
  fd.append('domain', location.host)

  const response = await fetch(httpApiUrl + '/auth/restore_password', {
    method: 'POST',
    body: fd
  })
  if (!response.ok) {
    return path.requestError({ status: response.status })
  }

  const result = await response.json()
  if (result.result) return path.success({ email })
  if (result.error) return path.error({ error: result.error })
}
