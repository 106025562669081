import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'

export default [
  () => ({ route: 'post' }),
  ({ store, get, props }) => {
    const postNum = props.routeArgs[0]
    store.set('posts.selectedPostId', parseInt(postNum))
  },
  dispatchUrlChangeToParent,
  handleRoute
]
