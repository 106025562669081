import config from '@/config'
import { getSessionId } from '@/utils'

const { ftCoreUrl } = config

export function updateMatchDate() {
  sendUpdate('update-match-authors-date')
}

export function updateFansDate() {
  sendUpdate('update-match-followers-date')
}

async function sendUpdate(type) {
  if (!getSessionId()) {
    return
  }

  try {
    /** всега грузим только первую страницу, этого хватит для баров,
     общее число онлайна можно запросить отдельно и показать в UI */

    let url = `${ftCoreUrl}/api/v1/subscriptions/` + type
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })

    // обработка вроде не нужна
    let res = await response.json()
  } catch (e) {
    console.warn('@@@ loadOnlineSubs error:', e)
  }
}
