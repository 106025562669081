import { isStreaming } from '../modules/socket/actions'
import { PROMOTION } from '../modules/buffs'
import { isBuffActive } from './buffUtils'
import { getMyUid } from './my'

export function isPopularUser(uid) {
  if (isStreaming(getMyUid(), true)) {
    return false
  }
  return isStreaming(uid, true) || isBuffActive(uid, PROMOTION)
}

export const SeparateDescriptionAndLink = text => {
  const dividerIndex = text.indexOf('\n')
  return [text.substring(0, dividerIndex + 1), text.substring(dividerIndex + 1, text.length + 1)]
}
