import { when } from 'cerebral/factories'
import { state } from 'cerebral'
import { routeToFactory } from '../factories'
import { shopRoute } from '@/utils/routerHelpers'
import { getSessionId } from '@/utils'
import { isTWA } from '@/utils/twa'
import { amplitudeApiKey, sendAmplitudeEvent } from '../../../utils/GAEvent'
import { isMSPWA } from '../../../utils/twa'

export default [
  ({ store, props }) => {
    store.set('shop.additionalShopMessage', props.message || null)
    if (props.reason) store.set('shop.openReason', props.reason)
  },
  ({ path, get }) => {
    if (get(state`shop.disable`)) {
      return path.doNothing()
    }
    if (isTWA()) {
      if (get(state`app.twa_payment_config`) || isMSPWA()) {
        return path.jsShop()
      } else {
        return path.nativeShop()
      }
    } else {
      return path.jsShop()
    }
  }, {
    doNothing: [
      () => {
        sendAmplitudeEvent('open_shop', {
          type: 'shopDisable'
        })
      },
    ],
    nativeShop: [
      ({ get, props }) => {
        const sid = getSessionId()
        const lang = get(state`intl.lang`)
        let appId = get(state`app.appId`)
        const message = props.message
        let msg = ''
        if (message !== undefined) {
          msg = '&msg=' + message
        } else {
          msg = ''
        }
        if (appId === 'flirty.chat') {
          appId = 'com.flirty.chat'
        }
        const linkTWA = appId + '://pay?session_id=' + sid + '&lng=' + lang + msg +
          '&user_id=' + get(state`auth.uid`) +
          '&api_key=' + amplitudeApiKey +
          '&reason=' + (props.reason || 'click-shop-coins')
        sendAmplitudeEvent('open_shop', {
          type: 'native'
        })
        window.open(encodeURI(linkTWA), '_self')
      }
    ],
    jsShop: [
      () => {
        sendAmplitudeEvent('open_shop', {
          type: 'jsShop'
        })
      },
      when(state`app.panels`, panels => panels.length && panels[panels.length - 1].type === 'shop'), {
        true: [],
        false: [
          routeToFactory(shopRoute),
        ],
      },
    ]
  }
]
