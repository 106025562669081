import { state } from 'cerebral'
import {
  dialogPanel,
  dialogsList,
  getVipPanel,
  payoutPanel,
  settingsPanel,
  shopPanel,
  spamPanel,
  userPanel,
  usersListPanel,
} from '../../../components/Panels/types'
import { isTWA } from '@/utils/twa'
import { isMSPWA } from '../../../utils/twa'
import useDialogsStore from '../../../zustand/useDialogsStore'

export const panelRoutes = {
    dialogs: dialogsList,
    girl: userPanel,
    dialog: dialogPanel,
    spam: spamPanel(),
    coins: shopPanel,
    getVip: getVipPanel,
    payout: payoutPanel,
    fans: usersListPanel('fans'),
    subs: usersListPanel('subs'),
    settings: settingsPanel,
    search: usersListPanel('all'),
}

export const handlePanelRoute = ({ store, get, props }) => {
    if (props.url === '/vip' && (isTWA() && !isMSPWA()) && !get(state`app.twa_payment_config`)) {
        return
    }

    const panels = get(state`app.panels`)
    let panel = panelRoutes[props.panelRoute]
    if (props.panelRoute === 'girl') {
        panel = panel(props.routeArgs[0])
    }
    if (props.panelRoute === 'dialog') {
        useDialogsStore.getState().setCompanionUid(props.routeArgs[0])
        panel = panel(props.routeArgs[0])
    }
    if (panels.length) {
        if (panels.length >= 2) {
            if (
                (panels[panels.length - 1].type !== panel.options.type)
            ) {
                if (panels[panels.length - 2].type !== panel.options.type || panel.options.type === 'shop') {
                    store.push('app.panels', panel.options)
                }
            }
        }
        if (panels.length === 1) {
            if (panels[panels.length - 1].type !== panel.options.type) {
                store.push('app.panels', panel.options)
            }
        }
    } else {
        store.push('app.panels', panel.options)
    }
}
