import React from 'react'
import './levels.css'
import { bool, number, object, string } from 'prop-types'
import { getLVLPicNumber, getNextLVLPicNumber } from '../../utils/levelUtil'
import { getUserGEOCountry, getUserRating, isTopRated, isVerified } from '../../utils/userUtil'
import { getShowcasePicUrl } from '../../utils/showcase/getShowcase'
import { getBaseImageUrl, getImageUrl } from '../../utils/urlUtils'
import { isCustomVanilla } from '../../utils/api/PWAUtil'

function getVerify(noVerify, uid, size) {
  return ((!noVerify && isVerified(uid)) ?
    <i className="buttonIcon icon-verified_badge" style={{ fontSize: size, color: '#2096F3' }}/> : null)
}

function getTopRatedBadge(uid, size, maxStars) {
  if (isCustomVanilla()) {
    return null
  }
  if (isTopRated(uid) && maxStars) {
    return (<img alt={'User rating shape'} src={getShowcasePicUrl(Math.floor(getUserRating(uid)))}
                 style={{ width: size, height: size }}/>)
  }

  return null
}

const LevelShape = ({
                      uid,
                      size,
                      nextLvlToUser,
                      noVerify,
                      maxStars,
                      style,
                      noStars,
                      withFlag
                    }) => {
  if (isCustomVanilla()) {
    return null
  }
  let picID
  if (nextLvlToUser) {
    picID = getNextLVLPicNumber(uid)
  } else {
    picID = getLVLPicNumber(uid)
  }

  //в зависимости от размера - разные картинки
  let type
  if (size < 21) {
    type = picID + '@chat'
  } else if (size < 36) {
    type = picID + '@menu'
  } else {
    type = picID + '@big'
  }

  if (!style) {
    style = { display: 'flex', alignItems: 'center' }
  }
  let countryCode
  if (withFlag) {
    countryCode = getUserGEOCountry(uid, false, true)
  }

  return (<div style={style}>
      {countryCode &&
        <img alt={'User Country Flag'} style={{ height: 20, width: 20, borderRadius: 20, objectFit: 'cover' }}
             src={getBaseImageUrl('/flags_1/l/' + countryCode + '.webp')}/>
      }
      {getVerify(noVerify, uid, size)}
      {picID > 0 &&
        <img src={`${getImageUrl()}/levels_new/${type}.webp`} alt={'User level shape'} style={{
          width: size + 'px',
          height: size + 'px'
        }}/>
      }
      {!noStars && getTopRatedBadge(uid, size, maxStars)}
    </div>
  )
}

LevelShape.propTypes = {
  maxStars: bool,
  nextLvlToUser: bool,
  noStars: bool,
  noVerify: bool,
  size: number,
  style: object,
  uid: string,
  withFlag: bool
}

export default LevelShape
