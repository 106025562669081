import { onclose } from '../actions'
import createPanelWithoutUrl from '../../app/signals/createPanelWithoutUrl'
import { connectionLostPanel } from '@/components/Panels/types'

export default [
  ({ props }) => {
    console.log(props.message)
  },
  onclose, {
    connectionLostPanelIsOpen: [],
    loggedFromAnotherPlacePanelIsOpen: [],
    needToCreateConnectionLostPanel: [
      () => ({ options: connectionLostPanel.options }),
      createPanelWithoutUrl
    ]
  }
]
