import React, { Component } from 'react'
import LevelShape from './LevelShape'
import { number, string } from 'prop-types'
import { getLevel } from '../../utils/levelUtil'
import ImageWithLoader from '../ImageWithLoader'
import { getBaseImageUrl, getImageUrl } from '../../utils/urlUtils'

class AnimatedShape extends Component {

  state = {
    visible: true
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: false })
    }, this.props.duration)
  }

  componentWillUnmount() {

  }

  getShape() {
    const {
      size,
      uid,
      giftId
    } = this.props

    const swingType = 'swing' + Math.ceil(Math.random() * 3) + ' ' + Math.max(Math.random() * 1, 0.3) + 's alternate infinite ease-in-out'
    const lvl = getLevel(uid)

    if (giftId) {
      return (
        <div style={{ height: 'auto', position: 'relative', animation: 'swing3 1s alternate infinite ease-in-out' }}>
          <ImageWithLoader style={{ width: 100, height: 160 }} src={getBaseImageUrl('/presents/balloons_gift.webp')}/>
          <ImageWithLoader style={{ width: 100, height: 100, marginTop: -30 }}
                           src={getBaseImageUrl(`/presents/big/l/${giftId}.webp`)}/>
        </div>)
    }
    if (lvl > 0) {
      return <LevelShape uid={uid} size={size} noStars={true} noVerify={true} style={{ animation: swingType }}/>
    }

    return (<div style={{
      animation: swingType, minWidth: size, minHeight: size, fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      lineHeight: '1em',
      fontStretch: 'normal',
      fontStyle: 'normal',
      // backgroundColor: '#f6f6f6'
    }}>
      <i className={'icon-flirty-like-full buttonIcon filledLikeIcon'} />
      {/*<img src={`${getImageUrl()}/levels_new/snowflake@big.webp`} style={{*/}
      {/*  width: size + 'px',*/}
      {/*  height: size + 'px'*/}
      {/*}}/>*/}
    </div>)
  }

  render() {
    if (!this.state.visible) {
      return null
    }

    const animate = this.props.giftId ? ' flyUpOnGift 10s' : 'flyUpOnLike 3s'
    return (
      <div style={{ position: 'absolute', animation: animate, transform: 'translate(-50%, -50%)' }}>
        {this.getShape()}
      </div>
    )
  }
}

AnimatedShape.propTypes = {
  duration: number,
  giftId: string,
  size: number,
  uid: string
}

export default AnimatedShape
