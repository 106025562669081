import { onVideoCallClick } from '../../../utils/amplitudeLiveEvents'

export const checkForVideoPrivateAvailability = ({ get, path, props }) => {
  if (!props.withVideo) {
    return path.available()
  } else {
    onVideoCallClick()
  }
  if (navigator.mediaDevices) {
    return path.available()
  } else {
    return path.noVideoDevicesOrBisy()
  }
}
