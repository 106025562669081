import signals from './signals'

const fmchat = 'com.flirty' + 'mania.js'

export default {
  state: {
    allowedPaidStreamId: null,
    callingTo: null,
    streamStopped: false,
    peepingApproved: false,
    streamInRoom: null,
    isFrontCamera: true,
    isScreenShared: false,
    publisherMicEnabled: true,
    publisherCamEnabled: true,
    fullscreen: false,
    audioDevices: [],
    videoDevices: [],
    currentAudioDeviceIndex: 0,
    currentVideoDeviceIndex: 0,
    currentAudioDeviceId: null,
    currentVideoDeviceId: null,
    metadata: {
      webRTC: true,
      app_id: fmchat,
      hdAvailable: true,
      userAgent: window.navigator.userAgent,
    },
    noWebrtc: null,
    soundMuted: location.host === 'moderation.faminta.com' || location.host === 'moderation.faminta1.com',
    streamData: null,
    codec: '',
    roomEnterTime: 0,
    broadcastStartTime: 0,
    giftTops: {},
    peepUid: '',//uid модели, чье дейcтвие можно подcмотреть
    peepTimeLeft: 0,
    peepActivityId: '',
    peepPrivateData: null,
    popups: [],//маccив из таких обьектов { fromUid: 'c47ea4fcbd7e5965937d57609846bd31', titles:['act_sing_title'], pink:false }
    selectedActs: {},
    bannedPrivates: {},
    companionUid: null,
    proBroadcast: false,
    needUserIteraction: false
  },
  sequences: signals
}
