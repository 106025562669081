import { set, wait, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { postRoute } from '@/utils/routerHelpers'
import prepareAndRouteTo from '../../app/signals/prepareAndRouteTo'

export default [
    when(state`posts.selectedVideoUrl`, state`posts.selectedPostId`, props`postId`,
        (selectedVideoUrl, selectedPostId, postId) => selectedVideoUrl && selectedPostId !== postId), {
        true: [],
        false: []
    },
    // set(state`chat.chatInputText`, ''),
    set(state`posts.selectedPostId`, props`postId`),
    () => ({ hideAll: true }),
    panelsOnPop,
    wait(300),

    ({ props }) => ({
        url: postRoute,
        urlValue: props.postId
    }),
    prepareAndRouteTo,

]
