import { state } from 'cerebral'
import { postersRoute } from '@/utils/routerHelpers'
import { isModeration } from '../../../utils/moderationUtil'
import { getState } from '../../../utils/StoreManager'
import { GENDER_SELECT_PANEL } from '../../../components/Panels/types'
import useDialogsStore from '../../../zustand/useDialogsStore'

export const panelsOnPop = ({ router, props, store, get }) => {
  if (isPanelType(GENDER_SELECT_PANEL) && getState('genderSelectScreen')) {
    return
  }
  let panels = get(state`app.panels`)
  const lang = get(state`intl.lang`)
  const history = get(state`app.routesHistory`)
  const urlGetVariables = get(state`app.urlGetVariables`)
  if (!panels.length) {
    return
  }

  const lastPanel = panels[panels.length - 1]
  if (lastPanel.type === 'login' && isModeration && //location.host === 'moderation.faminta.com' &&
    !get(state`auth.admin`) && !get(state`auth.superAdmin`)) {
    return
  }

  store.set('shop.additionalShopMessage', '')
  store.set('app.shouldDestroyLastPanel', true)
  const closePanel = () => new Promise(resolve => {
    setTimeout(() => {
      store.set('app.shouldDestroyLastPanel', false)
      panels = panels.slice(0, panels.length - 1)
      store.set('app.panels', panels)
      if (!lastPanel || !lastPanel.type === 'dialog') {
        useDialogsStore.getState().setCompanionUid('')
      }
      if (!lastPanel.hidden) {
        if (history.length > 1) {
          history.pop()
          store.set('app.routesHistory', history)
          router.goTo(`/${lang}${history[history.length - 1]}${urlGetVariables}`)
        } else {
          router.goTo(`/${lang}${postersRoute}${urlGetVariables}`)
        }
      }
      resolve()
    }, 250)
  })
  if (props.hideAll) {
    if (panels.length > 0) {
      const promises = panels.map(() => closePanel())
      return Promise.all(promises).then(() => {
      })
    }
  } else {
    closePanel()
  }
}

export const getTopPanelType = () => {
  let panels = getState('app.panels')
  if (!panels.length) {
    return undefined
  }
  const lastPanel = panels[panels.length - 1]
  return lastPanel.type
}

export function isPanelType(type) {
  return getTopPanelType() === type
}
