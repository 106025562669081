import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import onNewMessage from './onNewMessage'
import { hideBadUrlsInDialog } from './checkChatMessage'
import { isMe } from '../../../utils'
import { MESSAGE_TYPE_AUTO, MESSAGE_TYPE_AWARD, MESSAGE_TYPE_MEDIA } from '../../../utils/manyConst'
import { getLocaleValue } from '../../../utils/localeUil'
import updateDialogView from './updateDialogView'
import { playUserVoice } from '../../../utils/ai/textToSpeech'
import { isAIDatingApp } from '../../../utils/appConfigUtil'
import useDialogsStore from '../../../zustand/useDialogsStore'

const { soundUrl } = config

function isMyMessage(msgData) {
    if (!isMe(msgData.uid)) {
        return false
    }

    return msgData.message_type !== MESSAGE_TYPE_AUTO && msgData.message_type !== MESSAGE_TYPE_AWARD
}

export default async ({ props, store, get }) => {
    if (!props.params) return
    const msgData = props.params
    if (msgData.message_type !== MESSAGE_TYPE_AUTO &&
        msgData.message_type !== MESSAGE_TYPE_AWARD &&
        isAIDatingApp() &&
        !isMe(msgData.uid)) {
        return
    }

    msgData.text = hideBadUrlsInDialog(msgData.message)
    if (msgData.translations) {
        for (const key in msgData.translations) {
            msgData.translations[key] = hideBadUrlsInDialog(msgData.translations[key])
        }
    }

    const dialog_id = props.dialog_id || msgData.dialog_id

    loadUsers([msgData.uid], async () => {
        if (!isMyMessage(msgData)) {
            useDialogsStore.getState().addDialogMessage(msgData.uid, msgData)
        }

        let notInDialog = true
        if (useDialogsStore.getState().companionUid === msgData.uid) {
            notInDialog = false
            updateDialogView(dialog_id)
        }

        if (notInDialog) {
            if (!isMyMessage(msgData)) {
                if (get(state`auth.pushSettings.privateMsg`)) {
                    try {
                        const s = new Audio(soundUrl + 'private_message_source.mp3')
                        await s.play()
                    } catch (e) {
                        console.log('sound not allowed')
                    }
                }
            }
            if (!isMyMessage(msgData)) {
                onNewMessage({
                    store, get,
                    props: {
                        roomId: get(state`app.roomId`),
                        msgData: {
                            from: msgData.uid,
                            postId: msgData.id,
                            private: true,
                            message_type: msgData.message_type,
                            sticker: msgData.message_type === 'sticker' ? msgData.message : undefined,
                            text: msgData.message_type === MESSAGE_TYPE_MEDIA ? getLocaleValue('dialog_media_file') : msgData.text,
                            to: get(state`auth.uid`)
                        }
                    }
                })
            }
        }

        playUserVoice(msgData.text, msgData.uid, () => {
            const {
                typingCyber,
                setTypingCyber
            } = useDialogsStore.getState()
            if (typingCyber === msgData.uid) {
                setTypingCyber(null)
            }
        })
    })
}
