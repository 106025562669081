import { state } from 'cerebral'
import config from '@/config'
import { getSessionId } from '@/utils'
import { set } from 'cerebral/factories'

const { ftCoreUrl } = config

export default [
  () => {
    fetch(`${ftCoreUrl}/api/v1/payments/subscriptions/cancel`, {
      method: 'POST',
      body: new URLSearchParams({
        sid: getSessionId(),
      })
    }).catch(e => console.warn('catch error', e))
  },
  set(state`auth.vip_subscription.status`, 'CANCEL'),
]
