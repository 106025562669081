import { loadUser } from '@/utils/userLoader'
import { state } from 'cerebral'
import isVip from '../../../utils/isVip'
import { DAILY_COINS, DAILY_COINS_NOVIP, NO_DAILY_COINS } from '../../buffs'
import { isMe } from '../../../utils'
import { imVIP } from '../../../utils/my'

export default ({ props, store, get }) => {
  const userData = get(state`app.users.${props.uid}`)
  if (!userData) {
    return
  }

  loadUser(props.uid, () => {
    if (props.uid !== get(state`auth.uid`)) {
      return
    }
    const userData = get(state`app.users.${props.uid}`)
    if (!userData) {
      return
    }

    if (!userData.multi_buffs[NO_DAILY_COINS]) {
      if (isMe(props.uid) && imVIP()) {
        userData.multi_buffs[isVip() ? DAILY_COINS : DAILY_COINS_NOVIP] = [{
          start: Date.now() / 1000,
          buff_id: isVip() ? DAILY_COINS : DAILY_COINS_NOVIP
        }]
      }
    }

    for (const key in userData) {
      if (key === 'fans' || key === 'subs' || key === 'friends' || key === 'coins' || key === 'vip_subscription') {
        continue
      }
      store.set(`auth.${key}`, userData[key])
    }
  }, true)
}
