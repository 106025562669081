import { state } from 'cerebral'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { POSTRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'
import { showSnackbarNew } from '../factories'
import { getZLocaleValueF } from '../../../zustand/useAppStore'
import { ERROR_SNACKBAR } from '../../../utils/snackbarUtil'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { getMyUid } from '../../../utils/my'

export default [
    () => ({ hideAll: true }),
    panelsOnPop,
    async ({ get, props, store }) => {
        POSTRequest(getCoreUrl('/api/v1/balance/payout'), {
            wallet_type: props.walletType,
            wallet: props.wallet,
            additional_params: JSON.stringify(props.additionalParams || {})
        }, (data) => {
            // updateCoins(data.result)
            const res = data.result
            setStoreWrapper('auth.receivedCoins', res.receivedCoins)
            setStoreWrapper('auth.purchasedCoins', res.purchasedCoins)
            setStoreWrapper('auth.coins', res.purchasedCoins)
            setStoreWrapper('auth.usdBalance', 0)

            showSnackbarNew(getZLocaleValueF('get_money_wait'))
            const myUid = getMyUid()

            const pymentDataUser = getState('payment.pymentDataUser')

            // Последний тип вывода, которым воспользовался пользователь
            pymentDataUser.pymentTypeUser[myUid] = props.walletType

            // Данные, которые пользователь ввел при выводе средств, чтобы заполнить ими поля формы в следующий раз
            const dataPayment = props.additionalParams || {}
            dataPayment['wallet'] = props.wallet
            if (!pymentDataUser[myUid]) {
                pymentDataUser[myUid] = {}
            }
            pymentDataUser[myUid][props.walletType] = dataPayment

            setStoreWrapper('payment.pymentDataUser', pymentDataUser)

            sendAmplitudeEvent('payout', {
                sum_usd: res.usd,
                coins: res.coins,
                payout_id: res.payoutId,
                wallet: props.wallet,
                type: props.walletType
            })
        }, (data) => {
            console.log(data)
            showSnackbarNew(getZLocaleValueF('get_money_error'), 5000, ERROR_SNACKBAR)
        })
    },
    ({ props, store, get }) => store.set('app.payoutData', {
        coins: get(state`auth.coins`),
        wallet: props.wallet,
        type: props.walletType,
    })
]
