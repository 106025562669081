import config from '@/config'

const { ftCoreUrl } = config

export const getTopUsers = async ({ store }) => {
  try {
    const response = await fetch(`${ftCoreUrl}/api/v2/top-authors`)
    const result = await response.json()
    store.set('intl.usersTop', result['top-authors'])
  } catch (e) {
  }
}
