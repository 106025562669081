import { state } from 'cerebral'
import { startWatch } from '../../../utils/chat/authorAskinChatUtil'
import { getRoomOwnerUid } from '../../../utils/navigation/navigationUtil'

export default [
  () => {
    startWatch(getRoomOwnerUid())
  },
  ({ store, get, props }) => store.set('room.streamData', {
    ...get(state`room.streamData`),
    ...props
  })
]
