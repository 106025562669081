import is from 'is_js'

export default props => {
  if (props.overflow === 'overlay' && !is.chrome()) {
    props.overflow = 'auto'
  }
  if (props.overflowY === 'overlay' && !is.chrome()) {
    props.overflowY = 'auto'
  }
  if (props.overflowX === 'overlay' && !is.chrome()) {
    props.overflowX = 'auto'
  }

  return {
    ...props,
    children: undefined,
    src: undefined,
    style: undefined,
    key: undefined,
    onScroll: undefined,
    onLoad: undefined,
    onClick: undefined,
    id: undefined,
    className: undefined
  }
}
