import { getState } from './StoreManager'
import { getParentDomain } from '@/utils/urlUtils'
import { inIframe } from '@/utils/inIframe'

export function getIOSApp() {
  const appSourceConfig = getAppSourceConfig()
  if (appSourceConfig) {
    const iosApp = appSourceConfig.iosApp
    if (iosApp && iosApp.published) {
      return appSourceConfig.iosApp.shortUrl
    }
  }
}

export function getANDApp() {
  const appSourceConfig = getAppSourceConfig()
  if (appSourceConfig) {
    const andApp = appSourceConfig.andApp
    if (andApp && andApp.published) {
      return appSourceConfig.andApp.shortUrl
    }
  }

  return null
}

export function getAppSourceConfig() {
  return getState('intl.appSourceConfig')
}

export function getHost() {
  const parentDomain = getParentDomain()
  if (inIframe()) {
    return location.host
  } else if (parentDomain.startsWith('localhost') || parentDomain.includes('.js.faminta.com')) {
    return 'flirtymania.com'
  } else {
    return parentDomain
  }
}
