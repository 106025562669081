import { showSnackbar } from '../factories'
import { state } from 'cerebral'

const addFromParam = (params, uid) => {
  const parsedParams = new URLSearchParams(params)
  parsedParams.set('from', uid)
  return parsedParams.toString()
}

const processingOfParams = (resultUrl, rawUrl, uid) => {
  resultUrl = rawUrl.origin + rawUrl.pathname
  if (rawUrl.search) {
    const newParams = addFromParam(rawUrl.search, uid)
    resultUrl += `?${newParams}`
  } else {
    resultUrl += `?from=${uid}`
  }
  return resultUrl
}

export default [
  ({ store, get, props }) => {
    const uid = get(state`auth.uid`)
    const url = document.location
    const parentUrl = get(state`app.ageRating`) > 15 ?
      new URL(document.referrer || 'https://' + location.host + '/chat-en.html') :
      new URL(document.referrer || 'https://' + location.host + '/chat.html')
    let shareUrl = ''
    if (window.location !== window.parent.location) {
      shareUrl = processingOfParams(shareUrl, parentUrl, uid)
      if (props.futureLink) {
        shareUrl += `#${props.futureLink}`
      } else {
        shareUrl += url.pathname.indexOf('/new-chat/') !== -1
          ? url.pathname.replace('/new-chat/', '#')
          : `#${url.pathname.substring(1)}`
      }
    } else {
      if (props.earnLink) {
        const newParams = addFromParam(props.earnLink.search, uid)
        shareUrl = `${props.earnLink}?${newParams}`
      } else {
        shareUrl = processingOfParams(shareUrl, url, uid)
      }
    }
    navigator.clipboard.writeText(shareUrl).then(() => {
      showSnackbar(state`intl.content.sr_copied`, 5000)
    }, (err) => {
      showSnackbar(state`intl.support_problem_errorApp`, 5000)
    })
  },
  showSnackbar(state`intl.content.link_copied`)
]
