import { state } from 'cerebral'
import { loadUser } from '@/utils/userLoader'
import is from 'is_js'
import { getParentDomain } from '@/utils/urlUtils'
import { isTWA } from '@/utils/twa'
import { getLocaleValue } from '../../../utils/localeUil'

const msInDay = 86400000

export const SPAMMER_UID = '636cb66bbc2d58e4f3fc401736eb4a45'

export const loadBroadcastNotifications = ({ store, get, props }) => {
  return
  // const notifications = get(state`intl.spam`)
  // if (!get(state`auth.isAuthComplete`) || !notifications) {
  //   setTimeout(() => loadBroadcastNotifications({ store, get, props }), 5000)
  //   return
  // }
  //
  // const chatDialogs = get(state`chat.broadcastDialogs`)
  // const readedNotifications = get(state`chat.readedNotificationsTextKeys`)
  // const appId = get(state`app.appId`)
  //
  // const unreadedCount = 0
  //
  // loadUser(SPAMMER_UID, () => {
  // })
  // notifications.forEach(notification => {
  //   const currentDate = Date.parse(new Date())
  //   if (notification.startDate) {
  //     const startDate = Date.parse(new Date(notification.startDate))
  //     if (currentDate < startDate) return
  //   }
  //   if (notification.endDate) {
  //     const endDate = Date.parse(new Date(notification.endDate))
  //     if (currentDate > endDate) return
  //   }
  //   if (notification.userStatus) {
  //     const currentUserStatus = get(state`auth.model`) ? 'model' : 'viewer'
  //     const userStatus = notification.userStatus
  //     if (currentUserStatus !== userStatus) return
  //   }
  //   if (notification.domain) {
  //     const parentHost = getParentDomain()
  //     const msgDomains = notification.domain.split(',')
  //     if (msgDomains.indexOf(parentHost) === -1) {
  //       return
  //     }
  //   }
  //
  //   if (notification.lastPurchase) {
  //     const lastPurchase = get(state`auth.stuff.lastPurchase`)
  //
  //     if (notification.lastPurchase > 0) {
  //       if (!lastPurchase) return
  //
  //       const date = new Date(lastPurchase)
  //       const now = new Date()
  //       const dif = now - date
  //       if (dif > notification.lastPurchase * 24 * 60 * 60 * 1000) {
  //         return
  //       }
  //     } else if (notification.lastPurchase < 0 && lastPurchase) {
  //       const date = new Date(lastPurchase)
  //       const now = new Date()
  //       const dif = now - date
  //       if (dif < -notification.lastPurchase * 24 * 60 * 60 * 1000) {
  //         return
  //       }
  //     }
  //   }
  //
  //   if (notification.platforms) {
  //     if (isTWA()) {
  //       if (notification.platforms.indexOf('and') === -1) return
  //     } else {
  //       if (notification.platforms.indexOf('js') === -1) {
  //         return
  //       }
  //     }
  //   }
  //
  //   if (notification.appidDisabled) {
  //     if (notification.appidDisabled.indexOf(appId) !== -1) {
  //       return
  //     }
  //   }
  //
  //   if (notification.jsOs) {
  //     if (is.ios() && notification.jsOs.indexOf('ios') === -1) {
  //       return
  //     }
  //     if (is.android() && notification.jsOs.indexOf('and') === -1) {
  //       return
  //     }
  //     if (is.desktop()) {
  //       return
  //     }
  //   }
  //
  //   if (notification.platformStatus) {
  //     const currentPlatformStatus = get(state`app.ageRating`) > 17 ? 'f+' : 'f-'
  //     const platformStatus = notification.platformStatus
  //     if (platformStatus !== currentPlatformStatus) return
  //   }
  //   if (notification.registrationLag) {
  //     const registrationLag = notification.registrationLag * msInDay
  //     const regDate = Date.parse(new Date(get(state`auth.dateReg`)))
  //     if (currentDate - regDate < registrationLag) {
  //       return
  //     }
  //   }
  //
  //   if (notification.appid) {
  //     if (notification.appid.indexOf(appId) === -1) return
  //   }
  //   const from = SPAMMER_UID
  //   const messageData = {
  //     from,
  //     textKey: notification.textKey,
  //     text: getLocaleValue(notification.textKey),
  //     sticker: null,
  //     isBroadcastNotification: true
  //   }
  //
  //   if (chatDialogs[from]) {
  //     chatDialogs[from].push(messageData)
  //   } else {
  //     chatDialogs[from] = [messageData]
  //   }
  // })
  // store.set('chat.broadcastDialogs', chatDialogs)
}
