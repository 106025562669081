import { create } from 'zustand'
import { getSettings } from '../utils/settingsUtil'
import { GETRequest } from '../utils/api/requestWrapper'
import { getCoreUrl } from '../utils/api/apiURLUtil'
import { getLang } from './useAppStore'
import { imAdmin } from '../utils/adminUtil'
import { getSessionId } from '../utils'
import { LOADING } from '../utils/api/dating/datingApi'
import { setStoreWrapper } from '../utils/StoreManager'

export const ADMINS_TAGS = 'admins'
export const SEO_TAGS = 'seo'

const PROFILE = 'profile'
const POST = 'post'
const LAZY_LOAD_AGRIGATE_DELAY = 100

export const PROFILE_CATEGORIES = ['people', 'profile_education', 'profile_height', 'profile_housing', 'profile_kids', 'profile_languages', 'profile_religion', 'profile_smoking', 'videochat_questionnaire_interests', 'videochat_questionnaire_job', 'videochat_questionnaire_purpose']
export const ADMIN_CATEGORIES = [ADMINS_TAGS, SEO_TAGS]
export const POST_CATEGORIES = ['photo_tags']

const useTagsStore = create(set => ({
    tags: {},
    lazyTagsToLoad: [],
    loadTags: tagIds => {
        if (!tagIds || tagIds.length === 0) {
            return
        }
        const {
            tags
        } = useTagsStore.getState()

        const tagsToLoad = []
        tagIds.forEach(tagId => {
            if (!tags[tagId]) {
                tags[tagId] = LOADING
                tagsToLoad.push(tagId)
            }
        })

        if (tagsToLoad.length === 0) {
            return
        }

        setStoreWrapper('tagsToLoad', false)
        set({ tags })

        GETRequest(getCoreUrl('/api/v1/posts?withNotInteresting=true&limit=999&sid=' + getSessionId() + '&lang=' + getLang() + '&postsIds=' + tagsToLoad), (data) => {
            if (data && data.posts && data.posts.length) {
                data.posts.forEach(post => {
                    tags[post.postId] = post
                })
            }
            set({ tags })
            setStoreWrapper('tagsToLoad', true)
        })
    },
    getTagTitle: id => {
        const {
            tags
        } = useTagsStore.getState()
        if (!tags[id] || tags[id] === LOADING) {
            return null
        }
        return tags[id].title
    },
    loadProfileTags: () => {
        useTagsStore.getState().loadTagsByType(PROFILE)
    },
    loadPostTags: () => {
        useTagsStore.getState().loadTagsByType(POST)
    },
    loadTagsByType: type => {
        let tagIds = []
        const allTags = getSettings('tagsByCategories', {})
        let categories = []
        if (type === PROFILE) {
            categories = categories.concat(PROFILE_CATEGORIES)
        } else if (type === POST) {
            categories = categories.concat(POST_CATEGORIES)
        }

        if (imAdmin()) {
            categories = categories.concat(ADMIN_CATEGORIES)
        }

        categories.forEach(category => {
            const tags = allTags[category]
            if (tags) {
                tagIds = tagIds.concat(tags)
            }
        })
        useTagsStore.getState().loadTags(tagIds)
    },
    getTagsIdsByCategoryId: categoryId => {
        return getSettings('tagsByCategories', {})[categoryId] || []
    },
    getSortedTagsIdsByCategoryId: categoryId => {
        const {
            getTagTitle
        } = useTagsStore.getState()
        const ids = useTagsStore.getState().getTagsIdsByCategoryId(categoryId)
        return ids.sort((a, b) => {
            const wordA = (getTagTitle(a) || '').toLowerCase()
            const wordB = (getTagTitle(b) || '').toLowerCase()
            return wordA.localeCompare(wordB)
        })
    },
    isOnlyOneTagCategory: categoryId => {
        return categoryId === 'people' ||
            categoryId === 'profile_education' ||
            categoryId === 'profile_height' ||
            categoryId === 'profile_housing' ||
            categoryId === 'profile_kids' ||
            categoryId === 'profile_smoking'
    },
    lazyTagsLoad: tagIds => {
        const {
            lazyTagsToLoad,
            tags,
            loadTags
        } = useTagsStore.getState()

        const tagsToLoad = []
        tagIds.forEach(tagId => {
            if (tagId && !tags[tagId] && !lazyTagsToLoad.find(tag => tag === tagId)) {
                tagsToLoad.push(tagId)
            }
        })

        if (!tagsToLoad.length) {
            return
        }

        //если еще нет таймера на ленивую загрузку - добавим его
        if (!lazyTagsToLoad.length) {
            setTimeout(() => {
                //нужно получить текущие теги для загрузки, так как в локальной переменной они могут быть не все
                loadTags(useTagsStore.getState().lazyTagsToLoad)
                set({ lazyTagsToLoad: [] })
            }, LAZY_LOAD_AGRIGATE_DELAY)
        }
        //если таймер есть, то допишем теги для загрузки
        set({ lazyTagsToLoad: lazyTagsToLoad.concat(tagsToLoad) })
    }
}))

export default useTagsStore
