export function preloadVideo(url, cb, eb) {
  // Создаем новый элемент
  const preloadVideo = document.createElement('video')

  // Устанавливаем источник видео для предзагрузки
  preloadVideo.src = url

  // Слушаем событие loadeddata, которое срабатывает при предзагрузке видео
  preloadVideo.addEventListener('loadeddata', function () {
    if (cb) {
      cb()
    }
  })

  // Слушаем событие error, чтобы обработать ошибки загрузки (необязательно)
  preloadVideo.addEventListener('error', function () {
    if (eb) {
      eb()
    }
  })
}
