import { getDatingParams } from '../../modules/posts/getPostsRequestParams'
import { savePostsData } from '../../modules/posts/signals/onGetPosts'
import config from '@/config'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { getMyUid, imVIP } from '../my'

const { ftCoreUrl } = config

export async function loadTopBarProfiles() {
  if (getState('topUsersLoading') === 'loaded_all' || getState('topUsersLoading') === 'loading') {
    return
  }

  setStoreWrapper('topUsersLoading', 'loading')

  let queryString = `${ftCoreUrl}/api/v1/posts/`
  let topPosts

  //сортировка рядом
  queryString += 'last-visit?limit=30'

  topPosts = getState('topUids') || []
  const offset = getState('topUidsLength')
  if (offset) {
    queryString += `&page=${Math.floor(offset / 30)}`
  }
  queryString += '&' + getDatingParams(true)
  queryString += '&fromVip=true'

  try {
    const response = await fetch(queryString)
    if (!response.ok) {
      return
    }
    const result = await response.json()

    let posts = result.posts

    callSequence('posts.saveTopUsers', { posts: posts })
    setStoreWrapper('topUidsLength', (getState('topUidsLength') || 0) + posts.length)
    topPosts = topPosts.concat(posts.map(post => post.uid))
    if (imVIP() && topPosts.indexOf(getMyUid()) !== 0) {
      topPosts.unshift(getMyUid())
    }
    setStoreWrapper('topUids', topPosts)
    setStoreWrapper('topUsersLoading', posts.length === 0 ? 'loaded_all' : 'loaded')
  } catch (e) {
    console.warn('load top users posts error:', e)
  }
}

export const saveTopUsers = [
  savePostsData
]

export function addMeInTopUids() {
  if (imVIP()) {
    const topPosts = getState('topUids')
    if (topPosts && topPosts.indexOf(getMyUid()) !== 0) {
      topPosts.unshift(getMyUid())
      setStoreWrapper('topUids', topPosts)
    }
  }
}

