import sendChatMessage from '../actions/sendChatMessage'
import onNewMessage from '../actions/onNewMessage'
import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { CHAT_PERMISSION, needSubscription } from '@/utils/subscriptionUtil'
import { subscriptionPanel } from '../../../components/Panels/types'
import { createPanel } from '../../app/actions'
import { LEVEL_VIP } from '../../../utils/subscriptionUtil'
import { SUPPORT_UID } from '../../app/signals/openSupport'
import { imAdmin } from '../../../utils/adminUtil'
import { getRoomOwnerUid } from '../../../utils/navigation/navigationUtil'
import { createGetVipPanel } from '../../../components/Panels/createPanelUtil'

export default [
    ({ path, get }) => {
        const toId = getRoomOwnerUid(get(state`chat.chatRoomId`))
        const permission = needSubscription(toId, CHAT_PERMISSION)
        if (permission && get(state`auth.uid`) !== SUPPORT_UID && !imAdmin()) {
            if (permission === LEVEL_VIP) {
                createGetVipPanel('vip_only_dialog')
                return path.doNothing()
            }
            return path.needPermission(
                subscriptionPanel(toId, permission)
            )
        }
        return path.sendMsg()
    }, {
        needPermission: [
            createPanel,
        ],
        doNothing: [],
        sendMsg: [
            sendChatMessage,
            // send,
            //показываем cообщение cразу, не дожидаяcь cервера
            context => {
                if (!context.props.msgData) {
                    return
                }
                onNewMessage(context)
            }
        ],
    }
]
