import React, { Component } from 'react'
import { array } from 'prop-types'
import { state } from 'cerebral'
import AnimatedShape from './AnimatedShape'
import { connect } from '@cerebral/react'
import { isUID } from '../../utils/isUID'
import { FLY_GIFT_DURATION } from './animatedLike'
import { randomKey } from '../../utils/randomKey'

class AnimatedField extends Component {

  state = {
    likes: [],
    renderDate: null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const {
      animatedLikes
    } = this.props

    const renderDate = this.state.renderDate
    const likes = this.state.likes
    let needUpdate
    animatedLikes.forEach(likeObj => {
      if (isUID(likeObj.uid) && (!renderDate || renderDate < likeObj.date)) {
        needUpdate = true
        likes.push(<AnimatedShape key={randomKey()} size={28} uid={likeObj.uid} giftId={likeObj.giftId}
                                  duration={likeObj.giftId ? FLY_GIFT_DURATION - 100 : 2900}/>)
      }
    })

    const newLikes = []
    likes.forEach(rendered => {
      if (rendered) {
        newLikes.push(rendered)
      } else {
        needUpdate = true
      }
    })

    if (needUpdate) {
      this.setState({ likes: newLikes, renderDate: Date.now() })
    }
  }

  render() {
    return (
      <div style={{ position: 'absolute', pointerEvents: 'none' }}>
        {this.state.likes}
      </div>
    )
  }
}

AnimatedField.propTypes = {
  animatedLikes: array
}

export default connect({
  animatedLikes: state`app.animatedLikes`,
}, AnimatedField)
