import { getUser } from '../userLoader'
import { getUserStableRandom } from '../user/getUserStableRandom'
import { FREE_FILTER, getAwardsByCategory, LOCATION_AWARD } from './awardsConfig'

export function getUserAwardLocation(uid) {
  const user = getUser(uid)

  //если нет юзера, то не показываем картинку, что бы лишнее не моргало
  if (!user) {
    return null
  }

  if (user.awards && user.awards[1]) {
    return user.awards[1].award
  }

  const locations = getAwardsByCategory(LOCATION_AWARD, FREE_FILTER)
  return locations[getUserStableRandom(uid, locations.length)]
}
