import { POSTRequest } from '../api/requestWrapper'
import { getConfig } from '../urlUtils'
import { isMe, MY_DEFAULT_UID } from '../checks'
import { isCyber } from '../cyber/cyberUtil'
import useDialogsStore from '../../zustand/useDialogsStore'

let autoDialogTimeout

function initAutoDialog(uid) {
    POSTRequest(getConfig('dialogsUrl') + '/api/v1/dialogs/autostart-ai', { uid_to: uid },
        data => {
        })
}

export function checkAutoStartDialog(uid) {
    if (!isMe(uid) && isCyber(uid)) {
        const dialog = useDialogsStore.getState().getDialog(MY_DEFAULT_UID, uid)
        if (!dialog || !dialog.messages || !dialog.messages.length < 2) {
            autoDialogTimeout = setTimeout(() => {
                initAutoDialog(uid)
            }, 2000)
        }
    }
}

export function cancelAutoDialog() {
    if (autoDialogTimeout) {
        clearTimeout(autoDialogTimeout)
    }
}
