import controller from '@/controller'
import { isTWA } from '@/utils/twa'
import { getPrivateThemes } from './directCallThemeUtil'
import { getConfigVersion } from './bodyAttributeUtil'
import { loadSetting } from '../modules/intl/actions'

export const noDelicateRatingsArray = {
  12: [12],
  14: [12, 14],
  16: [14, 16],
  18: [12, 14, 16]
}

const ratingsArray = {
  12: [12],
  14: [12, 14, 16],
  16: [14, 16 ],
  18: [12, 14]
}

export const getRatingArray = rating => {
  const postRatings = controller.getState('intl.appSourceConfig.postRatings') || []
  const postRatingsNSFW = controller.getState('intl.appSourceConfig.postRatingsNSFW') || []
  if (postRatings && postRatingsNSFW) {
    return [...postRatings, ...postRatingsNSFW]
  }

  const ageRatingPost = controller.getState('intl.appSourceConfig.ageRatingPost')
  if (ageRatingPost && noDelicateRatingsArray[ageRatingPost]) {
    return noDelicateRatingsArray[ageRatingPost]
  }
  if (controller.getState('intl.appSourceConfig.hideDelicate') || isTWA() || location.host === 'dev.js.faminta.com') {
    return noDelicateRatingsArray[rating]
  }
  return ratingsArray[rating] || ratingsArray[12]
}

export const getThemeData = themeId => {
  const broadcastThemes = getPrivateThemes()
  return broadcastThemes[themeId] || {}
}

