import { state } from 'cerebral'
import { when } from 'cerebral/factories'
import { getSessionId } from '@/utils'
import removeNulls from '@/utils/removeNulls'
import { checkExtraFiles } from './createPost'
import controller from '@/controller'
import config from '@/config'
import { showStartPanel } from '../../../components/Panels/types'
import createPanel from '../../app/signals/createPanel'
import { saveMetadata } from '../../room/signals/sendCreateBroadcast'
import sendCreateAvatar from '../actions/sendCreateAvatar'
import { UpdateMyUser } from '../../../utils/userLoader'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { postModerationErrorEvent, postModerationEvent } from '../../../utils/amplitude/postEvents'

const { ftCoreUrl, postUploadUrl } = config

export default [
  removeNulls,

  async ({ get, props, store }) => {
    //console.log('---@@@ Edit Post Panel @@@---')
    const postId = get(state`posts.selectedPostId`)
    const post = get(state`posts.postsData.${postId}`)

    const editData = { sid: getSessionId(), ...props }

    if (get(state`auth.admin`) || get(state`auth.superAdmin`)) {
      editData.status = get(state`upload.moderPostStatus`)
      editData.categories = []
    }

    post['moderCheck'] = true
    //post['status'] = editData.status
    store.set(`posts.postsData.${postId}`, post)
    store.set('upload.description', null)
    store.set('upload.title', null)
    store.set('upload.subscriptionType', null)
    store.set('upload.selectedTags', [])
    store.set('upload.selectedFile', null)
    store.set('upload.selectedFilename', null)
    store.set('upload.selectedFileUrl', null)
    store.set('upload.selectedPhotoOrientation', null)
    store.set('upload.mainFileId', null)
  },

  panelsOnPop,

  async ({ get, props, store }) => {
    const myUid = get(state`auth.uid`)
    const postId = get(state`posts.selectedPostId`)
    const postData = get(state`posts.postsData.${postId}`)
    const postOwnerUid = postData.uid
    const postExtraFiles = get(state`posts.postsData.${postId}.mediaFilesIds.extra_files_ids`) || []
    let extraFiles = get(state`upload.extraFiles`)

    if (postExtraFiles.length > 0) {
      const filesResp = await fetch(`${postUploadUrl}/api/v1/media-file/${postExtraFiles}/?sid=${getSessionId()}`)
      const filesData = await filesResp.json()
      const ourFormatExtraFiles = []
      for (const file_id in filesData.result) {
        const fileInfo = filesData.result[file_id]
        const ourFormatFileInfo = { file_id }
        if (fileInfo && fileInfo.images) {
          fileInfo.images.forEach(
            imageInfo => ourFormatFileInfo[imageInfo.image_type] = imageInfo.url
          )
        }
        ourFormatExtraFiles.push(ourFormatFileInfo)
      }
      extraFiles = extraFiles.concat(ourFormatExtraFiles)
    }
    store.set('upload.extraFiles', [])

    const editData = { sid: getSessionId(), ...props }

    if (extraFiles.length > 0) {
      editData.extra_files_ids = checkExtraFiles(extraFiles, get(state`upload.description`) || '')
    }

    if (get(state`auth.admin`) || get(state`auth.superAdmin`)) {
      editData.status = get(state`upload.moderPostStatus`)
      editData.categories = []
    }

    let mainFileId = get(state`upload.mainFileId`)
    if (!mainFileId) {
      mainFileId = get(state`posts.postsData.${postId}.mediaFilesIds.main_file_id`)
    }

    if (mainFileId) {
      editData.main_file_id = mainFileId
      if (editData.post_kind === 'postProfile' && postData && postData.uid === myUid) {
        const { avatarUrl } = await sendCreateAvatar({ get, store, mainFileId })
        store.set('auth.profile_post.avatar', avatarUrl)
        UpdateMyUser({ store })
      }

      if (editData.postType === 1 && !get(state`upload.photoUrl`) && !editData.noSetCoverTime) {
        await fetch(`${postUploadUrl}/api/v1/media-file/create-screenshot`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            cover_time: get(state`upload.coverTime`),
            file_id: mainFileId,
            sid: getSessionId()
          })
        })
      }

      store.set('upload.photoUrl', null)
    }

    try {
      await fetch(`${ftCoreUrl}/api/v1/posts/${postId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editData)
      })

      postModerationEvent(editData)
      //store.unset(`posts.postsData.${postId}`)

      const getPost = controller.getSequence('posts.getPost')
      getPost({ postId: postId, isSpecial: true })
    } catch (e) {
      postModerationErrorEvent()
      console.log('@@@ edit post error', e)
    }
  },

  when(state`auth.storage.metadata.postThemeId`, state`posts.selectedPostId`,
    (postThemeId, postId) => postThemeId === postId), {
    true: [
      () => ({ options: showStartPanel.options }),
      createPanel,
      saveMetadata
    ],
    false: []
  },
]
