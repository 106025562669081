import { state } from 'cerebral'
import useDialogsStore from '../../../zustand/useDialogsStore'

export default ({ props, store, get }) => {
  const uid = props.uid
  const allChats = get(state`chat.roomsMessages`)
  for (const roomId in allChats) {
    const roomMessages = allChats[roomId]

    for (let i = 0; i < roomMessages.length; i++) {
      const msg = roomMessages[i]
      if (msg.from === uid) {
        msg.text = get(state`intl.content.moderador_delete_this_msg`)
      }
    }
  }
  store.set('chat.roomsMessages', allChats)

 useDialogsStore.getState().deleteDialog('', uid)
}
