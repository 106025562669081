import { getFingerprint } from './getFingerprint'
import { set } from 'cerebral/factories'
import { sequence, state } from 'cerebral'
import getAuthInfo from '../../auth/signals/getAuthInfo'
import { getSessionId } from '@/utils'

export const authorize = sequence('authorize', [
  getFingerprint,
  ({ path }) => {
    if (getSessionId()) {
      return path.authWithSession()
    }
    return path.makeNewSession()
  }, {
    authWithSession: [
      getAuthInfo
    ],
    makeNewSession: [
      set(state`genderSelectScreen`, true)
    ]
  }
])
