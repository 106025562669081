import { state } from 'cerebral'

export const setCompanyNotificationReaded = ({ store, get, props }) => {
  const textKey = props.textKey
  const readedNotificationsTextKeys = get(state`chat.readedNotificationsTextKeys`)
  if (!readedNotificationsTextKeys[textKey]) {
    readedNotificationsTextKeys[textKey] = true
    store.set('chat.readedNotificationsTextKeys', readedNotificationsTextKeys)
  }
}
