import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import config from '@/config'

const { pushSettingsUrl } = config

export const getPushSettings = async ({ store, get }) => {
  try {
    const response = await fetch(pushSettingsUrl + `push/settings?sid=${getSessionId()}`)

    if (response.ok) {
      const data = await response.json()
      store.set('auth.pushSettings', { ...get(state`auth.pushSettings`), ...data })
    }
  } catch (e) {
    console.warn('@@@ get push settings error', e)
  }
}
