import signals from './signals'

export default {
  state: {
    isOpen: false,
    isConnected: false,
    isFirstReconnect: true,
    mustSend: []
  },
  sequences: signals
}
