import { state } from 'cerebral'
import useTagsStore from '../../../zustand/useTagsStore'

const GENDER_CATEGORY = 'people'
export default [
  ({ props, store, get }) => {
    const { tagId, clearCategory, categoryId } = props
    let selectedTags = get(state`upload.selectedTags`)
    if (tagId) {
      if (selectedTags.includes(tagId)) {
        selectedTags = selectedTags.filter(item => item !== tagId)
      } else {
        selectedTags.push(tagId)
      }
    }
    if (clearCategory || categoryId === GENDER_CATEGORY || useTagsStore.getState().isOnlyOneTagCategory(categoryId)) {
      const catTags = useTagsStore.getState().getTagsIdsByCategoryId(categoryId)
      selectedTags = selectedTags.filter(item => item === tagId || !catTags.find(tag => tag === item))
    }
    store.set('upload.selectedTags', selectedTags)
  }
]
