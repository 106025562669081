import config from '@/config'
import { getSessionId } from '@/utils'
import { addBuff, delBuff } from '../actions'
import { GOOD_FOR_12, GOOD_FOR_14 } from '../index'
import { sendDellBuff } from '../../../utils/buffUtils'
import { callSequence } from '../../../utils/StoreManager'

const { ftCoreUrl } = config

export default [
    ({ props, path }) => {
        const buff = {
            toUid: props.uid,
            cardType: props.buffId,
            startTime: Date.now()
        }
        if (props.method === 'DELETE') {
            return path.delete({ buff })
        } else {
            return path.add({ buff })
        }
    }, {
        delete: delBuff,
        add: addBuff
    },
    ({ props }) => {
        //если это неодобрение на нормальный рейтинг, то шлем сразу вызов бафа
        if (!(props.buffId === GOOD_FOR_12 ||
            props.buffId === GOOD_FOR_14)) {
            if (props.method === 'DELETE') {
                sendDellBuff(props.uid, props.buffId)
            } else {
                callSequence('buffs.sendBuff', { buffId: props.buffId, method: props.method, uid: props.uid })
            }
        }

        if (props.buffId === GOOD_FOR_12 ||
            props.buffId === GOOD_FOR_14) {
            // callSequence('buffs.sendBuff', { buffId: props.buffId, method: 'POST', uid:props.uid  })

            fetch(`${ftCoreUrl}/api/v1/broadcasts/preview`, {
                method: 'POST',
                body: new URLSearchParams({
                    sid: getSessionId(),
                    uid: props.uid,
                    buffId: props.buffId
                })
            }).catch(e => console.warn('catch error', e))
        }
    }
]
