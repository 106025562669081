export const flex = (flexDirection, alignItems = 'center', justifyContent = 'center') => ({
  display: 'flex',
  flexDirection,
  alignItems,
  justifyContent
})

export const circle = size => ({
  width: size,
  height: size,
  borderRadius: size / 2
})

export const square = size => ({
  width: size,
  height: size
})

export const ripple = (hoverBgColor, activebgColor) => ({
  backgroundPosition: 'center',
  transition: 'background 0.8s',
  '&:hover': {
    // background: '#47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%'
    background: `${hoverBgColor} radial-gradient(circle, transparent 1%, ${hoverBgColor} 1%) center/15000%`
  },
  '&:active': {
    // backgroundColor: '#6eb9f7',
    backgroundColor: activebgColor,
    backgroundSize: '100%',
    transition: 'background 0s'
  }
})
