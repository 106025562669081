import { getTopPanelType } from '../modules/app/actions'
import { ANOTHER_LOGIN, CONNECTION_LOST } from '../components/Panels/types'
import { callSequence, getState } from './StoreManager'
import is from 'is_js'

export let NEED_RELOAD

export function checkReload(hidden) {
  //если приложение возвращается в фокус проверим коннект, если его нет то перезагрузим страницу
  if (!hidden) {
    const lastPanelType = getTopPanelType()
    console.log('lastPanel ' + lastPanelType + ' socet ' + getState('socket.isOpen'))
    if (lastPanelType === ANOTHER_LOGIN || lastPanelType === CONNECTION_LOST || !getState('socket.isOpen')) {
      goReload()
    } else {
      // NEED_RELOAD = true
      // setTimeout(() => {
      //   NEED_RELOAD = false
      // }, 1500)
    }
  } else {
    if (is.mobile()) {
      callSequence('room.stopStream')
    }
  }
}

export function goReload() {
  location.reload()
}

