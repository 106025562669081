import { isPartnerDomain } from './urlUtils'
import controller from '@/controller'
import { getState } from './StoreManager'
import { isHugePosterMode } from './post/posterUtil'
import config from '@/config'

export const isDesktopSkin = () => {
  return window.innerWidth > 930 && window.innerHeight > 450
}

export const isDesktopSkinDating = () => {
  return isHugePosterMode()
}

export const isMobileSkin = () => {
  return !isDesktopSkin()
}

export const isPlaceHolder = () => {
  if (!isPartnerDomain()) {
    return false
  }
  return window.innerHeight < 300 || window.innerWidth < 300
}

export const isFileExist = async streamFileUrl => {
  try {
    const response = await fetch(streamFileUrl, { method: 'HEAD' })
    if (!response.ok) throw Error(response.statusText)

    return true
  } catch (error) {
    return false
  }
}

export const getMainVideoHeight = uid => {
  const broadcast = controller.getState(`posters.raw.${uid}`)
  const streamInRoom = controller.getState('room.streamInRoom')
  const isStream = broadcast || streamInRoom

  const { w, h } = uid === controller.getState('auth.uid') ?
    controller.getState('room.metadata') || {} :
    controller.getState(`posters.raw.${uid}`) || {}

  if (!w || !h || !isStream) {
    //return { height: '60vh' }
    return { height: isDesktopSkin() ? '60vh' : '120px' }
  }
  if (document.fullscreenElement) {
    return { height: '100%' }
  }
  if (isDesktopSkin()) {
    return {
      minHeight: '50vh',
      maxHeight: '60vh',
      // height: (window.innerWidth - sidebarWidth - chatWidth - 32) * h / w
    }
  } else {
    return {
      height: window.innerWidth * h / w,
      maxHeight: '75vh',
    }
  }
}

export const MY_DEFAULT_UID = 'my'
export const isMe = (uid) => {
  return uid === MY_DEFAULT_UID || uid === getState('auth.uid')
}

export function getContentW(){
  const userAgent = getState('useragent.window')
  return isDesktopSkin() ? userAgent.width - config.sidebarWidth : userAgent.width
}
