import changeLocale from './changeLocale'
import getLocale from './getLocale'
import loadCountries from './loadCountries'
import banCountry from './banCountry'
import toogleAutoTranslation from './toogleAutoTranslation'
import handleLocaleFromUrl from './handleLocaleFromUrl'
import getTopUsers from './getTopUsers'

export default {
  handleLocaleFromUrl,
  toogleAutoTranslation,
  banCountry,
  changeLocale,
  getLocale,
  loadCountries,
  getTopUsers,
}
