import { getPostData } from '../postUtils'
import { getState } from '../StoreManager'

const MAIN_VIDEO_MIN_W = 480
const MAIN_VIDEO_MIN_H = 640

const HUGE_POSTER_H = 580
const NORMAL_POSTER_H = 430

export function getPosterPicH(postId, availableW) {
  let postData = getPostData(postId)
  let mediaW
  let mediaH
  if (!postData) {
    mediaW = MAIN_VIDEO_MIN_W
    mediaH = MAIN_VIDEO_MIN_H
  } else {
    mediaW = postData.originalWidth
    mediaH = postData.originalHeight
  }

  return Math.min(Math.max(mediaH * availableW / mediaW, 150), isHugePosterMode() ? HUGE_POSTER_H : NORMAL_POSTER_H)
}

export function isHugePosterMode() {
  return getState('isDesktopPosters')
}
