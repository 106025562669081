import showPost from './showPost'
import panelsOnPop from '../../app/signals/panelsOnPop'
import sendPostView from './sendPostView'

export default [
  () => ({ hideAll: true }),
  panelsOnPop,
  sendPostView,
  showPost,
]
