import controller from '@/controller'

const getStreamMetadata = uid => {
  if (uid === controller.getState('auth.uid')) {
    return controller.getState('auth.storage.metadata')
  }
  const userMD = controller.getState(`app.users.${uid}.storage.metadata`)
  if (userMD) return userMD

  const broadcastMD = controller.getState(`posters.raw.${uid}.metadata`)
  return broadcastMD || null
}

export default getStreamMetadata
