import React, { Component, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import propsOverrideCss from '@/utils/propsOverrideCss'
import { Spinner } from './Loading'
import { bool, func, object, string } from 'prop-types'
import { MD5 } from '../utils/MD5'

const Img = styled.img({
  height: '100%',
  width: '100%',
}, propsOverrideCss)

const ImageWithLoader = ({
                           style,
                           filter,
                           objectFit,
                           onLoad,
                           onClick,
                           alt,
                           noSpinner,
                           errorUrl,
                           imgStyle,
                           map,
                           stopPropagation,
                           customClass,
                           src
                         }) => {
  const [loading, setLoading] = useState(false) //
  const [error, setError] = useState(false)

  useEffect(() => {
      // setError(false)
      setLoading(!noSpinner)
  }, [src])
    
    return (
      <div style={{ position:'relative', ...style }} key={src} className={customClass} onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation()
        }
        if (onClick) {
          onClick()
        }
      }}>
        <Img alt={alt} src={(error && errorUrl) ? errorUrl : src} style={imgStyle} filter={filter} useMap={map} objectFit={objectFit}
             key={MD5(src)}
             opacity={loading ? 0 : 1}
             onError={()=>{
               setError(true)
             }}
             onLoad={e => {
                 //костыль нужен, на случай если в кеше браузера есть картинка,
                 //успевает выставиться setLoading в false до того как вызовется UseEffect при смене src
                 setTimeout(()=>{
                     setLoading(false)
                     // setError(false)
                 }, 10)

               // this.setState({ loadedSrc: src })
               if (onLoad) {
                 onLoad(e)
               }
             }}/>
        {loading &&
          <Spinner borderColor="rgba(221, 221, 221, 0.36)" borderTopColor="#ffffff"/>
        }
      </div>
    )
}

ImageWithLoader.propTypes = {
  alt: string,
  customClass: string,
  errorUrl: string,
  filter: string,
  imgStyle: object,
  map: object,
  noSpinner: bool,
  objectFit: string,
  onClick: func,
  onLoad: func,
  src: string,
  stopPropagation: bool,
  style: object
}

export default ImageWithLoader
