import createPost from './createPost'
import sendCreateAvatar from '../actions/sendCreateAvatar'
import { UpdateMyUser } from '../../../utils/userLoader'
import { setMyProfileId } from '../../../utils/my'
import { clearPostCreatedData } from '../../../utils/post/profileUtil'

export default [
  sendCreateAvatar,
  createPost,
  ({ store, props }) => {
    setMyProfileId(props.post_id)
    store.set('auth.profilePostTags', props.tags)
    // setFemale(props.tags.indexOf(FEMALE) !== -1)
  },
  UpdateMyUser,
  clearPostCreatedData
]
