import { state } from 'cerebral'
import { authorize, getFingerprint } from '../../auth/chains'

import destroyPanel from '../../app/signals/destroyPanel'
import { wait } from '@/utils'
import sendGoInRoom from '../../room/signals/sendGoInRoom'
import { getAuthBySid } from '../../../utils/api/auth/authAPI'
import { getSessionId } from '../../../utils'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'

export default [
  ({ get, path }) => {
    const panels = get(state`app.panels`)
    const lastPanel = panels[panels.length - 1]
    if (lastPanel && (lastPanel.type === 'connectionLost' || lastPanel.type === 'loggedFromAnotherPlace')) {
      return path.connectionLostPanelIsOpen()
    } else {
      return path.doNothing()
    }
  }, {
    connectionLostPanelIsOpen: destroyPanel,
    doNothing: []
  },
  getFingerprint,
  // authorize,
  () =>{
      callSequence('intl.loadCountries')
     if (getSessionId()) {
       getAuthBySid()
     } else {
       setStoreWrapper('genderSelectScreen', true)
     }
  },
  async ({ store, get }) => {
    for (let i = 0; i < 5; i++) {
      try {
        store.set('socket.isOpen', true)
        store.set('socket.isFirstReconnect', true)
      } catch (e) {
        console.log('@@@ socket.isOpen error:', e)
        await wait(1000)
      }
    }
  },
  sendGoInRoom
]

