import { getCurrentRoomId } from '../navigation/navigationUtil'
import { QUIZ } from '../../modules/buffs'
import { isBuffActive } from '../buffUtils'
import { getState, setStoreWrapper } from '../StoreManager'
import { clearQuiz, loadQuizInfo, loadQuizQuestions } from './quizAPI'
import { chatNotification } from '../../modules/auth/actions/notificationUtil'
import { getUserName } from '../userUtil'
import { getSettingsCost } from '../settingsUtil'
import { isMe } from '../checks'
import { quizStatusEvent } from '../amplitudeLiveEvents'
import { isCustomVanilla } from '../api/PWAUtil'

//времени на ответ
export const TIME_TO_ANSWER = 2 * 60 * 1000

export function askQuiz(quiz) {
  if (isCustomVanilla()) {
    return
  }
  if (isQuizMinimized()) {
    return
  }
  if (quiz.status === 'finish') {
    removeQuizQuestion()
    clearQuiz()
    return
  }
  const question_id = quiz.questions_list[quiz.question_id]
  const fromUid = quiz.started_by
  const counter = quiz.question_id + 1
  const endTime = quiz.question_finish_at

  loadQuizQuestions(() => {
    const currentQuiz = getState('quizInfo')
    if (currentQuiz && currentQuiz.question_id === question_id) {
      return
    }
    removeQuizQuestion()
    setStoreWrapper('quizInfo', { question_id, fromUid, counter, endTime })
    quizInChat('quiz_q_' + question_id, null, true)
    // upRoomMessage({ localeKey:'quiz_q_' + question_id, fromUid, quiz: true, counter, endTime: endTime || (Date.now() + TIME_TO_ANSWER) })
  })
}

export function isQuizInRoom(roomId) {
  return isBuffActive(roomId || getCurrentRoomId(), QUIZ)
}

export function removeQuizQuestion() {
  const popups2 = getState('room.popups')
  for (let i = 0; i < popups2.length; i++) {
    if (popups2[i].quiz) {
      popups2.splice(i, 1)
      break
    }
  }
  setStoreWrapper('room.popups', popups2)
}

export function quizMinimize(minimize) {
  setStoreWrapper('quizMinimize', minimize)
  if (minimize) {
    removeQuizQuestion()
    clearQuiz()
  } else {
    if (isQuizInRoom()) {
      loadQuizInfo()
    }
  }
}

export function isQuizMinimized() {
  return getState('quizMinimize')
}

export function quizInChat(key, metadata, force) {
  if (isCustomVanilla()) {
    return
  }
  chatNotification(key, metadata, QUIZ, force)
}

export function quizWinner(params) {
  quizInChat('quiz_winner_message', {
    replacedParams: { '%userName%': getUserName(params.winner), '%count%': getSettingsCost('quiz_winner_coins') },
    winner: params.winner
  }, true)
  // chatNotification('quiz_winner_message', { '%userName%': getUserName(params.winner), '%count%': getSettings('quiz_winner_exp') }, null, true)
  // askQuiz(props.params.quiz)
  if (isMe(params.winner)) {
    quizStatusEvent('winner')
  }
}
