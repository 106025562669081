import { getSequence } from './StoreManager'
import { postersRoute } from './routerHelpers'

export function goToRoom(uid) {
  if (uid && uid.length === 32) {
    getSequence('app.roomTransited')({ roomId: uid })
  }
}

export function openPosters() {
  getSequence('app.prepareAndRouteTo')({ url: postersRoute })
}
