import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import { send } from '../../socket/actions'

export default [
  ({ get, props }) => ({
    params: {
      model: props.model,
      viewer: props.viewer
    },
    method: 'callToPrivateCancel'
  }),
  send,
  set(state`room.callingTo`, null),
]
