// возвращает cookie c именем name, еcли еcть, еcли нет, то undefined
export function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

// уcтанавливает cookie c именем name и значением value
// options - объект c cвойcтвами cookie (expires, path, domain, secure)
export function setCookie(name, value, options) {
  options = options || {}

  let expires = options.expires

  if (typeof expires === 'number' && expires) {
    const d = new Date()
    d.setTime(d.getTime() + expires * 1000)
    expires = options.expires = d
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString()
  }

  value = encodeURIComponent(value)

  let updatedCookie = name + '=' + value

  for (const propName in options) {
    updatedCookie += '; ' + propName
    const propValue = options[propName]
    if (propValue !== true) {
      updatedCookie += '=' + propValue
    }
  }

  document.cookie = updatedCookie
}

// удаляет cookie c именем name
export function deleteCookie(name) {
  setCookie(name, '', {
    expires: -1
  })
}

export function setSessionId(sid) {
  if (!sid) {
    setCookie('ftSid', '', {
      expires: 0,
      path: '/',
      secure: true
    })
    ls.remove('fm.sessionId')
  } else {
    setCookie('ftSid', sid, {
      expires: 60 * 60 * 24 * 365,
      path: '/',
      secure: true
    })
    ls.set('fm.sessionId', sid)
  }
}

import ls from 'local-storage'

export function getSessionId() {
  try {
    let sid = getCookie('ftSid')
    if (!sid) {
      sid = ls.get('fm.sessionId')
      if (sid) {
        setSessionId(sid)
      }
    }
    return sid
  } catch (e) {
    console.log('@@@ cant get session')
  }
}
