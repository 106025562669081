import { sendAmplitudeEvent } from '../GAEvent'
import { getAwardURL } from '../api/apiURLUtil'
import { getSessionId } from '../cookies'
import { getState, setStoreWrapper } from '../StoreManager'
import { getUser, updateUser } from '../userLoader'
import { getMyUid } from '../my'
import { closeCurrentPanel } from '../../components/Panels/createPanelUtil'
import { canBuyCheck } from '../checkBalanceUtil'
import { getAwardCost, isAwardInSlot, updateAwardProgress } from './awardsProgressUtil'
import { isMe } from '../checks'
import { getUserMainCharacter } from './getUserMainCharacter'
import { showSnackbarNew } from '../../modules/app/factories'
import { getLocaleValue } from '../localeUil'
import { getUserAwardLocation } from './getUserAwardLocation'
import { getAwardCategory, prepareAwardConfig } from './awardsConfig'
import { closeFullscreenPlayer } from '../../components/Media/fullscreenPlayerUtil'
import askRateApp from '../askRateApp'

export async function setAwardInSlot(awardId, slot) {
  const myUid = getMyUid()

  //если уже утсановлен главный персонажем, то ничего не делаем
  if (getUserMainCharacter(myUid) === awardId || getUserAwardLocation(myUid) === awardId) {
    showSnackbarNew(getLocaleValue('already_set_in_main_slot'), 5000, 'error')
    return
  }

  const user = getUser(myUid)
  if (user) {
    if (!user.awards) {
      user.awards = {}
    }

    const inSlot = isAwardInSlot(awardId)
    if (inSlot !== -1) {
      delete user.awards[inSlot]
    }
    user.awards[slot] = {
      uid: myUid,
      award: awardId,
      slot: slot,
      date: Date.now()
    }

    updateUser(getMyUid(), user)
  }

  closeFullscreenPlayer()
  closeCurrentPanel()
  sendAmplitudeEvent('award', { action: 'set_in_slot', slot, award: awardId, category: getAwardCategory(awardId) })

  const response = await fetch(getAwardURL('/api/v1/awards/' + awardId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      slot: slot
    })
  }).catch(e => {
    console.error('setAwardInSlot', e)
  })

  askRateApp('award_set')

  if (!(response && response.ok)) {
    console.error('setAwardInSlot', { awardId, slot })
  }
}

export async function dellAwardFromSlot(awardId) {
  sendAmplitudeEvent('award', { action: 'dell_award', award: awardId, category: getAwardCategory(awardId) })

  const response = await fetch(getAwardURL('/api/v1/awards/' + awardId), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    }
  }).catch(e => {
    console.error('dellAwardFromSlot', e)
  })

  if (!(response && response.ok)) {
    console.error('dellAwardFromSlot', { awardId })
  }
}

export async function unlockAward(awardId, toUid, callback) {
  if (getState('awardBuying') === awardId) {
    return
  }
  setStoreWrapper('awardBuying', awardId)
  if (!canBuyCheck(getAwardCost(awardId), 'paidAward')) {
    setStoreWrapper('awardBuying', false)
    closeFullscreenPlayer()
    return
  }

  const response = await fetch(getAwardURL('/api/v1/awards/unlock'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      award: awardId,
      to_uid: toUid
    })
  }).catch(e => {
    setStoreWrapper('awardBuying', false)
    console.error('unlockAward', e)
    sendAmplitudeEvent('award', { action: 'unlock_error' })
  })

  if (response && response.ok) {
    const respObj = await response.json()
    callback(respObj)
    updateAwardProgress(toUid, awardId, respObj.rating)
    if (isMe(toUid)) {
      prepareAwardConfig(true)
    } else {
      showSnackbarNew(getLocaleValue('award_send_success'))
      // callSequence('chat.sendDialogMessageLocal',{})
    }

    sendAmplitudeEvent('award', {
      action: 'unlock_success',
      isMe: isMe(toUid),
      cost: getAwardCost(awardId),
      category: getAwardCategory(awardId),
      award: awardId
    })
    return
  }
  sendAmplitudeEvent('award', { action: 'unlock_error', award: awardId })
  callback()
}

