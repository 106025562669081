import { state } from 'cerebral'
import { AFK, GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18, MAN_ON_CAM, WRONG_FORMAT } from '../modules/buffs'
import { getState, setStoreWrapper } from './StoreManager'
import { checkMySubscription, LIVE_PERMISSION } from './subscriptionUtil'
import { sendAmplitudeEvent, sendPercentAmplitudeEvent } from './GAEvent'
import { getOnlineStatusForAmplitude, getStreamStatusForAmplitude, isStreaming } from '../modules/socket/actions'
import { imInRoom, imOnHomeRoute } from './appUtil'
import { getUserAgeRating } from './ageRating/ageRatingUtil'
import { getPostThemeId, getProfilePostId } from './userUtil'
import { getUserFeed } from '../modules/posts/signals/getPosts'
import { getAgeRatingByUid, getBroadcast, isVipStream } from '../modules/posters/showInRoom'
import { cantWatchCurrentStream, isPrivateInRoom } from './room/roomUtil'
import askRateApp from './askRateApp'
import { isMe } from './checks'
import { imVIP } from './my'
import { isCyber } from './cyber/cyberUtil'

//события от автора о создании и модерации трансляции
export function topicCreate() {
  const datetime_of_creation = Date.now()
  let datetime_of_moderation = 0

  setStoreWrapper('room.broadcastStartTime', datetime_of_creation)
  const userBuffs = getState(`app.users.${getState(state`auth.uid`)}.multi_buffs`) || {}

  let ageRating = 0
  if (userBuffs[GOOD_FOR_12]) {
    ageRating = 12
  } else if (userBuffs[GOOD_FOR_14]) {
    ageRating = 14
  } else if (userBuffs[GOOD_FOR_16]) {
    ageRating = 16
  } else if (userBuffs[GOOD_FOR_18]) {
    ageRating = 18
  } else if (userBuffs[WRONG_FORMAT]) {
    ageRating = WRONG_FORMAT
  } else if (userBuffs[AFK]) {
    ageRating = AFK
  } else if (userBuffs[MAN_ON_CAM]) {
    ageRating = MAN_ON_CAM
  }

  if (ageRating) {
    datetime_of_moderation = 1
  }

  const subscription_type = (getState(`auth.subscriptionSettings.${LIVE_PERMISSION}`) || '-1').toLowerCase()
  sendAmplitudeEvent('topic_create', {
    progress: 'click_on_start',
    codec: getState('room.codec') || 'H264',
    datetime_of_creation,
    content_rating: ageRating,
    subscription_type,
    videoCall: false,
    datetime_of_moderation
  })
}

export function publishStarts(videoCall) {
  const datetime_of_creation = getState('room.broadcastStartTime')
  const datetime_of_start = Date.now()
  const publishStartTime = Math.round((datetime_of_start - datetime_of_creation) / 1000)

  sendAmplitudeEvent('topic_create', {
    progress: 'publish',
    videoCall: Boolean(videoCall),
    codec: getState('room.codec') || 'H264',
    publish_start_time: publishStartTime,
  })

  if (videoCall) {
    sendAmplitudeEvent('video_call', {
      progress: 'publish',
      videoCall: true,
      publish_start_time: publishStartTime
    })
  }
}

export function onPublishEvent(progress, specialProps) {
  if (!specialProps) {
    specialProps = {}
  }
  specialProps['progress'] = progress
  sendAmplitudeEvent('stream_publish', specialProps)
}

export function onVideoCallClick() {
  setStoreWrapper('room.broadcastStartTime', Date.now())

  sendAmplitudeEvent('video_call', {
    progress: 'click'
  })
}

export function noCoinsForVideoCall() {
  sendAmplitudeEvent('video_call', {
    progress: 'no_coins'
  })
}

export function noVideoDeviceForCall() {
  sendAmplitudeEvent('video_call', {
    progress: 'no_media_device'
  })
}

export function tryToStartVideoCall() {
  sendAmplitudeEvent('video_call', {
    progress: 'try_start'
  })
}

export function publishErrorAmplitudeEvent(type) {
  sendAmplitudeEvent('publish_error', {
    type: type
  })
}

export function offlineCallEvent(uid) {
  sendAmplitudeEvent('offline_video_call', {
    progress: 'click',
    online: getOnlineStatusForAmplitude(uid)
  })
}

export function offlineCallAccept(uid) {
  sendAmplitudeEvent('offline_video_call', {
    progress: 'accept',
    online: getOnlineStatusForAmplitude(uid)
  })
}

export function callButtonClick(uid) {
  sendAmplitudeEvent('video_call', {
    progress: 'panel_click',
    online: getOnlineStatusForAmplitude(uid),
    stream: getStreamStatusForAmplitude(uid)
  })
}

export function sendDialogClick(uid, from) {
  sendAmplitudeEvent('dialog_message', {
    progress: 'panel_click',
    online: getOnlineStatusForAmplitude(uid),
    stream: getStreamStatusForAmplitude(uid),
    cyber: isCyber(uid),
    from: from
  })
}

export function liveClickEvent(uid) {
  sendPercentAmplitudeEvent('live_click', {
    online: getOnlineStatusForAmplitude(uid),
    stream: getStreamStatusForAmplitude(uid),
    home: imOnHomeRoute(),
    inRoom: imInRoom(),
    age_rating: getUserAgeRating(uid),
    hasTheme: Boolean(getPostThemeId(uid)),
    hasProfilePost: Boolean(getProfilePostId(uid)),
    roomNavigate: true,
    feed: getUserFeed()
  })
}

export function quizStatusEvent(status) {
  sendAmplitudeEvent('quiz', {
    progress: status
  })
}

let noLivesSend

export function sendNoLives() {
  if (!noLivesSend) {
    noLivesSend = true
    sendAmplitudeEvent('no_lives')
  }
}

let lastRoomTracked
let roomEntranceDate
let streamStartsDate
let privateStream = false
let waitingForStreamStarts = false

//срабатывает при каждом обновлении комнаты
export function roomEntranceAction(roomId) {
  if (!roomId) {
    return
  }
  //если я в той же комнате, что и был, то проверим что в ней поменялось
  if (lastRoomTracked === roomId) {
    //если я не ждал стрима, а теперь жду - обновим время захода
    if (!waitingForStreamStarts && isStreaming(roomId)) {
      waitingForStreamStarts = !cantWatchCurrentStream()
      roomEntranceDate = Date.now()
      return
    } else {
      //так проверим, что трансляция завершилась и пошлем событие и обновим дату входа
      if (!isStreaming(roomId) && streamStartsDate) {
        streamEndsAction()
        roomEntranceDate = Date.now()
        return
      }
      //если трансляция стала приватной - пошлем событие просмотра и обновим время входа в комнату
      if (privateStream !== isPrivateInRoom(roomId)) {
        sendStreamViewEvent(roomId)
        privateStream = isPrivateInRoom(roomId)
        roomEntranceDate = Date.now()
        streamStartsDate = Date.now()
      }
      return
    }
  }

  privateStream = isPrivateInRoom(roomId)
  if (lastRoomTracked) {
    roomLeaveAction()
  }
  waitingForStreamStarts = isStreaming(roomId) && !cantWatchCurrentStream()
  roomEntranceDate = Date.now()
  lastRoomTracked = roomId
}

export function roomLeaveAction(){
  //если я покидаю комнату, в которой был стрим, то пошлем аналитику
  streamEndsAction(lastRoomTracked)
}

export function streamStartsAction(){
  if (!lastRoomTracked) {
    console.error('NO ROOM ENTRANCE')
    return
  }
  streamStartsDate = Date.now()
}

export function streamEndsAction(){
  sendStreamViewEvent(lastRoomTracked)
}

let firstSend = true
export function sendStreamViewEvent(roomId) {
  try {
    if (!roomId || !waitingForStreamStarts || isMe(roomId)) {
      return
    }
    const streamData = getBroadcast(roomId) || {}
    const amData = {
      viewing_category: 'viewing_broadcast',
      content_rating: getAgeRatingByUid(roomId),
      model_id: roomId,
      subscription_type: checkMySubscription(roomId),
      broadcast_type: privateStream ? 'private' : 'public',
      height: streamData.h,
      vip_stream: !privateStream && isVipStream(roomId),
      width: streamData.w,
      protocol: getState('protocol')
    }

    if (streamData.brMetadata) {
      amData.codec = streamData.brMetadata.codec
      amData.subscription_type = (streamData.brMetadata.needSub || '-1').toLowerCase()
    }

    if (streamStartsDate > 0) {
      const brWatchTime = Date.now() - streamStartsDate
      if (brWatchTime < 100 || brWatchTime > 7575759) {
        amData.viewing_duration = 0
      } else {
        amData.viewing_duration = brWatchTime / 1000
      }

      const starting_duration = streamStartsDate - roomEntranceDate
      if (starting_duration < 20 || starting_duration > 1000000) {
        amData.starting_duration = 0
      } else {
        amData.starting_duration = starting_duration / 1000
      }

      if (brWatchTime > 60000) {
        askRateApp('stream_watch')
      }
    } else {
      //если не дождался и вышел, то время старта отрицательное
      const dif = Date.now() - roomEntranceDate
      amData.starting_duration = (dif < 20) ? 0 : (-dif / 1000)
      amData.viewing_duration = 0
    }
    if (firstSend || imVIP()) {
      firstSend = false
      sendAmplitudeEvent('topic_duration_user', amData)
    } else {
      sendPercentAmplitudeEvent('topic_duration_user', amData)
    }

    lastRoomTracked = null
    roomEntranceDate = null
    streamStartsDate = null
  } catch (e) {
    console.error('topic_duration_user')
  }
}
