import { getMyUid, isNotificationEnable } from '../../../utils/my'
import { getMyLSProp, setMyLSProps } from '../../../utils/LocalStorageUtil'
import { askGiftSnackbar, askPushSnackbar, geoRequestSnackbar, profileFillSnackbar } from '../../../utils/snackbarUtil'
import { getProfilePostId } from '../../../utils/userUtil'
import { isBrowserGeoSet } from '../../../utils/geo/geoUtil'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { MESSAGE_TYPE_BUFF, MESSAGE_TYPE_SYSTEM } from '../../../utils/manyConst'
import { isDatingApp, isPayoutDisable } from '../../../utils/appConfigUtil'
import { createFirstIncomePanel } from '../../../components/Panels/createPanelUtil'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { getCurrentRoomId } from '../../../utils/navigation/navigationUtil'
import { SPAMMER_UID } from '../../chat/actions'
import { addNewMessage, scrollOnAction } from '../../../utils/chat/chatMessagesManager'
import { isPopularUser } from '../../../utils/popularUtil'
import { sendBuff } from '../../../utils/buffUtils'
import { GIFT_DISCOUNT } from '../../buffs'
import { getExp } from '../../../utils/levelUtil'
import { isGiftDiscount } from '../../../utils/giftUtil'
import { isTWA } from '../../../utils/twa'

const NOTIFICATION_ASK_TIMEOUT = 24 * 60 * 60 * 1000
const GEO_ASK_TIMEOUT = 24 * 60 * 60 * 1000
const FILL_PROFILE_ASK_TIMEOUT = 12 * 60 * 60 * 1000
const GIFT_ASK_NOTIFICATION_TIMEOUT = 15 * 60 * 1000
const GIFTS_DISCOUNT_CARD_TIMEOUT = 10 * 24 * 60 * 60 * 1000

//таймаут между уведомлениями
const TIMEOUT = 30 * 1000

const NOTIFICATION = 'askNotificationDate'
const PROFILE_NOTIFICATION = 'askFillProfileDate'
const GEO_ASK_NOTIFICATION = 'askGEODate'
const GIFT_ASK_NOTIFICATION = 'askGift'
const GIFTS_DISCOUNT_NOTIFICATION = 'GIFTS_DISCOUNT_NOTIFICATION'
const NOTIFICATION_TYPES = [NOTIFICATION, PROFILE_NOTIFICATION, GEO_ASK_NOTIFICATION, GIFT_ASK_NOTIFICATION]

export function checkNotification(uid) {
  if (!isNotificationEnable() || isTWA()) {
    return
  }

  if (!checkAnyNotification()) {
    return
  }

  const askNotificationDate = getMyLSProp(NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + NOTIFICATION_ASK_TIMEOUT) < Date.now()) {
    askPushSnackbar(uid)
    setMyLSProps(NOTIFICATION, Date.now())
  }
}

export function giftNotificationWithTimeout(uid) {
  setTimeout(() => {
    checkGiftNotification(uid)
  }, 7000)
}

export function checkGiftNotification(uid) {
  //если юзер не популярный то никаких уведомлений
  if (!isPopularUser(uid)) {
    return
  }

  const askNotificationDate = getMyLSProp(GIFT_ASK_NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + GIFT_ASK_NOTIFICATION_TIMEOUT) < Date.now()) {
    askGiftSnackbar(uid)
    sendAmplitudeEvent('gift_ask_notification')
    setMyLSProps(GIFT_ASK_NOTIFICATION, Date.now())
    checkGiftDiscount()
  }
}

export function giftNotificationAfterPaymentOpen() {
  // sendBuff(getMyUid(), GIFT_DISCOUNT)
  //если больше 1000 опыта то не вешаем
  if (getExp(getMyUid()) > 1000) {
    return
  }

  //подождем 60 секунд, может и так купит монеты
  setTimeout(() => {
    //если не купил монеты, значит дадим скидку на подарок
    if ((getState('auth.coins') || 0) > 1000) {
      return
    }
    checkGiftDiscount(1000)
  }, 60000)
}

export function checkGiftDiscount(timeout) {
  const askNotificationDate = getMyLSProp(GIFTS_DISCOUNT_NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + GIFTS_DISCOUNT_CARD_TIMEOUT) < Date.now()) {
    //добавим таймаут до скидки, что бы не спамить подряд
    setTimeout(() => {
      setMyLSProps(GIFTS_DISCOUNT_NOTIFICATION, Date.now())
      sendBuff(getMyUid(), GIFT_DISCOUNT)
    }, timeout || 7000)
  }
}

export function checkFillProfileNotification() {
  if (getProfilePostId(getMyUid())) {
    return
  }

  if (!checkAnyNotification()) {
    return
  }

  let askNotificationDate = getMyLSProp(GEO_ASK_NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + GEO_ASK_TIMEOUT) < Date.now()) {
    profileFillSnackbar()
    setMyLSProps(GEO_ASK_NOTIFICATION, Date.now())
  }
}

export function checkGEONotification() {
  if (isBrowserGeoSet()) {
    return
  }

  if (!checkAnyNotification()) {
    return
  }

  let askNotificationDate = getMyLSProp(GEO_ASK_NOTIFICATION) || 0

  if (!askNotificationDate || (askNotificationDate + FILL_PROFILE_ASK_TIMEOUT) < Date.now()) {
    geoRequestSnackbar()
    setMyLSProps(PROFILE_NOTIFICATION, Date.now())
  }
}

function checkAnyNotification() {
  let lastNotification = 0
  let currentType = 0
  NOTIFICATION_TYPES.forEach(type => {
    currentType = getMyLSProp(type) || 0
    if (currentType > lastNotification) {
      lastNotification = currentType
    }
  })

  if (lastNotification && (lastNotification + TIMEOUT) < Date.now()) {
    return false
  }

  return true
}

const chatNotifications = {}

export function chatNotification(key, metadata, type, force, specialData) {
  if (chatNotifications[key] && !force) {
    return
  }

  addNewMessage()

  chatNotifications[key] = true
  const roomId = getState('chat.chatRoomId')
  const allMessages = getState('chat.roomsMessages') || {}
  const messages = allMessages[roomId] || []

  const data = specialData || {
    text: key,
    type: type || MESSAGE_TYPE_SYSTEM,
    messageOriginal: key
  }
  if (metadata) {
    data.metadata = metadata
  }
  messages.push(data)

  allMessages[roomId] = messages

  scrollOnAction()
  setStoreWrapper('chat.roomsMessages', allMessages)
}

export function checkIncomeNotification() {
  if (isPayoutDisable(true) || getState('auth.usdBalance')) {
    return
  }
  sendAmplitudeEvent('first_income_panel')
  createFirstIncomePanel()
}

export function buffActionMessage(buffId, fromUid, toUid, action) {
  const allMessages = getState('chat.roomsMessages') || {}
  const roomId = getCurrentRoomId()
  const messages = allMessages[roomId] || []

  const data = {
    text: buffId,
    fromId: fromUid || SPAMMER_UID,
    toId: toUid,
    type: MESSAGE_TYPE_BUFF,
    messageOriginal: buffId,
    metadata: {
      action
    }
  }
  messages.push(data)

  allMessages[roomId] = messages
  scrollOnAction()

  setStoreWrapper('chat.roomsMessages', allMessages)
}

export function nyGiftDiscountActivate() {
  if (!isGiftDiscount() && !isDatingApp()) {
    sendBuff(getMyUid(), GIFT_DISCOUNT, true)
  }
}
