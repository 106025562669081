import { showSnackbar } from '../../app/factories'
import { state } from 'cerebral'
import banRoomChat from './banRoomChat'
import { DELETERequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'
import { getCurrentRoomId } from '../../../utils/navigation/navigationUtil'
import { deleteChatMessage } from '../../../utils/chat/chatUtil'

export default [
    // banMsg,
    showSnackbar(state`intl.content.claim_success`, 3000),
    // ({ props, get }) => ({
    //   method: 'messageComplaint',
    //   params: {
    //     messageId: props.messageId + '',
    //     roomId: get(state`app.roomId`)
    //   },
    // }),
    // send,
    ({ props }) => {
        DELETERequest(getCoreUrl('/api/v1/chat/history?room_id=' + getCurrentRoomId() + '&message_id=' + props.message_id),{},
            (data) => {
            console.log('deletePT')
                deleteChatMessage(data.result.roomId, data.result.deletedMessage)
        })
    },
    banRoomChat
]
