import { useStore, create } from 'zustand'

const useUsersStore = create(set => ({
    users: {},
    setUsers: users => {
        set((state) => ({ users: { ...state.users, ...users } }))
    },
    // Функция для получения данных пользователя по его id
    getUser: uid => {
        return useUsersStore.getState().users[uid]
    },
}))

export const getZUser = uid => {
    return useUsersStore.getState().getUser(uid)
}

export function setUsers(users) {
    useUsersStore.getState().setUsers(users)
}

export default useUsersStore
