import { showSnackbar } from '../../app/factories'
import { props, state } from 'cerebral'
import { checkEmailRequest } from '../actions/checkEmailRequest'
import { createPanel } from '../../app/actions'
import { forgotPasswordStep2Panel } from '../../../components/Panels/types'

export default [
  checkEmailRequest('auth.resetPasswordForm'), {
    notExists: showSnackbar(state`intl.content.find_noone`, 3000, 'error'),
    exists: [
      () => ({ options: forgotPasswordStep2Panel.options }),
      createPanel
    ],
    error: showSnackbar(props`error`, 3000, 'error')
  }
]
