import { isNoCyberApp } from '../appConfigUtil'
import { getSettings } from '../settingsUtil'
import { loadUsersAndProfiles } from '../userLoader'
import { setStoreWrapper } from '../StoreManager'
import { getProfilePostId } from '../userUtil'
import { getPostData } from '../postUtils'
import { checkSelectedFeed } from '../../modules/filters/actions/feedUtil'
import { TYPE_CYBER, TYPE_LIVE_ONLY } from '../../modules/posts/signals/getPosts'
import usePostersStore from '../../zustand/usePostersStore'

export function getAllCyber() {
    if (isNoCyberApp()) {
        return []
    }
    return getSettings('ai_users', [])
}

export function loadCyberPosters() {
    const uids = getAllCyber()
    loadUsersAndProfiles(uids, () => {
        usePostersStore.getState().setCyberPosters(uids)
    })
}

export function setCyberPosters() {
    const uids = getAllCyber()
    loadUsersAndProfiles(uids, () => {
        const posts = []
        uids.forEach(uid => {
            const pid = getProfilePostId(uid)
            if (pid) {
                const postData = getPostData(pid)
                if (postData && !postData.error) {
                    posts.push(pid)
                } else {
                    console.log('no cyber post data')
                }
            } else {
                console.log('no cyber pid')
            }
        })
        if (checkSelectedFeed(TYPE_CYBER) || checkSelectedFeed(TYPE_LIVE_ONLY)) {
            setStoreWrapper('posts.topPosts', posts)
            setStoreWrapper('posts.loadingPostsStatus', 'loaded_all')
        }
    })
}

export function isCyber(uid) {
    return getAllCyber().includes(uid)
}
