import config from '@/config'
import { isLocalHost } from '../urlUtils'

const { ftCoreUrl, geoURL, ftAward, minigamesUrl, minigamesUrlDev } = config

export function getCoreUrl(url, local) {
  if (local && isLocalHost()) {
    return 'http://localhost:8000' + url
  }
  return ftCoreUrl + url
}

export function getUsersURL(url, local) {
  return getCoreUrl('/api/v1/users/' + url, local)
}

export function getGEOUrl(url) {
  return geoURL + url
}

export function getReviewsURL(url) {
  if (!url) {
    return getCoreUrl('/api/v1/reviews')
  }
  return getCoreUrl('/api/v1/reviews/' + url)
}

export function getAwardURL(url) {
  return ftAward + url
}

export function getMiniGamesUrl(url) {
  // if (!isProdGame) {
  //   return minigamesUrlDev + url
  // }
  return minigamesUrl + url
}
