import { getUser } from '@/utils/userLoader'

const EXP_TO_LVL = 2000
const MAX_PIC_LVL = 96
const MAX_SHAPE_ID = 8

const SHAPE_SWITCH = 12
const MATERIAL_SWITCH = [1, 2, 4, 8, 16, 30, 40, 50]

const LVL_SWITCH = {}
genLVLSwitch()

function genLVLSwitch() {
  let step = 0
  MATERIAL_SWITCH.forEach(materialSwitch => {
    step += materialSwitch * SHAPE_SWITCH
    LVL_SWITCH[step] = materialSwitch * SHAPE_SWITCH
  })
}

export function getLevel(uid) {
  return expToLVL(getExp(uid))
}

export function expToLVL(exp) {
  if (!exp) {
    return 0
  }

  return 1 + Math.floor(exp / EXP_TO_LVL)
}

export function getExpToNextLVL(uid) {
  const exp = getExp(uid)
  if (!exp) {
    return EXP_TO_LVL
  }

  return EXP_TO_LVL - (exp % EXP_TO_LVL)
}

export function getExp(uid) {
  const user = getUser(uid)
  //КОСТЫЛЬ, потому что в БД по дефолту был 1 опыт вместо 0
  if (!user || !user.experience || user.experience < 2) {
    return 0
  }

  return user.experience
}

export function getFilledPercent(uid) {
  const exp = getExp(uid)
  if (!exp) {
    return 0
  }
  const curLVLExp = expOnCurrentLVL(exp)
  return Math.floor(curLVLExp / EXP_TO_LVL * 100)
}

export function expToNextLVL(uid) {
  const exp = getExp(uid)
  if (!exp) {
    return EXP_TO_LVL
  }
  return EXP_TO_LVL - expOnCurrentLVL(exp)
}

function expOnCurrentLVL(exp) {
  return exp % EXP_TO_LVL
}

//кеш уровень => номер картинки, след картинка,  что бы не считать кучу раз
const LVL_CASH = {}

export function getLVLPicNumber(uid) {
  const lvl = getLevel(uid)
  if (!lvl) {
    return 0
  }
  return getPicLvlInfo(lvl).picLVL
}

export function getNextLVLPicNumber(uid) {
  const lvl = getLevel(uid)
  return getPicLvlInfo(lvl).nextPic
}

function getPicLvlInfo(lvl) {
  if (LVL_CASH[lvl]) {
    return LVL_CASH[lvl]
  }

  let resp = {}

  let counter = 0
  let prevStep = 0

  //Номер картинки уровня текущего
  let picLVL = 0

  //сколько уровней набрано в рамках текущего материала
  let achievedSteps = 0

  //всего шагов в рамках текущего материала
  let maxSteps = 0

  //следующий уровень, когда поменяется картинка
  let nextLvlSwitchPic = 0

  //Номер формы, для описания и названия
  let shapeID = 0

  //Номер материала для описания
  let materialID = 0

  let shapeCounter = 0

  if (lvl == 0) {
    resp['picLVL'] = 0
    resp['achievedSteps'] = 0
    resp['maxSteps'] = MATERIAL_SWITCH[0]
    resp['nextLvlSwitchPic'] = MATERIAL_SWITCH[0]
    resp['nextPic'] = 1
    resp['shapeID'] = 0
    resp['materialID'] = 0
    resp['shapeCounter'] = 0
  } else {
    Object.keys(LVL_SWITCH).map(step => {
      //если мой уровень ниже порога смены формы, то посчитаем материал и форму
      if (step > lvl) {
        //если это не первая форма, то логика одна
        if (prevStep) {
          maxSteps = MATERIAL_SWITCH[counter]

          let picCount
          if (lvl == prevStep) {
            shapeID = counter
            picCount = 0
            materialID = SHAPE_SWITCH
            achievedSteps = 0
            nextLvlSwitchPic = lvl + maxSteps
          } else {
            //посмотрим, сколько уровней пройденов  форме и поделим на порог смены материала, округлим в меньшую
            picCount = Math.floor((lvl - prevStep) / maxSteps)

            //если я на нулевом уровне новой формы, то нужно написать про максимальный материал,
            // 0 материала нет, он только для тех кто без опыта совсем
            if (!picCount) {
              shapeID = counter
            } else {
              shapeCounter = counter
              shapeID = counter + 1
            }

            materialID = picCount || SHAPE_SWITCH
            achievedSteps = (lvl - prevStep) % maxSteps
            nextLvlSwitchPic = lvl + maxSteps - achievedSteps
          }
          shapeCounter = counter
          picLVL = counter * SHAPE_SWITCH + picCount
          return

          //если первая форма, то логика другая
        } else {
          shapeID = 1
          materialID = lvl
          maxSteps = MATERIAL_SWITCH[counter]
          achievedSteps = maxSteps - 1
          nextLvlSwitchPic = lvl + maxSteps - achievedSteps
          picLVL = lvl
          return
        }
      }
      prevStep = step
      counter++
    })

    if (picLVL) {
      resp['picLVL'] = picLVL
      resp['achievedSteps'] = achievedSteps
      resp['maxSteps'] = maxSteps
      resp['nextLvlSwitchPic'] = nextLvlSwitchPic
      resp['nextPic'] = picLVL < MAX_PIC_LVL ? picLVL + 1 : MAX_PIC_LVL
      resp['shapeID'] = shapeID
      resp['materialID'] = materialID
      resp['shapeCounter'] = shapeCounter
    } else {
      resp['picLVL'] = MAX_PIC_LVL
      resp['achievedSteps'] = maxSteps
      resp['maxSteps'] = maxSteps
      resp['nextPic'] = MAX_PIC_LVL
      resp['nextLvlSwitchPic'] = lvl + 1
      resp['shapeID'] = MAX_SHAPE_ID
      resp['materialID'] = SHAPE_SWITCH
      resp['shapeCounter'] = shapeCounter
    }
  }

  LVL_CASH[lvl] = resp
  return resp
}

export function nextLvlSwitchPic(uid) {
  return getPicLvlInfo(getLevel(uid)).nextLvlSwitchPic
}

export function getLvlProgressInfo(uid) {
  const nextLvlInfo = getPicLvlInfo(getLevel(uid))
  return {
    achievedSteps: nextLvlInfo.achievedSteps,
    maxSteps: nextLvlInfo.maxSteps,
  }
}

export function getShapeID(uid) {
  return getPicLvlInfo(getLevel(uid)).shapeID
}

export function getLVLMaterialID(uid) {
  return getPicLvlInfo(getLevel(uid)).materialID
}

export function getEne(uid) {
  return getPicLvlInfo(getLevel(uid)).shapeCounter
}
