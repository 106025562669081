import { props, state } from 'cerebral'
import { resetPasswordRequest } from '../actions'
import { prepareSnackbarTitle, showSnackbar } from '../../app/factories'
import { panelsOnPop } from '../../app/actions'
import { set, wait } from 'cerebral/factories'

export default [
  resetPasswordRequest, {
    success: [
      panelsOnPop,
      wait(300),
      panelsOnPop,
      prepareSnackbarTitle('intl.content.ce_sended', 'auth.resetPasswordForm.email'),
      showSnackbar(props`snackbarTitle`, 30000, 'mail'),
      set(state`auth.resetPasswordForm`, { email: '', password: '' })
    ],
    error: showSnackbar(props`error`, 3000, 'error')
  }
]
