import { GAME_CARD_ROOM_OWNER_ACTION, getMagic, prepareAndSetGameField, setHerroWonState } from './cardGameUtil'
import { setStoreWrapper } from '../StoreManager'
import { getCurrentRoomId, isMyRoom } from '../navigation/navigationUtil'
import { GETRequest, POSTRequest } from '../api/requestWrapper'
import { getMiniGamesUrl } from '../api/apiURLUtil'
import { canBuyCheck } from '../checkBalanceUtil'
import { gameFinnish } from './cardGameTick'
import { closeCurrentPanel } from '../../components/Panels/createPanelUtil'
import { sendAmplitudeEvent } from '../GAEvent'
import { checkAndSpentEnergy } from '../energy/energyUtil'

let gameInRoom
const HERO_WON = 'hero_won'

export function getRoomGame() {
  if (gameInRoom === getCurrentRoomId()) {
    return
  }
  const loadedRoom = getCurrentRoomId()
  gameInRoom = getCurrentRoomId()
  GETRequest(getMiniGamesUrl('/api/v1/card-games/' + loadedRoom), (game) => {
    if (loadedRoom !== getCurrentRoomId()) {
      return
    }
    if (game.status === HERO_WON) {
      setStoreWrapper('gameBoss', game.boss)
      setHerroWonState({ winner: game.winner })
      return
    }
    startGame(game)
  })
}

export function startGame(game) {
  setStoreWrapper('winnerInfo', null)
  setStoreWrapper('gameBoss', game.boss)
  prepareAndSetGameField(game.cards)
}

export function buyGameCard(cardId) {
  const magicCard = getMagic(cardId)
  if (!magicCard) {
    return
  }
  if (magicCard.type === GAME_CARD_ROOM_OWNER_ACTION && !isMyRoom()) {
    return
  }

  if (magicCard.energy) {
    // closeCurrentPanel()
    checkAndSpentEnergy(magicCard.energy, () => {
      sendAmplitudeEvent('card_game', {
        type: 'buy_card',
        cardId,
        cost: magicCard.cost,
        energy: magicCard.energy
      })

      POSTRequest(getMiniGamesUrl('/api/v1/card-games/' + getCurrentRoomId() + '/cards'), { card_id: cardId }, (result) => {
        //TODO хз нужно ли что-то делать, все равно будет WSS уведомление
      })
    }, magicCard.energy, true)
    return
  } else if (magicCard.cost) {
    if (!canBuyCheck(magicCard.cost)) {
      return
    }
    closeCurrentPanel()

    sendAmplitudeEvent('card_game', {
      type: 'buy_card',
      cardId,
      cost: magicCard.cost,
      energy: magicCard.energy
    })

    POSTRequest(getMiniGamesUrl('/api/v1/card-games/' + getCurrentRoomId() + '/cards'), { card_id: cardId }, (result) => {
      //TODO хз нужно ли что-то делать, все равно будет WSS уведомление
    })
  }

  //TODO костыль временный
  // addGameCard(cardId, getMyUid(), GAME_CARD_MAGIC, strength)
}

export function clearCardGame() {
  gameInRoom = null
  gameFinnish()
  setStoreWrapper('gameBoss', null)
  setStoreWrapper('gameField', null)
  setStoreWrapper('winnerInfo', null)
}
