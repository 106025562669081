import { isDesktopSkin } from './checks'

export function getOverlayButtonStyle() {
  return isDesktopSkin() ? 'simpleButton postersButtonLike' : 'simpleButton postersButtonLikeMobile'
}

export function likeButtonClass(subType) {
  if (isDesktopSkin()) {
    return subType ? 'simpleButton postersButtonLiked' : 'simpleButton postersButtonLike'
  }
  return subType ? 'simpleButton postersButtonLikedMobile' : 'simpleButton postersButtonLikeMobile'
}