import { setStoreWrapper } from './StoreManager'
import config from '@/config'
import { getSessionId } from '@/utils'

const { ftCoreUrl } = config

const ONLINE_CHECK_TIMEOUT = 20000
let onlineCheck

async function loadOnlineSubs() {
  if (!getSessionId()) {
    return
  }

  try {
    /** всега грузим только первую страницу, этого хватит для баров,
     общее число онлайна можно запросить отдельно и показать в UI */

    let url = `${ftCoreUrl}/api/v1/subscriptions/authors?only_online=true`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })

    //TODO возможно нужна сортировка
    const users = (await response.json() || {})['authors']
    let usersUids = []

    Object.keys(users).map(uid => {
      usersUids.push(uid)
    })
    setStoreWrapper('onlineSubs', usersUids)
    onlineCheck = setTimeout(() => loadOnlineSubs(), ONLINE_CHECK_TIMEOUT)
  } catch (e) {
    console.warn('@@@ loadOnlineSubs error:', e)
  }
}

export function startLoadOnlineSubs() {
  if (onlineCheck) {
    clearTimeout(onlineCheck)
  }
  loadOnlineSubs()
}

export function clearLoadOnlineSubs() {
  if (onlineCheck) {
    clearTimeout(onlineCheck)
  }
  setStoreWrapper('onlineSubs', [])
}
