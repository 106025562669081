import { state } from 'cerebral'
import { when } from 'cerebral/factories'
import config from '@/config'
import { loadTopBarProfiles } from '../../../utils/topBar/topBarFillUtil'

const { ftCoreUrl } = config

let loadedSponsorList = []
let page = 0

export const loadMoreSponsors =  async ({ path, store, get, props }) => {
  if (get(state`sponsors.isLoading`)) {
    return
  }

  if (get(state`sponsors.allLoaded`)) {
    store.set('sponsors.data', loadedSponsorList)
    loadTopBarProfiles()
    return
  }

  if (props.firstOpen && loadedSponsorList.length) {
    store.set('sponsors.data', loadedSponsorList)
    return
  }

  store.set('sponsors.isLoading', true)

  const getPage = page === 0 ? '' : `?page=${page}`
  const queryString = `${ftCoreUrl}/api/v1/users/sponsors${getPage}`

  try {
    const response = await fetch(queryString)
    if (!response.ok) { return }
    const result = await response.json()

    const resultList = []
    for (const i in result) {
      resultList.push({ uid:i, dollars:result[i] })
    }

    if (resultList.length === 0) {
      store.set('sponsors.isLoading', false)
      store.set('sponsors.allLoaded', true)
    } else {
      page++
      loadedSponsorList = loadedSponsorList.concat(resultList)
      store.set('sponsors.data', loadedSponsorList)
      store.set('sponsors.isLoading', false)
    }
  } catch (e) {
    console.warn('@@@ load sponsors error:', e)
  }
  return
}

export const getSponsors = [
  when(
    state`sponsors.isLoading`,
    state`sponsors.allLoaded`,
    state`sponsors.selectedSponsors`,
    (isLoading, selectedSponsors) => (isLoading) && selectedSponsors
  ), {
    true: [],
    false: [ loadMoreSponsors ]
  }
]
