import { state } from 'cerebral'
import { setSessionId } from '@/utils'
import config from '@/config'

const { httpApiUrl } = config

export const authWithEmail = async ({ path, store, get }) => {
  store.set('auth.isAuthComplete', false)
  try {
    const { username, password } = get(state`auth.loginForm`)
    const fd = new FormData()
    fd.append('username', username)
    fd.append('password', password)

    const response = await fetch(httpApiUrl + '/auth', {
      method: 'POST',
      body: fd
    })
    if (!response.ok) {
      const result = await response.json()
      return path.error({ error: (result && result.error) || response.statusText })
    } else {
      const result = await response.json()
      if (result.error) {
        return path.error({ error: result.error })
      }
      store.set('auth.uid', result.userid)
      setSessionId(result.access_token)
      // uploadOneSignalId()
      return path.success()
    }
  } catch (e) {
    console.warn('@@@ auth with email error', e)
    return path.error({ error: 'network error' })
  }
}
