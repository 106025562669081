import prodConfig from './prod.config.json'
import testConfig from './test.config.json'
import devConfig from './dev.config.json'

let config = isProdEnv ? prodConfig : testConfig

if (isDev && isProdEnv) config = { ...config, ...devConfig }

export default config

export const DESCR_LIMIT = 30000
export const TITLE_LIMIT = 60
export const NAME_LIMIT = 30

export const BITCOIN = 'btc'
export const YANDEX = 'yndx'
export const EPAYMENT = 'epay'
export const CARD = 'card'
export const CARD_RUSSIA = 'card_russia'
export const SEPA = 'sepa'
export const BITSAFE = 'bitsafe'
export const INVOICE = 'invoice'
export const SWIFT = 'swift'
export const ACH = 'ach'
export const QIWI = 'qiwi'
export const CT = 'ct'
export const AGENT = 'agent'
export const USDT = 'usdt'
export const PYONEER = 'pyoneer'
export const ADVCASHE = 'advcashe'
export const PAXUM = 'paxum'
export const ALIPAY = 'alipay'
export const PAYTM = 'paytm'
export const IMPS = 'imps'
export const PAGBANK = 'PagBank'
export const GCASH = 'GCash'
export const GRABPAY = 'GrabPay'
export const PAYMAYA = 'PayMaya'

