import showPost from './showPost'
import postComplaintId from './postComplaintId'
import postComplaintUid from './postComplaintUid'
import postComplaintDelete from './postComplaintDelete'
import getPost from './getPost'
import postLike from './postLike'
import hideCoverSelection from './hideCoverSelection'
import freePhotoView from './freePhotoView'
import setFilter, { setDefaultFilter } from './setFilter'
import selectTag from './selectTag'
import {
    getMorePosts,
    getMoreUserPosts,
    getSimilarPosts,
    getTopPosts,
    getUserPosts,
    getUserSubsPosts
} from './getPosts'
import resetPosts from './resetPosts'
import selectHDQuality from './selectHDQuality'
import postLoadCheckNew from './postLoadCheckNew'
import postsWatched from './postsWatched'
import showLastPost from './showLastPost'
import onPostClick from './onPostClick'
import onFixedPost from './onFixedPost'
import { saveTopUsers } from '../../../utils/topBar/topBarFillUtil'
import sendPostView from './sendPostView'

export default {
    setFilter,
    setDefaultFilter,
    selectTag,
    showPost,
    postsWatched,
    showLastPost,
    onPostClick,
    hideCoverSelection,
    freePhotoView,
    postComplaintId,
    postComplaintUid,
    postComplaintDelete,
    getPost,
    postLike,
    getTopPosts,
    getMorePosts,
    getUserSubsPosts,
    getMoreUserPosts,
    saveTopUsers,
    getSimilarPosts,
    getUserPosts,
    resetPosts,
    selectHDQuality,
    onFixedPost,
    postLoadCheckNew,
    sendPostView
}
