import { sendAmplitudeEvent } from '../GAEvent'
import config from '@/config'
import { getSessionId } from '../cookies'
import { getState, setStoreWrapper } from '../StoreManager'
import { getUser } from '../userLoader'

const { ftCoreUrl } = config

export function saveMyBDay() {
  if (!getState('myBDay') || isNaN(getState('myBDay'))) {
    return
  }

  setStoreWrapper('isAvaSet', false)

  const bDay = new Date(getState('myBDay'))
  console.log('iso ' + bDay.toISOString().substring(0, 10))

  fetch(`${ftCoreUrl}/api/v1/users/dob`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      dob: bDay.toISOString().substring(0, 10)
    })
  }).catch(e => console.warn('catch error', e))

  sendAmplitudeEvent('profile_age', {
    age: getMyRealAge()
  })
}

function bDayToAge(dateString) {
  const date = new Date(dateString)
  if (date && !isNaN(date)) {
    return new Date().getFullYear() - date.getFullYear()
  }

  return 21
}

export function getUserAge(uid) {
  const user = getUser(uid)
  if (!user || !user.birthday) {
    return 21
  }
  const age = bDayToAge(user.birthday * 1000)
  if (age < 18) {
    return 21
  }
  return age
}

export function getMyAge() {
  return bDayToAge(getState('myBDay'))
}

export function getMyRealAge() {
  if (!getState('myBDay')) {
    return 21
  }
  return bDayToAge(getState('myBDay'))
}

export function hideOrientation() {
  return true//isRTL()
}

