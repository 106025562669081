export const dispatchUrlChangeToParent = () => {
  const url = location.pathname.indexOf('new-chat') !== -1
    ? location.pathname.substring(10)
    : location.pathname.substring(1)
  window.parent.postMessage(
    JSON.stringify(
      { type: 'urlchange', payload: `#${url}` }
    ),
    '*'
  )
}
