import controller from '@/controller'
import { isMe } from './checks'
import { getLocaleValue } from './localeUil'
import { getMyProfile, getMyUid } from './my'
import { getUser } from './userLoader'
import { callSequence } from './StoreManager'
import { getZPostData } from '../zustand/usePostsStore'
import { imAdmin } from './adminUtil'
import { isTagAgeGood } from './ageRating/ageRatingUtil'

export const PROFILE_TAG = 1067952
export const STREAM_THEME_TAG = 1131021
export const MALE = 38
export const IS_REAL = 35
export const INTERESTING = 36
export const HD = 33
export const FEMALE = 83341
export const VIDEOCHAT = 1085661
export const HETERO = 1476374

export const SKYPE_TAG = 1348801
export const FB_TAG = 1348799
export const SNAPCHAT_TAG = 1348800
export const TIK_TOK_TAG = 1360605
export const INSTAGRAM_TAG = 1376652
export const EMAIL_TAG = 1348798
export const FACE_DETECTED = 1489662
export const NO_FACE = 1489664

export const GENDER_MISMATCH_TAG = 1545618

export const DESCRIPTION_REMOVED = 1493889

export const BAD_TITLE = 1849093


export const FIXED_POST = 1063614

export const GAMING_TAG = 1072431
export const ART_TAG = 39
export const GIF_TAG_ID = 1120922

export const showPostInBlog = postData => {
  if (!postData) {
    return false
  }

  if (postData.tags) {
    if (postData.tags.indexOf(STREAM_THEME_TAG) !== -1) {
      return false
    }
  }

  return true
}

//если забаненно возвращает true
export const postComplaintCheck = postId => {
  const lsp = controller.getState('auth.myLSProps')
  let banned = lsp && lsp.bannedPosts && lsp.bannedPosts[postId]
  return banned
}

//получение uid автора поста
export const getPostAuthor = (postId) => {
  if (!postId) {
    return undefined
  }
  const postData = getPostData(postId)
  if (!postData) {
    return undefined
  }
  return postData.uid
}

export function isMyPost(postId) {
  return isMe(getPostAuthor(postId))
}

//получение данных поста
export const getPostData = (postId) => {
  return getZPostData(postId) || controller.getState(`posts.postsData.${postId}`)
}

export const isProfile = (postId) => {
  const postData = getPostData(postId)
  if (postData && postData.tags && postData.tags.indexOf(PROFILE_TAG) !== -1) {
    return true
  }

  return false
}

export function getPostDescription(postId, original) {
  const postData = getPostData(postId)
  if (!postData) {
    return null
  }
  if (postData.tags && postData.tags.includes(DESCRIPTION_REMOVED) && !isMyPost(postId)) {
    return null
  }
  if (isMyPost(postId) || original) {
    return postData.originalDesc
  }

  return postData.description || postData.originalDesc
}

export function getPostModerationStatus(postId) {
  if (!postId) return null

  const post = getPostData(postId)
  if (!post) return null

  const isVideo = post.postType === 1
  switch (post.statusFromDb) {
    case 0:
      return null//intl.ui_up115
    case 5:
      return isVideo ? getLocaleValue('bad_video') + ' \u00A0 ' : getLocaleValue('bad_photo')
    case 6:
      return isVideo ? getLocaleValue('someone_elses_video') + ' \u00A0 ' : getLocaleValue('not_your_photo')
    case 7:
      return getLocaleValue('bad_descr')
    case 8:
      return getLocaleValue('duplicate')
    case 101:
      return getLocaleValue('ui_up_status6')
  }

  return null
}

export function getMyProfileModerationStatus() {
  const user = getUser(getMyUid())
  if (user && user.storage && user.storage.profilePost && user.storage.profilePost.postId && !getMyProfile()) {
    return getPostModerationStatus(user.storage.profilePost.postId)
  }
  return null
}

export function sendPostViewFunc(postId) {
  callSequence('posts.sendPostView', { postId })
}

export const getPostStatus = (post, intl) => {
  if (!post) return null
  const isVideo = post.postType === 1
  switch (post.statusFromDb) {
    case 0:
      return ''//intl.ui_up115
    case 5:
      return isVideo ? intl.bad_video + ' \u00A0 ' : intl.bad_photo
    case 6:
      return isVideo ? intl.someone_elses_video + ' \u00A0 ' : intl.not_your_photo
    case 7:
      return intl.bad_descr
    case 8:
      return intl.duplicate
    case 101:
      return intl.ui_up_status6
  }

  return null
}

export function getGifTags(tags, tagsData) {
  let text = ''
  if (tags && tags.indexOf(GIF_TAG_ID) !== -1) {
    if (tagsData[GIF_TAG_ID] !== undefined) {
      text = tagsData[GIF_TAG_ID].title
    }
  }
  return text
}


export const filterTags = (tags, tagsData) => {
  const priorityTags = controller.getState('posts.priorityTags') || []
  const postCategories = controller.getState('intl.postCategories')
  return tags.filter((tagId, idx) => {
    if (tags.indexOf(tagId) !== idx) {
      return false//remove duplicates
    }
    if (priorityTags.indexOf(parseInt(tagId)) !== -1) {
      return false//priority tags must be hidden
    }

    const tagInfo = tagsData[tagId]
    if (tagInfo) {
      if (tagInfo.categories && tagInfo.categories.length) {
        if (
            tagInfo.categories.includes('admin') ||
            tagInfo.categories.includes('seo') ||
            tagInfo.categories.includes('moderator') ||
            tagInfo.categories.includes('autotags')
        ) {
          return imAdmin()
        }
        let isModer = false
        let categoryInfo
        tagInfo.categories.forEach(category => {
          if (postCategories) {
            categoryInfo = postCategories[category]
            if (categoryInfo && categoryInfo.visible === 'admin') {
              isModer = true
              return false
            }
          }
        })

        //если тег видим только модераторам, то видимость зависит от того модерато ли я
        if (isModer) {
          return imAdmin()
        }
      }

      return isTagAgeGood(tagInfo.ageRating)
    }
    return false
  })
}
