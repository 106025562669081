import './index.css'

/**
 !!!!! ВСЕГДА СТАВИТЬ margin В 0px
 !!!!! ВСЕГДА менять в основном HTML прелоад ссылку фонтеллы при смене шрифта
 */
import './static/fontello/css/fontello.css'
import './static/fontello/css/fontello-codes.css'

import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
import { Container } from '@cerebral/react'
import AppLoader from './components/AppLoader'
import controller from './controller'
import './polyfills'
import is from 'is_js'
import { isDesktopSkin } from './utils'

console.log(new Date(AppBuildTime))
window.startLoadingTime = Date.now()

window.is = is

if (is.ipad()) {
  document.ontouchmove = function (e) {
    e.preventDefault()
  }
}

if (!isDev && isProdEnv) {
  Sentry.init({
    release: 'fm_js@' + AppBuildTime,
    dsn: 'https://68e9ac09dd164fb692c0cb9d285b61b9@sentry.faminta.com/2',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const AppWrapper = () =>
  <Container app={controller}>
    <AppLoader/>
  </Container>

const container = document.getElementById('app')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<AppWrapper/>)

if (!isDev && isProdEnv) {
  setInterval(() => {
    const appNode = document.querySelector('#app')
    if (appNode && appNode.childElementCount === 0) {
      console.log('redirecting...')
      location.href = 'https://' + location.host + '/en/p' + location.search
    }
  }, 4000)
}

const serverNode = document.getElementById('server-render')
if (serverNode) serverNode.style.display = 'none'
const appNode = document.getElementById('app')
if (appNode) {
  appNode.style.display = 'block'
  if (!isDesktopSkin()) {
    document.body.style.height = 'unset'
    appNode.style.height = 'unset'
  }
}
