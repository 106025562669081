import { getCoreUrl } from '../api/apiURLUtil'
import { getSessionId } from '../cookies'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { sendAmplitudeEvent } from '../GAEvent'
import { scrollToNextPost } from '../navigation/datingScrollUtil'
import { isUID } from '../isUID'
import { checkMySubscription } from '../subscriptionUtil'
import { getProfilePostId } from '../userUtil'

export function dislikeUser(uid){
  dislikePost(uid)
  const postId = getProfilePostId(uid)
  if (postId){
    dislikePost(postId)
  }
}

export function dislikePost(postId) {
  scrollToNextPost(postId)
  sendAction(postId, true)

  const itsUserId = isUID(postId)
  if (itsUserId && checkMySubscription(postId)) {
    callSequence('app.removeSub', { uid: postId })
  }

  sendAmplitudeEvent('dislike', { isUser: itsUserId })
}

export function cancelDislike(postId) {
  sendAction(postId, false)
}

export function isDislikedPost(postId) {
  const disliked = getState('dislikedPosts') || {}
  return disliked[postId]
}

async function sendAction(postId, dislike) {
  const disliked = getState('dislikedPosts') || {}
  if (dislike) {
    disliked[postId] = postId
  } else {
    delete disliked[postId]
  }

  if (!postId) {
    return
  }
///api/v1/users/dislikes/
  setStoreWrapper('dislikedPosts', disliked)

  const response = await fetch(getCoreUrl(isUID(postId) ? '/api/v1/users/dislike/' + postId : '/api/v1/dislikes/' + postId), {
    method: dislike ? 'POST' : 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    }
  }).catch(e => {
    //прогресс индикатор
    this.setState({ loadingMode: false })
    console.warn('catch error', e)
  })

  if (response && response.ok) {
    const respObj = await response.json()
    // console.log('rob')
  }
}
