import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import config from '@/config'

const { pushSettingsUrl } = config

export const sendPushSettings = ({ store, get }) => {
  const fd = new FormData()
  fd.append('sid', getSessionId())
  fd.append('settings', JSON.stringify(get(state`auth.pushSettings`)))

  fetch(pushSettingsUrl + 'push/settings', {
    method: 'POST',
    body: fd
  }).catch(e => console.warn('@@@ send push settings error:', e))
}
