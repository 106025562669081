import { push, shift, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { send } from '../../socket/actions'
import { MAX_SAVED_VIEWS } from '../../../utils/post/postViewUtil'
import { POSTRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'

export default [
  ({ props }) => ({
    postId: Number(props.postId)
  }),
  when(state`posts.viewedPhotos`, props`postId`, (viewedPhotos, postId) => !viewedPhotos.includes(postId)), {
    true: [
      // ({ props }) => ({
      //   params: { postId: props.postId },
      //   method: 'postView'
      // }),
      // send,
      ({ props }) =>{
        POSTRequest(getCoreUrl('/api/v1/posts/' + props.postId + '/view'), {
        }, (data) => {
          console.log(data)
        })
      },
      push(state`posts.viewedPhotos`, props`postId`),
      when(state`posts.viewedPhotos`, viewedPhotos => viewedPhotos.length > MAX_SAVED_VIEWS), {
        true: [
          shift(state`posts.viewedPhotos`)
        ],
        false: []
      },
    ],
    false: []
  }
]
