import { sendDialogMessage } from '../actions'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { panelsOnPop } from '../../app/actions'
import dialogMediaUpload from '../../upload/actions/dialogMediaUpload'
import useDialogsStore from '../../../zustand/useDialogsStore'

export default [
  ({ props }) => {
    const progress = getState('upload.uploadProgressFile') || {}
    progress[getState('upload.selectedFilename')] = 0
    setStoreWrapper('upload.uploadProgressFile', progress)
  },
  ({ props }) => (
    {
      uid: props.uid,
      cost: props.cost,
      mediaFileUpload: true,
      fileData: {
        cost: props.cost,
        mediaTypeVideo: getState('mediaTypeVideo'),
        selectedFile: getState('upload.selectedFile'),
        selectedFilename: getState('upload.selectedFilename'),
        selectedFileUrl: getState('upload.selectedFileUrl'),
        selectedPhotoOrientation: getState('upload.selectedPhotoOrientation')
      }
    }),
  panelsOnPop,
    ({ props })=>{
        useDialogsStore.getState().sendDialogMessage('', props.fileData)
    },
  dialogMediaUpload
]
