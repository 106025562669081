import { Component } from 'react'
import { bool, func, string, number } from 'prop-types'
import { connect } from '@cerebral/react'
import { getDurationStr } from '../utils'
import { getLocaleValue } from '../utils/localeUil'

class TimeLeftString extends Component {
  timer

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  render() {
    const timeLeft = this.props.endTime - Date.now()

    if (timeLeft < 1000) {
      if (this.props.endCallBack) {
        this.props.endCallBack()
      }
      return getLocaleValue(this.props.endStringKey || 'End')
    }
    let updateTime = 1000
    if (timeLeft > 3660000) {
      updateTime = 60000
    }
    this.timer = setTimeout(() => {
      this.forceUpdate()
    }, updateTime)

    if (!this.props.baseStringKey) {
      return getDurationStr(timeLeft, false, this.props.minSec)
    }
    return getLocaleValue(this.props.baseStringKey, { '{0}': getDurationStr(timeLeft, false, this.props.minSec) })
  }
}

TimeLeftString.propTypes = {
  baseStringKey: string,
  endCallBack: func,
  endStringKey: string,
  endTime: number,
  minSec: bool
}

export default connect({},
  TimeLeftString
)

