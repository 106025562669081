import { GETRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getState, setStoreWrapper } from '../StoreManager'
import { checkSelectedFeed } from '../../modules/filters/actions/feedUtil'
import { TYPE_MATCH } from '../../modules/posts/signals/getPosts'
import { NEW_FOLLOWER_PARAM, getInCollector, NEW_MATCH_PARAM, setInCollector } from '../../zustand/useMyStore'

export function loadMyNewContentCounters(){
  GETRequest(getCoreUrl('/api/v1/subscriptions/updates-counts'), (data)=> {
    // setStoreWrapper('auth.newFollowersCount', data.new_followers_no_match_count)
    setInCollector(NEW_FOLLOWER_PARAM, data.new_followers_no_match_count)
    setInCollector(NEW_MATCH_PARAM, data.new_match_count)
    // setStoreWrapper('auth.newMatchCount', data.new_match_count)
    // console.log(data)
  })
}

export function reduceFansCount(){
  const count = getInCollector(NEW_FOLLOWER_PARAM, 0)
  if (count > 0) {
    setInCollector(NEW_FOLLOWER_PARAM, count - 1)
  }
}

export function addNewMatchCounter(){
  if (checkSelectedFeed(TYPE_MATCH)) {
    return
  }
  setInCollector(NEW_MATCH_PARAM, getInCollector(NEW_MATCH_PARAM, 0) + 1)
}

export function addFollowerCount(){
  setStoreWrapper('auth.newFollowersCount', getState('auth.newFollowersCount', 0) + 1)
}
