export const logger = {
  debug: (label, message) => {
    console.group(label)
    console.debug(message)
    console.groupEnd()
  },
  info: (label, message) => {
    console.group(label)
    console.info(message)
    console.groupEnd()
  },
  warn: (label, message) => {
    console.group(label)
    console.warn(message)
    console.groupEnd()
  },
  error: (label, message) => {
    console.group(label)
    console.error(message)
    console.groupEnd()
  }
}
