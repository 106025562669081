import signals from './signals'

export default {
  state: {
    ageRating: 14,
    appId: null,
    twa_version: null,
    subject: 'videochat',
    hardAgeRating: 0,//рейтинг, который выcтавляется гет параметром в URL для встраивания iframe или открытия с мобильника
    oneSignalInited: false,
    oneSignalUserId: null,
    isPushEnabled: false,
    appRated: false,
    mobile: false, //в гет параметре пришел параметр мобайл, значит нужна версия без NSWF
    /*
    * 0 - show propmt button
    * 1 - show info button
    * 2 - hide button
    */
    notificationsButtonMode: 0,
    ban: null,
    banId: null,
    fromPush: null,
    route: '/',
    lastRoute: null,
    panelRoute: '',
    roomId: null,
    routesHistory: [],
    dialogUserId: null,
    lastRoomId: null,
    navBar: { hidden: false },
    roomSpectators: {},
    onlineAmount: 1,
    online: {},
    users: {},
    recentUsers: [],
    broadcastDescription: '',
    isReady: false,
    isInPrivate: null,
    isInPrivateWithVideo: false,
    privateThemeId: '',
    panels: [],
    paidRooms: {},
    uniqueEvents: {},
    shouldDestroyLastPanel: false,
    snackbar: { state: 'out', text: '', type: 'success' },
    urlGetVariables: '',
    warningAccepted: false,
    showWarning: false,
    eulaAccepted: false,
    showEula: false,
    uRedirectStatus: 'not_viewed',
    showSafariRedirect: true,
    hideTopBar: false,
    scrollToTop: false,
    animatedLikes: []
  },
  sequences: signals
}
