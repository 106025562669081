import { state } from 'cerebral'

export const delBuff = ({ props, store, get }) => {
  const buff = props.buff
  const user = get(state`app.users.${buff.toUid}`)
  if (!user || !user.multi_buffs) {
    return
  }

  delete user.multi_buffs[buff.cardType]
  store.set(`app.users.${buff.toUid}`, user)
}
