import { getAwardURL } from '../api/apiURLUtil'
import { getSessionId } from '../cookies'
import { getState, setStoreWrapper } from '../StoreManager'
import { getSettings } from '../settingsUtil'
import { imAdmin } from '../adminUtil'
import { FOR_EXP_AWARD, isVIPAward, prepareAwardConfig, TEST_AWARD } from './awardsConfig'
import { getMyUid } from '../my'
import { expToLVL, getExp } from '../levelUtil'
import { getUser } from '../userLoader'
import { isMe } from '../checks'
import isVip from '../isVip'

export const LOADING = 'loading'

export async function getAwardsProgress(uid) {
  if (!uid) {
    return
  }
  const awardProgress = getState('awardProgress') || {}
  if (awardProgress[uid]) {
    return
  }
  awardProgress[uid] = LOADING
  setStoreWrapper('awardProgress', awardProgress)
  const response = await fetch(getAwardURL('/api/v2/ratings?uids=' + uid), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    }
  }).catch(e => {
    console.error('getAwardsProgress', e)
    awardProgress[uid] = {}
    setStoreWrapper('awardProgress', awardProgress)
    prepareAwardConfig(true)
  })

  if (response && response.ok) {
    const respObj = await response.json()
    awardProgress[uid] = respObj.ratings[uid] || {}
    // awardProgress[uid]['top_monthly_local_4_2023'] = 100
    setStoreWrapper('awardProgress', awardProgress)
    prepareAwardConfig(true)
    return
  }
  console.error('getAwardsProgress')
  awardProgress[uid] = {}
  setStoreWrapper('awardProgress', awardProgress)

  prepareAwardConfig(isMe(uid))
}

export function clearAwardProgress(uid) {
  if (!isMe(uid)) {
    let awardProgress = getState('awardProgress') || {}
    delete awardProgress[uid]
    setStoreWrapper('awardProgress', awardProgress)
  }
}

export function updateAwardProgress(uid, awardId, ratings) {
  const awardProgress = getState('awardProgress') || {}
  const userRatings = awardProgress[uid] || {}
  userRatings[awardId] = ratings
  awardProgress[uid] = userRatings
  setStoreWrapper('awardProgress', awardProgress)
}

export function getAwardProgressString(awardId, uid) {
  if (!uid) {
    uid = getMyUid()
  }

  const awardsConfig = getSettings('awards') || {}
  const awardProgress = (getState('awardProgress') || {})[uid] || {}
  const awardInfo = awardsConfig[awardId]

  if (!awardInfo || (awardInfo.visible === TEST_AWARD && !imAdmin())) {
    return null
  }

  if (!awardInfo.rating || awardInfo.vip || awardInfo.paid) {
    return null
  }

  if (awardProgress[awardInfo.action] >= awardInfo.rating) {
    return null
  }
//awardProgress[awardInfo.action] || 0
  return expToLVL(awardInfo.rating) //(awardInfo.rating - (getExp(getMyUid()))).toLocaleString()
}

export function isAwardUnlocked(awardId, uid) {
  if (!uid) {
    uid = getMyUid()
  }

  if (isVIPAward(awardId)) {
    return isVip(uid)
  }

  const awardsConfig = getSettings('awards') || {}
  const awardProgress = (getState('awardProgress') || {})[uid] || {}
  const awardInfo = awardsConfig[awardId]

  if (!awardInfo || (awardInfo.visible === TEST_AWARD && !imAdmin())) {
    return false
  }

  if (!awardInfo.rating) {
    return true
  }
  if (awardInfo.action === FOR_EXP_AWARD) {
    return getExp(uid) >= awardInfo.rating
  }

  if (awardProgress[awardInfo.action] >= awardInfo.rating) {
    return true
  }

  return false
}

export function getAwardCost(awardId) {
  const awardsConfig = getSettings('awards') || {}
  const awardInfo = awardsConfig[awardId]
  return (awardInfo && awardInfo.rating) || 0
}

const DEFAULT_SLOTS = 5

export function getMyAwardSlotCount() {
  const exp = getExp(getMyUid())
  const slotConfig = getSettings('awards_slots_for_exp') || []
  for (let i = 0; i < slotConfig.length; i++) {
    if (slotConfig[i] > exp) {
      return DEFAULT_SLOTS + i
    }
  }

  return DEFAULT_SLOTS + slotConfig.length
}

export function isAwardInSlot(awardId) {
  const user = getUser(getMyUid())
  let inSlot = -1
  if (user) {
    if (user.awards) {
      Object.keys(user.awards).map(slot => {
        if (user.awards[slot] && user.awards[slot].award && user.awards[slot].award === awardId) {
          inSlot = slot
          return inSlot
        }
      })
    }
  }

  return inSlot
}
