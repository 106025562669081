import signals from './signals'

export default {
  state: {
    name: '',
    sex: true,
    hiddenLocation: false,
    coins: 0,
    receivedCoins: 0,
    purchasedCoins: 0,
    usdBalance: 0,
    dob: '',
    photo: null,
    avatar: null,
    lang: null,
    fid: null,
    fans: {},//юзеры, которые подпиcаны на тебя
    paidFans: {},//юзеры, которые подпиcаны на тебя платно
    subs: {},//юзеры, на которых ты подпиcалcя
    subsLoadStatus: '',
    fansLoadStatus: '',
    paidFansLoadStatus: '',
    subsCount: 0,
    fansCount: 0,
    subscriptionSettings: {},
    loginCount: 0,
    experience: 0,
    pushSettings: {
      goLive: true,
      goPrivate: true,
      privateMsg: true,
      newPost: true,
      newComment: true,
      privateOffer: true,
    },
    isLoggedIn: false,
    isAuthComplete: true,
    isSuccessful: false,
    isPasswordSet: null,
    token: null,
    fromParam: null,
    sessionId: null,
    uid: null,
    geoIpInfo: null,
    email: null,
    partnerId: null,
    mrtigraRefer: null,
    pricesRate: 1,
    changeEmailForm: {
      email: '',
      password: ''
    },
    changePasswordForm: {
      oldPassword: '',
      password: ''
    },
    loginForm: {
      username: '',
      password: ''
    },
    loginFormErrors: {
      username: null,
      password: null
    },
    resetPasswordForm: {
      email: '',
      password: ''
    },
    interestsTags: []
  },
  sequences: signals
}
