import { state } from 'cerebral'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import config from '@/config'
import { LIVE_PERMISSION } from '../../../utils/subscriptionUtil'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import { addBuff } from '../../buffs/actions'
import { AFTER_PRIVATE_DELAY } from '../../buffs'
import { callSequence, getState, setStoreWrapper } from '../../../utils/StoreManager'
import { isMe } from '../../../utils'

const { soundUrl } = config

/*{
  "method": "directCallEnd", 
  "params": {
    "viewer":"536eda98cb83d00e2f87bd551b742d4c",
    "model":"b58493b94b0e8615335bc7ce0b24cb60"
  }
}*/

export default [
  async ({ props, get, store }) => {
    const { viewer, model } = props.params
    if (isMe(model) && getState('endStreamAfterCall') === viewer) {
      setStoreWrapper('endStreamAfterCall', null)
      callSequence('room.stopStream')
    }

    if (isMe(viewer) || isMe(model)) {
      setStoreWrapper('callAccepted', null)
    }

    store.unset(`posters.privates.${model}`)
    store.unset(`posters.raw.${viewer}`)

    store.unset(`app.paidRooms.${model}`)

    addBuff({
      props: {
        buff: {
          toUid: model,
          startTime: Date.now(),
          cardType: AFTER_PRIVATE_DELAY
        }
      }, get, store
    })

    const myUid = get(state`auth.uid`)
    if (myUid !== viewer && myUid !== model) {
      return
    }

    store.set('room.companionUid', null)
    store.set('app.isInPrivate', null)
    store.set('app.isInPrivateWithVideo', false)

    if (myUid !== model) {
      store.set('room.streamInRoom', null)
      return
    }

    const broadcastingTime = (Date.now() - get(state`room.broadcastStartTime`)) / 1000
    if (broadcastingTime > 1 && broadcastingTime < 20000) {
      const subscription_type = (get(state`auth.subscriptionSettings.${LIVE_PERMISSION}`) || '-1').toLowerCase()
      const amData = {
        subscription_type,
        broadcast_duration: Math.round(broadcastingTime),
        broadcast_type: 'private',
        end_reason: 'user',
        content_rating: get(state`auth.lastModerRating`) || getAgeRatingByUid(myUid),
        topic_id: get(state`app.privateThemeId`)
      }
      sendAmplitudeEvent('topic_duration_model', amData)
    }
    store.set('room.broadcastStartTime', Date.now())

    try {
      const s = new Audio(soundUrl + 'private_end.mp3')
      await s.play()
    } catch (e) {
      console.log('sound not allowed')
    }
  }
]
