import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'
import { handlePanelRoute } from '../actions/handlePanelRoute'

export default [
  () => ({ panelRoute: 'coins' }),
  dispatchUrlChangeToParent,
  handleRoute,
  handlePanelRoute
]
