import panelsOnPop from '../../app/signals/panelsOnPop'
import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { send } from '../../socket/actions'
import { getLangCode, getLocaleRequest } from '../actions'
import { loadBroadcastNotifications } from '../../chat/actions'
import { POSTRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'
import { getState } from '../../../utils/StoreManager'

export default [
  panelsOnPop,
  ({ router, props }) => {
    const pathname = location.pathname.split('/')
    pathname[1] = props.locale
    router.goTo(`${pathname.join('/')}${location.search}`)
  },
  set(state`intl.lang`, props`locale`),
  getLangCode,
  () => ({ useCache: false }),
  getLocaleRequest, {
    success: [],
  },
  // set(state`chat.broadcastDialogs`, {}),
  // loadBroadcastNotifications,
  ({ props }) => {
    POSTRequest(getCoreUrl('/api/v1/users/lang'), {
      lang: props.locale
    }, (data)=>{
      console.log(data.result)
    })
  },
    //TODO дублирование осталось, что бы скидывать кеш в вовзе, когда авторизация перенесется можно удалить
  // ({ props }) => ({ params: { lang: props.locale, country: null }, method: 'setLangAndCountry' }),
  // send,
]
