import { create } from 'zustand'
import { isBuffActive } from '../utils/buffUtils'
import { PROMOTION } from '../modules/buffs'
import { getRoomSpectatorsCount } from '../modules/socket/actions'
import { posterVisibleStream } from '../utils/ageRating/ageRatingUtil'
import usePostersStore from './usePostersStore'

const useLivesStore = create(set => ({
    allLives: {},
    postersVisibleLives: [],
    setAllLives: lives => {
        for (const uid in lives) {
            lives[uid].sortRate = (isBuffActive(uid, PROMOTION) ? 100 : 0) + getRoomSpectatorsCount(uid)
        }
        //запишем все лайвы как они есть
        set({ allLives: lives })
        useLivesStore.getState().setPostersVisibleLives(lives)
    },
    setPostersVisibleLives: lives => {
        //проверим, какие стримы должны быть видны, а какие нет и запишем их
        let visible = []
        for (const uid in lives) {
            if (posterVisibleStream(uid)) {
                visible.push(uid)
            }
        }
        visible = visible.sort((a, b) =>  lives[b].sortRate - lives[a].sortRate)
        set({ postersVisibleLives: visible })
        usePostersStore.getState().setLivesInPosters(visible)
    },
}))

export function setAllLives(lives) {
    useLivesStore.getState().setAllLives(lives)
}

export const useUserLive = uid => {
    return useLivesStore.getState().allLives[uid]
}

export function getPostersVisibleLives() {
    return useLivesStore.getState().postersVisibleLives
}

export const isUserLiveVisible = uid => {
    return useLivesStore.getState().postersVisibleLives.includes(uid)
}

export default useLivesStore
