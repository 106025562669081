import onclose from './onclose'
import onConnect from './onConnect'
import onmessage from './onmessage'
import onopen from './onopen'
import onerror from './onerror'
import send from './send'

export default {
  onmessage,
  onConnect,
  onclose,
  onopen,
  onerror,
  send
}
