import { getState, setStoreWrapper } from '../../../utils/StoreManager'

let xhr = null

export default ({ store, get, props }) => {
  const { url, blob, headerValue, body, callback, onError, selectedFilename } = props
  let formData = null
  xhr = new XMLHttpRequest()

  xhr.open('POST', url, true)

  console.log('send file', url)
  if (headerValue) {
    xhr.setRequestHeader('vb-params', encodeURI(JSON.stringify(headerValue)))
  }
  if (body) {
    formData = new FormData()
    for (const idx in body) {
      formData.append(idx, body[idx])
    }
  }
  xhr.onerror = e => {
    if (onError) {
      if (selectedFilename) {
        const progress = getState('upload.uploadProgressFile') || {}
        progress[selectedFilename] = 0
        setStoreWrapper('upload.uploadProgressFile', progress)
      }
      onError(e)
    }
  }
  xhr.onreadystatechange = () => {
    // console.log('xhr.status =', xhr.status, 'xhr.readyState =', xhr.readyState, xhr.responseText)

    if (selectedFilename) {
      const progress = getState('upload.uploadProgressFile') || {}
      progress[selectedFilename] = 0
      setStoreWrapper('upload.uploadProgressFile', progress)
    }

    setStoreWrapper('upload.uploadProgress', -1)

    if (xhr && xhr.status === 200 && xhr.readyState === 4) {
      // console.log('responseType', xhr.responseType, 'resp:', xhr.responseText)
      let resp = ''

      try {
        resp = JSON.parse(xhr.responseText)
      } catch (e) {
        resp = xhr.responseText || ''
      }

      if (callback) {
        if (selectedFilename) {
          const progress = getState('upload.uploadProgressFile') || {}
          progress[selectedFilename] = 100
          setStoreWrapper('upload.uploadProgressFile', progress)
        }
        callback(resp)
      }
      xhr = null
    }
  }
  xhr.upload.onprogress = e => {
    const currentProgress = Math.round(99 * e.loaded / e.total)
    console.log('upload.uploadProgress !!! ' + currentProgress + '%' + ' ' + e.loaded + ' ' + e.total)
    if (selectedFilename) {
      const progress = getState('upload.uploadProgressFile') || {}
      progress[selectedFilename] = currentProgress
      setStoreWrapper('upload.uploadProgressFile', progress)
    }
    setStoreWrapper('upload.uploadProgress', currentProgress)
  }

  if (formData) {
    xhr.send(formData)
  } else {
    xhr.send(blob)
  }

  setStoreWrapper('upload.uploadProgress', 0)
}

export const cancelUpload = () => {
  if (xhr) {
    xhr.abort()
    xhr = null
  }
}
