export default [
  ({ props, store }) => {

    if (typeof props.avaX1 === 'number') {
      store.set('upload.avaX1', Math.max(0, props.avaX1))
    }
    if (typeof props.avaX2 === 'number') {
      store.set('upload.avaX2', Math.max(0, props.avaX2))
    }
    if (typeof props.avaY1 === 'number') {
      store.set('upload.avaY1', Math.max(0, props.avaY1))
    }
    if (typeof props.avaY2 === 'number') {
      store.set('upload.avaY2', Math.max(0, props.avaY2))
    }
    if (typeof props.avaW === 'number') {
      store.set('upload.avaW', Math.max(0, props.avaW))
    }
    if (typeof props.avaH === 'number') {
      store.set('upload.avaH', Math.max(0, props.avaH))
    }
  }
]
