import signals from './signals'

export default {
  state: {
    raw: {},
    privates: {},
    moderFilter: 'unchecked',
    typeModerFilter: 'interest',
    scrollPos: 0,
    postersCounter: 0,
  },
  sequences: signals
}
