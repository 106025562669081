import { state } from 'cerebral'

export default [
  ({ store, get }) => {
    console.log('OneSignal inited')
    const notificationsButtonMode = get(state`app.notificationsButtonMode`)
    store.set('app.oneSignalInited', true)
    if (notificationsButtonMode === 1) store.set('app.notificationsButtonMode', 2)
  }
]
