import { set, when } from 'cerebral/factories'
import { props, state } from 'cerebral'

export default [
  set(state`upload.coverSelection`, true),
  when(props`videoUrl`), {
    true: [
      set(state`upload.selectedFileUrl`, props`videoUrl`),
    ],
    false: []
  },
]
