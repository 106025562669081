import { getSessionId } from '../cookies'

export async function DELETERequest(url, params, callback, errorCB) {
  const sid = getSessionId()
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': sid
    },
    body: JSON.stringify(params)
  }).catch(e => {
    if (errorCB) {
      errorCB()
    }
  })

  if (response && response.ok) {
    const result = await response.json()
    if (callback) {
      callback(result)
    }
    return
  }
  if (errorCB) {
    errorCB()
  }
}

export async function PUTRequest(url, params, callback, errorCB) {
  const sid = getSessionId()
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': sid
    },
    body: JSON.stringify(params)
  }).catch(e => {
    if (errorCB) {
      errorCB()
    }
  })

  if (response && response.ok) {
    const result = await response.json()
    if (callback) {
      callback(result)
    }
    return
  }
  if (errorCB) {
    errorCB()
  }
}

export async function POSTRequest(url, params, callback, errorCB, noSid, fromData) {
  const sid = getSessionId()
  const response = await fetch(url, {
    method: 'POST',
    headers: (noSid || !sid) ? {
      'Content-Type': 'application/json'
    } : {
      'Content-Type': 'application/json',
      'ft-sid': sid
    },
    body: fromData ?  params : JSON.stringify(params)
  }).catch(e => {
    if (errorCB) {
      errorCB()
    }
  })

  if (response) {
    try {
      const result = await response.json()
      if (response.ok){
        if (callback) {
          callback(result)
        }
      } else {
        if (errorCB) {
          errorCB(result)
        }
      }
      return
    } catch (e) {
      if (errorCB) {
        errorCB()
      }
      return
    }
  }
  if (errorCB) {
    errorCB()
  }
}

export async function GETRequest(url, callback, errorCB, noSID) {
  const data = {
    method: 'GET'
  }
  if (!noSID) {
    data.headers = {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    }
  }
  const response = await fetch(url, data).catch(e => {
    if (errorCB) {
      errorCB()
    }
  })

  if (response && response.ok) {
    const result = await response.json()
    if (callback) {
      callback(result)
    }
    return
  }
  if (errorCB) {
    errorCB()
  }
}

