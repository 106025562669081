import controller from '@/controller'
import { SUPPORT_UID } from '../modules/app/signals/openSupport'

//проверяет админ ли я
export const imAdmin = () => {
 return controller.getState('auth.admin') || controller.getState('auth.superAdmin')
}

//проверяет супер админ ли я
export const imSuperAdmin = () => {
  return  controller.getState('auth.superAdmin')
}

//проверяет админ ли я
// export const checkAdmin = (uid) => {
//   const user = getUser(uid)
//   if (user){
//     return true
//   }
// }

export const isSupport = (uid) => {
  return uid === SUPPORT_UID
}

