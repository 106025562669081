import { state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { getSessionId } from '@/utils'
import removeNulls from '@/utils/removeNulls'
import { checkExtraFiles } from './createPost'
import controller from '@/controller'
import { postersRoute } from '@/utils/routerHelpers'
import config from '@/config'
import { showStartPanel } from '../../../components/Panels/types'
import createPanel from '../../app/signals/createPanel'
import { saveMetadata } from '../../room/signals/sendCreateBroadcast'
import sendCreateAvatar from '../actions/sendCreateAvatar'
import { getMyUser, UpdateMyUser, updateUser } from '../../../utils/userLoader'
import { postEditEvent } from '../../../utils/amplitude/postEvents'
import { clearPostCreatedData, isAvatarChanged, setAvatarUpdated } from '../../../utils/post/profileUtil'
import { isModeration } from '../../../utils/moderationUtil'

const { ftCoreUrl, postUploadUrl } = config

export default [
  set(state`upload.uploadProgress`, 999),
  removeNulls,

  async ({ get, props, store }) => {
    const myUid = get(state`auth.uid`)
    const postId = get(state`posts.selectedPostId`)
    const postData = get(state`posts.postsData.${postId}`)
    const postOwnerUid = postData.uid
    const postExtraFiles = get(state`posts.postsData.${postId}.mediaFilesIds.extra_files_ids`) || []
    let extraFiles = get(state`upload.extraFiles`)

    if (postExtraFiles.length > 0) {
      const filesResp = await fetch(`${postUploadUrl}/api/v1/media-file/${postExtraFiles}/?sid=${getSessionId()}`)
      const filesData = await filesResp.json()
      const ourFormatExtraFiles = []
      for (const file_id in filesData.result) {
        const fileInfo = filesData.result[file_id]
        const ourFormatFileInfo = { file_id }
        if (fileInfo && fileInfo.images) {
          fileInfo.images.forEach(
            imageInfo => ourFormatFileInfo[imageInfo.image_type] = imageInfo.url
          )
        }
        ourFormatExtraFiles.push(ourFormatFileInfo)
      }
      extraFiles = extraFiles.concat(ourFormatExtraFiles)
    }

    const editData = { sid: getSessionId(), ...props }

    if (extraFiles.length > 0) {
      editData.extra_files_ids = checkExtraFiles(extraFiles, get(state`upload.description`) || '')
    }

    if (get(state`auth.admin`) || get(state`auth.superAdmin`)) {
      editData.status = get(state`upload.moderPostStatus`)
      editData.categories = []
    }

    let mainFileId = get(state`upload.mainFileId`)
    if (!mainFileId) {
      mainFileId = get(state`posts.postsData.${postId}.mediaFilesIds.main_file_id`)
    }

    if (mainFileId) {
      editData.main_file_id = mainFileId
      if (editData.post_kind === 'postProfile' && postData && postData.uid === myUid && isAvatarChanged()) {
        const { avatarUrl } = await sendCreateAvatar({ get, store, mainFileId })
        postData.avatar = avatarUrl
        const my = getMyUser()
        my.profile_post = postData
        updateUser(myUid, my)
        store.set('auth.profile_post', postData)

        store.set('auth.profilePostTags', props.tags)
        // setFemale(props.tags.indexOf(FEMALE) !== -1)

        //скинуть кеш постов
        controller.getSequence('posts.resetPosts')()

        UpdateMyUser({ store })
      }

      if (editData.postType === 1) {
        await fetch(`${postUploadUrl}/api/v1/media-file/create-screenshot`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            cover_time: get(state`upload.coverTime`),
            file_id: mainFileId,
            sid: getSessionId()
          })
        })
      }
    }

    // if (postOwnerUid && postOwnerUid !== myUid) {
    //   const routeTo = controller.getSequence('app.prepareAndRouteTo')
    //   routeTo({ url: postersRoute })
    // }

    try {
      await fetch(`${ftCoreUrl}/api/v1/posts/${postId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editData)
      })
      if (isModeration) {
        // store.unset(`posts.postsData.${postId}`)
      } else {
        store.unset(`posts.postsData.${postId}`)
      }


      const getPost = controller.getSequence('posts.getPost')
      getPost({ postId: postId, isSpecial: true })

      postEditEvent('success')
    } catch (e) {
      postEditEvent('error')
      console.log('@@@ edit post error', e)
    }

    if (!isModeration) {
      setAvatarUpdated()
    }
    clearPostCreatedData()
  },
  set(state`upload.uploadProgress`, -1)
]
