import checkEmail from './checkEmail'
import changeEmail from './changeEmail'
import formOnChange from './formOnChange'
import logOut from './logOut'
import logIn from './logIn'
import checkPassword from './checkPassword'
import changePassword from './changePassword'
import checkEmailBeforeResetPassword from './checkEmailBeforeResetPassword'
import resetPassword from './resetPassword'
import getPushSettings from './getPushSettings'
import sendPushSettings from './sendPushSettings'
import changePushSettings from './changePushSettings'
import init from './init'
import clearLoginFormErrors from './clearLoginFormErrors'
import loadNextFans from './loadNextFans'
import loadNextSubs from './loadNextSubs'
import loadNextOnlineAuthors from './loadNextOnlineAuthors'
import setSubs from './setSubs'
import guestAuth from './guestAuth'
import getAuthInfo from './getAuthInfo'
import loadSpam from './loadSpam'

export default {
    init,
    checkEmail,
    changeEmail,
    logOut,
    logIn,
    setSubs,
    loadNextFans,
    loadNextSubs,
    loadNextOnlineAuthors,
    getPushSettings,
    clearLoginFormErrors,
    formOnChange,
    checkPassword,
    changePassword,
    resetPassword,
    sendPushSettings,
    changePushSettings,
    checkEmailBeforeResetPassword,
    guestAuth,
    getAuthInfo,
    loadSpam
}
