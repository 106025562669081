import signals from './signals'

export default {
  state: {
    inputH: 64,
    chatOpen: false,
    unreadComments: [],
    chatRoomId: '',
    toId: '',
    roomsMessages: {},
    dialogs: null,
    dialogsPage: 0,
    otherDialogs: {},
    dialogsLoaded: false,
    selectedMessage: null,
    broadcastDialogs: {},
    readedNotificationsTextKeys: {}
  },
  sequences: signals
}
