import { onclose } from '../actions'
import { connectionLostPanel } from '../../../components/Panels/types'
import createPanelWithoutUrl from '../../app/signals/createPanelWithoutUrl'

export default [
  onclose, {
    connectionLostPanelIsOpen: [],
    loggedFromAnotherPlacePanelIsOpen: [],
    needToCreateConnectionLostPanel: [
      () => ({ options: connectionLostPanel.options }),
      createPanelWithoutUrl
    ]
  }
]
