import sendBuff from '../../buffs/signals/sendBuff'
import { ROOM_CHAT_BAN } from '../../buffs'

export default [
  ({ props }) => ({
    uid: props.from,
    buffId: ROOM_CHAT_BAN,
    method: 'POST'
  }),
  sendBuff
]
