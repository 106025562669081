import { showSnackbarNew } from '../../modules/app/factories'
import { getLocaleValue } from '../localeUil'

const minPicSize = 480

export function checkPhotoSize(url, callback) {
  let img = document.createElement('img')
  img.onload = function () {
    if (img.width >= minPicSize && img.height >= minPicSize) {
      callback()
    } else {
      showSnackbarNew(getLocaleValue('too_small_picture'), 5000, 'error')
    }
  }
  img.src = url
}
