import { getSettings } from './settingsUtil'

const getPrivateCost = (withCam, themeId, audioOnly) => {
  const cost = (getSettings('direct_calls') || {})[themeId]
  if (!cost) {
    return 0
  }
  if (audioOnly) {
    return cost.mic || 0
  }

  if (withCam) {
    return cost.cam || 0
  }

  return cost.simple || 0
}

export default getPrivateCost
