import { getState, setStoreWrapper } from '../StoreManager'
import {
  CLEAR_BONUS_HP,
  CLEAR_DEBUFF_CARD,
  GAME_CARD_MAGIC,
  getStrengthWithBuffs,
  isBossDeBuff,
  prepareAndSetGameField
} from './cardGameUtil'
import { getCurrentRoomId } from '../navigation/navigationUtil'

const TICK_DURATION = 1500

const ANIMATE_DURATION = 800

export const ANIMATE_HEAL = 'heal'
export const ANIMATE_FIREBALL = 'fireball'
export const ANIMATE_MASS_MAGIK = 'massMagic'
export const ANIMATE_MASS_HIT = 'mass_hit'
export const ANIMATE_HIT = 'hit'

let tickInterval

export function gameStart() {
  // if (tickInterval){
  //   clearInterval(tickInterval)
  // }
  // tickInterval = setInterval(tickAction, TICK_DURATION)
}

export function gameFinnish() {
  if (tickInterval) {
    clearInterval(tickInterval)
  }
}

//возьмем объект боса и применим к нему одну карточку игры
function tickAction() {
  const boss = getState('gameBoss')
  if (!boss) {
    return
  }

  const data = getState('gameField')
  let gameCard
  if (data) {
    gameCard = data[0]
  }

  if (!gameCard) {
    return
  }

  if (gameCard.card_id === ANIMATE_FIREBALL) {
    setStoreWrapper('animateBoss', ANIMATE_FIREBALL)
    return
  } else if (gameCard.card_id === ANIMATE_MASS_HIT) {
    setStoreWrapper('animateBoss', ANIMATE_MASS_HIT)
    return
  }

  if ((gameCard.card_type === GAME_CARD_MAGIC && isBossDeBuff(gameCard.card_id)) || gameCard.card_id === CLEAR_DEBUFF_CARD || gameCard.card_id === CLEAR_BONUS_HP) {
    setStoreWrapper('animateBoss', ANIMATE_MASS_MAGIK)
    return
  }
  if (getStrengthWithBuffs(gameCard) < 0) {
    setStoreWrapper('animateBoss', ANIMATE_HEAL)
    return
  }
  setStoreWrapper('animateBoss', ANIMATE_HIT)

  // setTimeout(()=>{
  //   setStoreWrapper('animateBoss', false)
  // }, ANIMATE_DURATION)
  // }
  // setStoreWrapper('gameBoss', boss)
  // if (boss.hp === 0){
  //   gameFinnish()
  // }
}

export function syncGame(game, force) {
  if (game.room_id !== getCurrentRoomId()) {
    return
  }
  setStoreWrapper('winnerInfo', null)
  tickAction()
  // gameStart()
  // setStoreWrapper('animateBoss', true)
  if (force) {
    setStoreWrapper('gameBoss', game.boss)
    prepareAndSetGameField(game.cards)
  } else {
    setTimeout(() => {
      setStoreWrapper('gameBoss', game.boss)
      prepareAndSetGameField(game.cards)
    }, ANIMATE_DURATION)
  }
}
