//добавляет кнопки которые должны быть в фильтре всегда
import {
  TYPE_CYBER,
  TYPE_FANS,
  TYPE_I_LIKE,
  TYPE_LIVE_ONLY,
  TYPE_MATCH,
  TYPE_ONLINE,
  TYPE_REAL_DATING,
  TYPE_SUBSCRIPTIONS,
  TYPE_TOP_M,
  TYPE_TOP_M_L,
  TYPE_VIDEO_ONLY
} from '../modules/posts/signals/getPosts'
import { isRestrictedMode } from './api/PWAUtil'
import { isAIDatingApp, isNoCyberApp, isNoDatingInConfig } from './appConfigUtil'


export const setDefaultFilters = filters => {
  if (!filters) {
    filters = []
  }
  let hasSubButton
  //если фильтры пустые - добавим дефолтную группу
  if (filters.length === 0) {
    filters.push({ type: [] })
  } else {
    //проверим, есть ли кнопка "подписки"
    filters.forEach(filterOb => {
      if (hasSubButton) {
        return
      }
      Object.keys(filterOb).forEach(key => {
        if (hasSubButton) {
          return
        }
        filterOb[key].forEach(button => {
          if (typeof button !== 'number' && button.id === 'subscriptions') {
            hasSubButton = true
            return
          }
        })
      })
    })
  }

  //добавим дефолтные фильтры в первую группу
  Object.keys(filters[0]).forEach(key => {
    // filters[0][key].push({
    //   'localeKey': 'locale_filter',
    //   'id': 'locale_filter',
    //   'priority': 100
    // })

    if (isRestrictedMode()) {
      filters[0][key].push({
        'localeKey': 'online_status',
        'id': TYPE_ONLINE,
        'priority': 100
      })

      filters[0][key].push({
        'localeKey': 'fans',
        'id': TYPE_FANS,
        'priority': 100
      })

      filters[0][key].push({
        'localeKey': 'top_users_month',
        'id': TYPE_TOP_M,
        'priority': 100
      })

      filters[0][key].push({
        'localeKey': 'locale_top',
        'id': TYPE_TOP_M_L,
        'priority': 100
      })
      return
    }
    filters[0][key].push({
      'localeKey': 'VIP',
      'id': 'vip_filter',
      'priority': 100
    })

    // filters[0][key].push({
    //   'localeKey': 'filter_videochat_dating',
    //   'id': TYPE_DATING,
    //   'priority': 100
    // })

    filters[0][key].push({
      'localeKey': 'sbscr_my_sbscr',
      'id': TYPE_I_LIKE,
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'short_dating',
      'id': TYPE_REAL_DATING,
      'priority': 100
    })

    // filters[0][key].push({
    //   'localeKey': 'post_kind_videochat_video',
    //   'id': TYPE_VIDEO_ONLY,
    //   'priority': 100
    // })

    filters[0][key].push({
      'localeKey': 'fans',
      'id': TYPE_FANS,
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'dating_matches',
      'id': TYPE_MATCH,
      'priority': 100
    })

    // filters[0][key].push({
    //   'localeKey': 'online_status',
    //   'id': TYPE_ONLINE,
    //   'priority': 100
    // })

    // filters[0][key].push({
    //   'localeKey': 'chats_filter',
    //   'id': 'chat_only',
    //   'priority': 100
    // })

    filters[0][key].push({
      'localeKey': 'sponsors',
      'id': 'sponsors',
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'top_users_month',
      'id': TYPE_TOP_M,
      'priority': 100
    })
    // filters[0][key].push({
    //   'localeKey': 'top_users_week',
    //   'id': TYPE_TOP_W,
    //   'priority': 100
    // })
    filters[0][key].push({
      'localeKey': 'locale_top',
      'id': TYPE_TOP_M_L,
      'priority': 100
    })
    return
  })

  //если не было подписок, добавим их
  if (!hasSubButton) {
    filters.push({
      subscriptions: [{
        localeKey: 'subscriptions',
        id: 'subscriptions',
        'showTopBar': true,
        priority: 1199
      }]
    })
  }
  return filters
}

export function getBaseFilters() {
  if (isNoDatingInConfig()) {
    return [
      {
        localeKey: TYPE_SUBSCRIPTIONS,
        id: TYPE_SUBSCRIPTIONS,
        icon: 'icon-flirty-like'
        // ,
        // dating: true
      },
      {
        'localeKey': TYPE_LIVE_ONLY,
        'id': TYPE_LIVE_ONLY,
        'priority': 100
      },
      // {
      //   'localeKey': 'short_dating',
      //   'id': TYPE_REAL_DATING,
      //   dating: true
      // },
      // {
      //   'localeKey': 'dating_matches',
      //   'id': TYPE_MATCH,
      //   dating: true
      // },
      {
        'localeKey': 'VIP',
        'id': 'vip_filter'
      },
      {
        'localeKey': 'sbscr_my_sbscr',
        'id': TYPE_I_LIKE,
        dating: true
      },
      // {
      //   'localeKey': 'post_kind_videochat_video',
      //   'id': TYPE_VIDEO_ONLY,
      //   'priority': 100
      // },
      {
        'localeKey': 'sponsors',
        'id': 'sponsors',
        dating: true
      },
      {
        'localeKey': 'top_users_month',
        'id': TYPE_TOP_M
      },
      {
        'localeKey': 'locale_top',
        'id': TYPE_TOP_M_L
      }
    ]
  }

  const filters = [
    {
      localeKey: TYPE_SUBSCRIPTIONS,
      id: TYPE_SUBSCRIPTIONS,
      icon: 'icon-flirty-like'
      // ,
      // dating: true
    },
    {
      'localeKey': TYPE_LIVE_ONLY,
      'id': TYPE_LIVE_ONLY,
      'priority': 100
    },
    {
      'localeKey': 'ai_doll_btn',
      'id': TYPE_CYBER,
      dating: true,
      className: 'buttonPinkText'
    },
    {
      'localeKey': 'short_dating',
      'id': TYPE_REAL_DATING,
      dating: true
    },
    {
      'localeKey': 'fans',
      'id': TYPE_FANS,
      dating: true
    },
    {
      'localeKey': 'dating_matches',
      'id': TYPE_MATCH,
      dating: true
    },
    {
      'localeKey': 'sbscr_my_sbscr',
      'id': TYPE_I_LIKE,
      dating: true
    },
    {
      'localeKey': 'VIP',
      'id': 'vip_filter'
    },
    // {
    //   'localeKey': 'post_kind_videochat_video',
    //   'id': TYPE_VIDEO_ONLY,
    //   'priority': 100
    // },
    {
      'localeKey': 'sponsors',
      'id': 'sponsors',
      dating: true
    },
    {
      'localeKey': 'top_users_month',
      'id': TYPE_TOP_M
    },
    {
      'localeKey': 'locale_top',
      'id': TYPE_TOP_M_L
    }
  ]
  if (isNoCyberApp()) {
    return filters.filter(button => button.id !== TYPE_CYBER)
  }
  return filters
}

export function getDatingFilters() {
  if (isAIDatingApp()) {
    return [{
      'localeKey': 'ai_doll_btn',
      'id': TYPE_CYBER,
      dating: true,
      className: 'buttonPinkText'
    }]
  }
  let filtered = getBaseFilters().filter(button => button.dating)
  if (isNoCyberApp()) {
    filtered = filtered.filter(button => button.id !== TYPE_CYBER)
  }
  return filtered
}
