import config from '@/config'
import controller from '@/controller'
import { getAppId, isTWA } from '@/utils/twa'
import { isPartnerDomain } from '@/utils/urlUtils'
import is from 'is_js'
import { isWSSCDN } from './urlUtils'
import { callSequence } from './StoreManager'
import { isIOSPWA } from './api/PWAUtil'
import { getGen, getPlatformForConnect } from './twa'

let instance = null

const gen = 1001

const fmchat = 'com.flirty' + 'mania.js'

const getConnectParams = () => {
  const iosPF = isIOSPWA()
  if (isTWA()) {
    console.log('!!!PLAFTORM ' + getPlatformForConnect())
    return {
      platform: isIOSPWA() ? 'ios' : 'and',
      appId: getAppId(),
      appSource: getAppId(),
      gen: getGen()
    }
  }

  if (isPartnerDomain()) {
    const url = new URL(location.href)
    const ageRating = url.searchParams ? url.searchParams.get('ageRating') : 14
    return {
      platform: 'js',
      appId: fmchat,
      appSource: ageRating < 15 ? 'flirtymania.com' : 'flirtymania.plus',
      gen
    }
  }

  if (!isDev && isProdEnv) {
    return {
      platform: 'js',
      appId: fmchat,
      appSource: document.location.host,
      gen
    }
  }

  return {
    platform: 'js',
    appId: fmchat,
    appSource: document.location.host !== 'localhost:3000' ? document.location.host : 'ulive.chat',
    //appSource: 'ulive.house',
    //appSource: 'ulive.games',
    //appSource: 'ulive.news',
    //appSource: 'trivia.live',
    //appSource: 'ulive.studio',
    //appSource: 'xxxstory.best',
    //appSource: 'flirtymania.plus',
    //appSource: 'flirty.chat',
    gen
  }
}

let secondWs

function getWSURL() {
  if (isWSSCDN()) {
    return config.wsUrlCDN
  }
  return (is.safari('>=15') || is.ios('>=15')) ? config.wsUrlProxy : config.wsUrl
}

export function sendToWS2(data, auth) {
  // try {
  //   if (auth){
  //     if (secondWs && secondWs.readyState !== undefined && secondWs.readyState === secondWs.OPEN && secondWs.readyState !== secondWs.CONNECTING){
  //       secondWs.send(data)
  //     } else {
  //       setTimeout(()=>{
  //         sendToWS2(data, auth)
  //       }, 500)
  //     }
  //     return
  //   }
  // if (!(secondWs && secondWs.readyState !== undefined && secondWs.readyState === secondWs.OPEN && secondWs.readyState !== secondWs.CONNECTING && getState('auth.isSuccessful'))) {
  //   return
  // }
  // secondWs.send(data)
  // } catch (e) {
  //   console.log('someerror sww 2', e)
  // }
}

export const Ws = () => {
  if (!instance) {
    const connectParams = getConnectParams()

    let subject = controller.getState('app.subject')
    if (new URL(location.href).searchParams) {
      subject = new URL(location.href).searchParams.get('subject')
    }

    if (subject) {
      connectParams.subject = subject
    }
    let url = getWSURL()

    url += '/?params=' + encodeURI(JSON.stringify(connectParams))

    instance = new WebSocket(url)
    if (!secondWs) {
      // secondWs = new WebSocket(config.secondWsUrl += '/?params=' + encodeURI(JSON.stringify(connectParams)))
      //  secondWs.onopen = () => {
      //   if (getState('tempAD')){
      //     sendToWS2(getState('tempAD'), true)
      //   }
      //  }
    }
  }

  return instance
}

export const createWsConnection = ({ onopen, onmessage, onclose, onerror }) => {
  if (instance) {
    instance.close()
  }
  instance = null

  const ws = Ws()
  ws.onopen = () => onopen()
  ws.onmessage = ({ data }) => onmessage({ data })
  ws.onclose = ({ wasClean, code, reason }) => onclose({ wasClean, code, reason })
  ws.onerror = ({ message }) => onerror({ message })
  if (process.env.NODE_ENV !== 'production') window.ws = ws
}

export function universalWssSend(method, params) {
  callSequence('socket.send', { method, params })
}
