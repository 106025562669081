import config from '@/config'
import { isTWA } from '@/utils/twa'
import { isPaymentAvailable } from '../../../utils/paymentUtil'

const { httpApiUrl } = config

export const getConfigRequest = async ({ path }) => {
  try {
    let urlParams = '/apifa/getpaymentsconfig?mode=fa&app=vb'
    if (!isPaymentAvailable()) {
      urlParams += '&type=crypto'
    }
    const response = await fetch(httpApiUrl + urlParams)
    if (!response.ok) {
      return path.error({ error: response.statusText })
    }
    const { result } = await response.json()

    return path.success({ result: saveShopItems(result) })
  } catch (error) {
    return path.error({ error })
  }
}

export function saveShopItems(result) {
  const minAmount = result[0].price,
    minPrice = result[0].modePrice
  return result.reduce((newResult, item, count) => {
    if (count > 0) {
      item.oldPrice = Math.round(item.price / (minAmount / minPrice) * 100) / 100
      item.discount = Math.round((1 - item.modePrice / item.oldPrice) * 100)
    }
    if (item.isCrypto && isTWA()) {
      return newResult
    }
    newResult.push(item)
    return newResult
  }, [])
}
