import controller from '@/controller'

const DEFAULT = 'need_more_coins'

export function canBuyCheck(cost, reason = '', messageKey = 'need_more_coins') {
  cost = cost || 0
  if (!cost) {
    return true
  }
  if (controller.getState('auth.coins') >= cost) {
    return true
  }
  const intl = controller.getState('intl.content')
  let message = (intl[messageKey] || '')

  if (messageKey === DEFAULT) {
    message = message.replace('{0}', (cost - controller.getState('auth.coins')))
  }

  const openShop = controller.getSequence('app.openShop')
  openShop({ message: message, reason: reason })

  return false
}
