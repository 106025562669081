import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import { savePostsData } from './onGetPosts'

import config from '@/config'
import { isOnlyApprovedPosts } from '../../../utils/ageRating/ageRatingUtil'
import { isModeration } from '../../../utils/moderationUtil'
import controller from '@/controller'
import { callSequence, getSequence, getState, setStoreWrapper } from '../../../utils/StoreManager'
import { getPostData } from '../../../utils/postUtils'

const { ftCoreUrl } = config

//статус процесса загрузки поста
let callBacksMap = new Map()

//статус процесса загрузки поста
let loadingStatus = {}

//простые посты которые принимают общие условия
let simplePosts = []
//особые посты, вроде анкет, тем трансляций, им не нужен учет исключающих тегов и "интересного"
let specialPosts = []

function checkPostLoadNew() {
  setTimeout(() => {
    callSequence('posts.postLoadCheckNew')
  }, 100)
}

export const checkPostLoad = async ({ get, store }) => {
  if (simplePosts.length) {
    startLoadingPosts(get, store, simplePosts, false)
    simplePosts = []
  }
  if (specialPosts.length) {
    startLoadingPosts(get, store, specialPosts, true)
    specialPosts = []
  }
}

const startLoadingPosts = async (get, store, postsToLoad, isSpecial) => {
  let queryString = isModeration
    ? `${ftCoreUrl}/api/v1/posts/moderation?postsIds=${postsToLoad.join()}&lang=${get(state`intl.lang`)}`
    : `${ftCoreUrl}/api/v1/posts?postsIds=${postsToLoad.join()}&lang=${get(state`intl.lang`)}`

  if (isSpecial) {
    queryString += '&withNotInteresting=true'
  } else {
    const tagsIdExcluded = get(state`posts.tagsIdExcluded`) || []
    if (tagsIdExcluded.length) {
      queryString += `&excludeTags=${tagsIdExcluded}`
    }
  }

  queryString += `&limit=${postsToLoad.length}`

  const sid = getSessionId()
  if (sid) {
    queryString += `&sid=${getSessionId()}`
  }

  if (!isOnlyApprovedPosts() || isModeration) {
    queryString += '&withOnModeration=true'
  }

  if (isModeration) {
    queryString += '&withNotApproved=true'
  }

  let checkedPosts = []
  let callBacks
  let result = []
  try {
    const loadingPosts = postsToLoad
    const response = await fetch(queryString)

    if (!response.ok) {
      loadingPosts.forEach(id => {
        delete loadingStatus[id]
        store.set(`posts.postsData.${id}`, { error: 'Not found', postId: id })

        checkedPosts.push(id)

        callBacks = callBacksMap.get(id)
        if (callBacks) {
          callBacksMap.delete(id)
          callBacks.forEach(callbackFunction => callbackFunction({ error: 'Not found', postId: id }))
        }
      })

      return
    }
    result = await response.json()
    loadingPosts.forEach(id => {
      delete loadingStatus[id]
      if (!result.posts.some((arrVal) => arrVal.postId === id)) {
        checkedPosts.push(id)

        store.set(`posts.postsData.${id}`, { error: 'Not found', postId: id })

        callBacks = callBacksMap.get(id)
        if (callBacks) {
          callBacksMap.delete(id)
          callBacks.forEach(callbackFunction => callbackFunction({ error: 'Not found', postId: id }))
        }
      }
    })
  } catch (e) {
    return
  }
  savePostsData({ store, get, props: { posts: result.posts } })

  //вызовем калбеки для индивидуальных постов
  result.posts.forEach(post => {
    checkedPosts.push(post.postId)
    callBacks = callBacksMap.get(post.postId)
    if (callBacks) {
      callBacksMap.delete(post.postId)
      callBacks.forEach(callbackFunction => callbackFunction(post))
    }
  })

  callBacksMap.forEach(function (callBacks, postIds) {
    let postIdsArr = postIds.toString().split(',')
    let allLoaded = true
    postIdsArr.forEach(postId => {
      if (checkedPosts.indexOf(postId) == -1 && !getPostData(postId)) {
        allLoaded = false
      }
    })

    if (allLoaded) {
      if (callBacks) {
        callBacksMap.delete(postIds)
        //TODO тут можно сделать передачу инфы по куче постов
        callBacks.forEach(callbackFunction => callbackFunction())
      }
    }
  })
}

export async function getPostNew(postId, isSpecial, callbackFunction) {
  //если нет поста сразу вызовем калбек
  if (!postId) {
    callbackFunction()
    return
  }

  const postData = controller.getState('posts.postsData')[postId]
  //если пост загружен - вызовем калбек
  if (postData) {
    callbackFunction(postData)
    return
  }

  //здесь остались толкьо посты которые уже грузятся или еще не начали грузиться
  if (!callBacksMap.has(postId)) {
    callBacksMap.set(postId, [])
  }

  //запишем калбек
  callBacksMap.get(postId).push(callbackFunction)

  //если уже грузится - ничего не делаем
  if (loadingStatus[postId]) {
    return
  }

  //если пост еще не грузится - запишем что грузится
  loadingStatus[postId] = true
  if (isSpecial) {
    specialPosts.push(postId)
  } else {
    simplePosts.push(postId)
  }
  checkPostLoadNew()
}

export function loadPostsNew(postsIds, isSpecial, callbackFunction) {
  //если нет поста сразу вызовем калбек
  if (!postsIds || postsIds.length == 0) {
    callbackFunction()
    return
  }

  let loaded = true

  //проверим загруженность постов
  postsIds.forEach(postId => {
    const postData = controller.getState('posts.postsData')[postId]

    //если пост загружен - хорошо
    if (postData) {
      return
    }

    //если уже грузится - ничего не делаем
    if (loadingStatus[postId]) {
      loaded = false
      return
    }

    loaded = false
    //если пост еще не грузится - запишем что грузится
    loadingStatus[postId] = true
    if (isSpecial) {
      specialPosts.push(postId)
    } else {
      simplePosts.push(postId)
    }
  })

  //если все загружено, то калбек
  if (loaded) {
    callbackFunction()
    return
  }

  let postsIdsString = postsIds.toString()

  //здесь остались толкьо посты которые уже грузятся или еще не начали грузиться
  if (!callBacksMap.has(postsIdsString)) {
    callBacksMap.set(postsIdsString, [])
  }

  //запишем калбек
  callBacksMap.get(postsIdsString).push(callbackFunction)

  checkPostLoadNew()
}

// export function getPostData(postId) {
//   return controller.getState('posts.postsData')[postId]
// }

export default ({ get, props, store }) => {
  if (!props.postId ||
    loadingStatus[props.postId] ||
    get(state`posts.postsData.${props.postId}`)) {
    return
  }

  loadingStatus[props.postId] = true
  if (props.isSpecial) {
    specialPosts.push(props.postId)
  } else {
    simplePosts.push(props.postId)
  }

  setTimeout(() => checkPostLoad({ get, store }), 100)
}

export function updatePostData(postId) {
  const postsData = getState('posts.postsData')
  delete postsData[postId]
  setStoreWrapper('posts.postsData', postsData)
  getSequence('posts.getPost')(postId)
  console.log('UPDATED ' + postId)
  // getPostNew(postId, false, ()=>{
  //   console.log('UPDATED')
  // })
}
