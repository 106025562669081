import { isLocalHost } from './urlUtils'

function getConfig(key) {
  try {
    const attribute = document.body.getAttribute('data-server')
    if (!attribute) {
      return null
    }
    const versionConfig = JSON.parse(attribute)
    return versionConfig[key]
  } catch (e) {
    return null
  }
}

export function getLocaleVersion() {
  // console.log('isDev ' + isDev + ' cdne ' + cdnEnable)
  const val = (isLocalHost() || isDev || !cdnEnable) ? Math.random() : getConfig('locales_version')
  if (!val) {
    return ''
  }
  return '?v=' + val
}

let configV
export function getConfigVersion() {
  // console.log('isDev ' + isDev + ' cdne ' + cdnEnable)
  const val = (isLocalHost() || isDev || !cdnEnable) ? (configV || Math.random()) : getConfig('configs_version')
  if (!val) {
    return ''
  }
  configV = val
  return '?v=' + val
}
