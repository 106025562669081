import freePhotoView from './freePhotoView'
import { getPostData } from '../../../utils/postUtils'
import showPost from './showPost'

export default [
    ({ path, props, store }) => {
        const { postId } = props

        const data = getPostData(postId)
        if (!data) {
            return path.doNothing()
        }
        const { postType, subscriptionType } = data

        let { paid } = data
        if (subscriptionType) {
            paid = false
        }

        if (postType === 1) {//video post
            return path.doNothing()
        }

        if (!paid) {
            store.set('posts.buyingPostId', -1)
            return path.freePhotoView({ postId })
        }
    }, {
        freePhotoView,
        doNothing: [ showPost ]
    }
]
