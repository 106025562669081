import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import uploadFile from '../signals/uploadFile'

export default [
  set(state`upload.selectedFile`, props`file`),
  set(state`upload.selectedFilename`, props`filename`),
  set(state`upload.selectedFileUrl`, props`url`),
  set(state`upload.selectedPhotoOrientation`, props`orientation`),
  uploadFile
]
