import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import createPanel from '../../app/signals/createPanel'
import { messageActionsPanel } from '@/components/Panels/types'

export default [
  set(state`chat.selectedMessage`, props`msgData`),
  () => ({ options: messageActionsPanel.options }),
  createPanel,
]
