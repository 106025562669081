import { changePasswordRequest } from '../actions/changePasswordRequest'
import { prepareSnackbarTitle, showSnackbar } from '../../app/factories'
import { props, state } from 'cerebral'
import { panelsOnPop } from '../../app/actions'
import { set, wait } from 'cerebral/factories'

export default [
  changePasswordRequest, {
    success: [
      panelsOnPop,
      wait(300),
      panelsOnPop,
      prepareSnackbarTitle('intl.content.rp_url_sended', 'auth.email'),
      showSnackbar(props`snackbarTitle`, 3000, 'success'),
      set(state`auth.changePasswordForm`, { oldPassword: '', password: '' })
    ],
    error: showSnackbar(props`error`, 3000, 'error'),
    requestError: showSnackbar(state`intl.content.connection_faild`, 3000, 'error')
  }
]
