import { state } from 'cerebral'
import { connect } from '@cerebral/react'
import ls from 'local-storage'
import { getState, setStoreWrapper } from './StoreManager'
import { getMyUid } from './my'

const SyncWays = {
  /////twa params
  appRated: 'app.appRated',
  twa_version: 'app.twa_version',
  twa_app_name: 'app.twa_app_name',
  twa_app_installer: 'app.twa_app_installer',
  ///////
  oneSignalUserId: 'app.oneSignalUserId',
  warningAccepted: 'app.warningAccepted',
  eulaAccepted: 'app.eulaAccepted',
  token: 'auth.token',
  userId: 'auth.uid',
  name: 'auth.name',
  userLang: 'auth.lang',
  loginCount: 'auth.loginCount',
  pushSettings: 'auth.pushSettings',
  showSafariRedirect: 'app.showSafariRedirect',
  currentCamIndex: 'room.currentVideoDeviceIndex',
  currentMicIndex: 'room.currentAudioDeviceIndex',
  currentCamId: 'room.currentVideoDeviceId',
  currentMicId: 'room.currentAudioDeviceId',
  myLikes: 'posts.myLikes',
  viewedPhotos: 'posts.viewedPhotos',
  isHDQualitySelected: 'posts.isHDQualitySelected',
  isLoggedIn: 'auth.isLoggedIn',
  uniqueEvents: 'app.uniqueEvents',
  complaints: 'posts.complaints',
  selectedActs: 'room.selectedActs',
  bannedPrivates: 'room.bannedPrivates',
  lang: 'intl.lang',
  autoTranslation: 'intl.autoTranslation',
  country: 'intl.country',
  bannedCountries: 'intl.bannedCountries',
  readedNotificationsTextKeys: 'chat.readedNotificationsTextKeys',
  //PaymentType: 'payment.pymentTypeUser',
  PaymentData: 'payment.pymentDataUser',
  //IamSponsor: 'sponsors.imSponsor',
  //uRedirectStatus: 'app.uRedirectStatus',
  typeModerFilter: 'moderation.typeModerFilter',

}
const SyncSettings = {}
const allKeys = Object.keys(SyncWays)

allKeys.forEach(key => {
  SyncSettings[key] = state`${SyncWays[key]}`
})

export const LoadState = ({ store }) => {
  try {
    allKeys.forEach(key => {
      const val = ls.get('fm.' + key)
      if (val) {
        store.set(SyncWays[key], val)
        if (key === 'userId') {
          store.set('auth.myLSProps', loadMyLSProps(val))
        }
      }
    })
  } catch (e) {
    console.warn('@@@ load state error:', e)
  }
}

const SaveState = props => {
  try {
    allKeys.forEach(key => {
      const val = ls.get('fm.' + key)
      const newVal = props[key]
      if (val !== newVal) {
        if (typeof newVal === 'boolean' || typeof newVal === 'number') {
          if (newVal) ls.set('fm.' + key, newVal)
          else ls.remove('fm.' + key, newVal)
        } else if (!val) {
          if (newVal) ls.set('fm.' + key, newVal)
        } else {
          if (newVal) ls.set('fm.' + key, newVal)
          else ls.remove('fm.' + key, newVal)
        }
      }
    })
  } catch (e) {
    console.warn('@@@ save state error:', e)
  }
  return null//dont remove, react need it
}
//change it to reaction when we use cerebral 5
export default connect(
  SyncSettings,
  SaveState
)

export function updateLSProps(uid, props) {
  try {
    const userKey = 'custom.' + uid
    ls.set(userKey, props)
  } catch (e) {
    console.warn('@@@ updateLSProps error:', e)
  }
}

export function loadMyLSProps(uid) {
  try {
    const userKey = 'custom.' + uid
    return ls.get(userKey) || {}
  } catch (e) {
    console.warn('@@@ loadMyLSProps error:', e)
  }
  return {}
}

export function dellLSProps(uid) {
  try {
    const userKey = 'custom.' + uid
    ls.remove(userKey)
  } catch (e) {
    console.warn('@@@ dellLSProps error:', e)
  }
}

function getAllMyLSP() {
  return getState('auth.myLSProps') || {}
}

export function getMyLSProp(key) {
  return getAllMyLSP()[key]
}

export function setMyLSProps(key, value) {
  const myLSProps = getAllMyLSP()
  myLSProps[key] = value
  setStoreWrapper('auth.myLSProps', myLSProps)
  updateLSProps(getMyUid(), myLSProps)
}
