import { increment } from 'cerebral/factories'
import { state } from 'cerebral'

export default [
  ({ store, get, props }) => {
    let { type } = props
    const { data } = props
    const filters = get(state`posts.filters`) || []

    if (type === 'all') {
      for (const item of filters) {
        const itemType = Object.keys(item)[0]
        if (item[itemType].find(f => f.id === data.id)) {
          type = itemType
          break
        }
      }
    }

    const gr = filters.find(gr => gr.type)
    if (gr) {
      const grButton = gr.type.find(btn => btn.id === 'live_only')
      if (grButton && grButton.selected) {
        grButton.selected = false
      }
    }

    const group = filters.find(gr => gr[type])
    if (!group) {
      return
    }
    const groupButtons = group[type]
    const isSelected = data.selected

    groupButtons.forEach(btn => {
      if (typeof btn !== 'number') {
        if (btn.selected) {
          btn.selected = false
        }
        if (!isSelected && data.id === btn.id) {
          btn.selected = true
        }
      }
    })

    store.set('posts.filters', filters)
    store.set('posts.loadingPostsStatus', 'not_loaded')
    store.set('posts.topPosts', [])
  },
  increment(state`posts.filterCounter`),
]

export const setDefaultFilter = [
  ({ store, get }) => {
    const filters = get(state`posts.filters`) || []
    /** Дефолты для мегапорна
     *
     if (location.host === 'mega.porn') {
     const typeGroup = filters.find(gr => gr.type)
     const f = typeGroup.type.find(f => f.id === 'video_only')
     f.selected = true

     const periodGroup = filters.find(gr => gr.period)
     const f2 = periodGroup.period.find(f => f.period === 'recently')
     f2.selected = true

     store.set('posts.filters', filters)
     return
     }
     */
    const lastLogin = get(state`auth.storage.logOutTime`) * 1000

    const day = 24 * 60 * 60 * 1000 * 1000
    const dif = (Date.now() - lastLogin) / day

    const group = filters.find(gr => gr.period)
    if (!group) {
      return
    }

    const groupButtons = group.period

    for (let i = 0; i < groupButtons.length; i++) {
      const btn = groupButtons[i]
      const btnNext = groupButtons[i + 1]

      if (btn.selected) {
        btn.selected = false
      }

      if (!btn.setIndefault) {
        btn.setIndefault = 0
      }

      if (btnNext) {
        if (!btnNext.setIndefault) {
          btnNext.setIndefault = 0
        }

        if (btn.setIndefault <= dif && btnNext.setIndefault > dif) {
          if (typeof btn !== 'number') {
            btn.selected = true
          }
          break
        }
      } else {
        if (typeof btn !== 'number') {
          btn.selected = true
        }
      }
    }

    store.set('posts.filters', filters)
  },
  ({ store }) => {
    store.set('posts.loadingPostsStatus', 'not_loaded')
    store.set('posts.topPosts', [])
  }
]
