import { create } from 'zustand'
import { getConfig } from '../utils/urlUtils'
import { isVipStream } from '../modules/posters/showInRoom'
import { getAllCyber, isCyber } from '../utils/cyber/cyberUtil'
import { setDesktopSkin } from './useAppStore'
import {
    EMPTY_I_LIKE,
    TYPE_CYBER, TYPE_DATING, TYPE_FANS,
    TYPE_I_LIKE,
    TYPE_LIVE_ONLY,
    TYPE_MATCH, TYPE_REAL_DATING,
    TYPE_SPONSORS, TYPE_SUBS_POSTS,
    TYPE_SUBSCRIPTIONS, TYPE_VIDEO_ONLY,
    TYPE_VIP
} from '../modules/posts/signals/getPosts'
import { isCyberPostersFeed, noLiveFeed } from '../utils/post/postViewUtil'
import useLivesStore from './useLivesStore'
import { checkMySubscription } from '../utils/subscriptionUtil'
import { isAIDatingApp, isDatingApp } from '../utils/appConfigUtil'
import is from 'is_js'
import { isMobileSkin } from '../utils'

export const COlUMN_W_MAX = 960
const COlUMN_W_CYBER = 440
const COlUMN_W = 300
const MAX_ENDED_LIVES_IN_POSTERS = 1

const MAX_DAIRY_WIDTH = 768

const usePostersStore = create(set => ({
    columnCount: 0,
    columnWidth: 0,
    mainContentWidth: 0,
    postersContent: [],
    postersContentInColumns: [],
    cyberPosters: [],
    livesSetFirstTime: false,
    selectedFeed: undefined,
    loadingState: true,
    dairyColumnCount: 1,
    dairyColumnW: 240,
    calculate: () => {
        let w = window.innerWidth
        const desktopSkin = window.innerWidth > 930 && window.innerHeight > 450
        setDesktopSkin(desktopSkin)
        if (desktopSkin) {
            w -= getConfig('sidebarWidth')
        }
        if (is.desktop()){
            w -= 16
        }

        const { selectedFeed } = usePostersStore.getState()
        const defaultCW = isCyberFeed(selectedFeed) ? COlUMN_W_CYBER : COlUMN_W
        const columnCount = isOneColumnFeed(selectedFeed) ? 1 : Math.ceil(w / defaultCW)
        const columnWidth = columnCount === 1 ? (Math.min(w, COlUMN_W_MAX) - 32) : Math.floor((w - 32 - 16 * (columnCount - 1)) / columnCount)

        const dairyW = w > MAX_DAIRY_WIDTH ? MAX_DAIRY_WIDTH : w - 32
        const dairyColumnCount = isMobileSkin() ? 1 : Math.ceil(dairyW / COlUMN_W)
        const dairyColumnW = Math.floor((dairyW - 16 * (dairyColumnCount - 1)) / dairyColumnCount)

        const needReset = usePostersStore.getState().columnCount !== columnCount
        set({ columnCount, columnWidth, mainContentWidth: w, dairyColumnW, dairyColumnCount })
        if (needReset) {
            usePostersStore.getState().resetPostersContent()
        }
    },
    resetPostersContent: () => {
        const columns = []
        const { postersContent, columnCount, setLoadingState } = usePostersStore.getState()

        if (!columnCount) {
            return
        }
        for (let i = 0; i < columnCount; i++) {
            columns.push([])
        }
        if (setLoadingState) {
            for (let i = 0; i < 30; i++) {
                columns[i % columnCount].push('loading')
            }
        } else {
            if (postersContent.length) {
                for (let i = 0; i < postersContent.length; i++) {
                    columns[i % columnCount].push(postersContent[i])
                }
            } else {
                columns[0].push(EMPTY_I_LIKE)
            }
        }

        set({ postersContentInColumns: columns })
    },
    setLivesInPosters: (allLives, fullReset) => {
        const {
            postersContent,
            livesSetFirstTime,
            setPostersContent,
            addPostersContent,
            cyberPosters,
            clearPostersContent,
            selectedFeed
        } = usePostersStore.getState()

        //если в ленте не должно быть лайвов то ничего не делаем
        if (noLiveFeed(selectedFeed)) {
            if (isDatingApp() && !livesSetFirstTime) {
                set({ livesSetFirstTime: true })
            }
            return
        }

        //если это экран с приоритетом лайвов, то посмотрим сколько из них закончилось,
        // если закончилось больше N то сделаем полное перестроение
        if (selectedFeed === TYPE_LIVE_ONLY && livesSetFirstTime) {
            //проверим закончившиеся лайвы, это отрисованные постеры не киберов и не в текущих лайвах
            const ended = postersContent.filter(uid => !isCyber(uid) && !allLives.includes(uid))
            //полный ресет если кончились
            if (ended && ended.length > MAX_ENDED_LIVES_IN_POSTERS) {
                fullReset = true
                clearPostersContent()
            }
        }

        //найдем новые лайвы и сразу поделим их на VIP и обычные, в афише мы будем их чередовать
        let newLives = (livesSetFirstTime && !fullReset) ? allLives.filter(uid => !postersContent.includes(uid)) : allLives

        //если это состояние "от подписок добавим фильтр"
        if (selectedFeed === TYPE_SUBSCRIPTIONS) {
            newLives = newLives.filter(uid => checkMySubscription(uid))
        } else if (selectedFeed === TYPE_VIP) {
            newLives = newLives.filter(uid => isVipStream(uid))
        }
        if (!newLives.length && livesSetFirstTime) {
            return
        }
        const vipLives = []
        const simpleLives = []
        newLives.forEach(uid => {
            if (isVipStream(uid)) {
                vipLives.push(uid)
            } else {
                simpleLives.push(uid)
            }
        })
        const result = []
        const maxLength = Math.max(vipLives.length, simpleLives.length)
        for (let i = 0; i < maxLength; i++) {
            if (i < simpleLives.length) {
                result.push(simpleLives[i])
            }
            if (i < vipLives.length) {
                result.push(vipLives[i])
            }
        }

        if (livesSetFirstTime && !fullReset) {
            addPostersContent(result)
        } else {
            if (isCyberPostersFeed(selectedFeed)) {
                setPostersContent(result.concat(cyberPosters))
            } else {
                setPostersContent(result)
            }
        }
        if (!livesSetFirstTime) {
            set({ livesSetFirstTime: true })
        }
    },
    setCyberPosters: cyberPosters => {
        const {
            selectedFeed,
            livesSetFirstTime,
            addPostersContent,
            setPostersContent
        } = usePostersStore.getState()

        set({ cyberPosters })

        //если в ленте нет кибер постеров, то ничего не делаем
        if (!isCyberPostersFeed(selectedFeed)) {
            return
        }
        if (livesSetFirstTime) {
            addPostersContent(cyberPosters)
        } else {
            if (noLiveFeed(selectedFeed)) {
                setPostersContent(cyberPosters)
            }
        }
    },
    setPostersContent: allContent => {
        set({ postersContent: allContent, setLoadingState: false })
        usePostersStore.getState().resetPostersContent()
    },
    addPostersContent: newContent => {
        if (!newContent.length) {
            set({ setLoadingState: false })
            usePostersStore.getState().resetPostersContent()
            return
        }
        const { postersContent } = usePostersStore.getState()
        set({ postersContent: [...postersContent, ...newContent], setLoadingState: false })
        usePostersStore.getState().resetPostersContent()
    },
    setSelectedFeed: feed => {
        const {
            selectedFeed,
            clearPostersContent,
            setPostersContent,
            calculate,
            livesSetFirstTime,
            setLivesInPosters
        } = usePostersStore.getState()
        if (feed === selectedFeed) {
            return
        }
        clearPostersContent()

        //для киберов не нужна загрузка
        if (isCyberFeed(feed)) {
            setPostersContent(getAllCyber())
            set({ selectedFeed: feed })
            calculate()
            return
        }
        if (feed === TYPE_LIVE_ONLY && livesSetFirstTime) {
            set({ selectedFeed: feed, postersContent: [] })
            calculate()
            setLivesInPosters(useLivesStore.getState().postersVisibleLives, true)
            return
        }
        set({ selectedFeed: feed, setLoadingState: true })
        calculate()
        usePostersStore.getState().resetPostersContent()
        if (!noLiveFeed(feed) && livesSetFirstTime) {
            setLivesInPosters(useLivesStore.getState().postersVisibleLives, true)
        }
    },
    setLoadingState: loadingState => {
        set({ loadingState: loadingState })
    },
    clearPostersContent: () => {
        set({ postersContent: [] })
    }
}))

export const useSetSelectedFeed = feed => {
    return usePostersStore.getState().setSelectedFeed(feed)
}

export function setNewSize() {
    usePostersStore.getState().calculate()
}

export function isCyberFeed(feed) {
    return feed === TYPE_CYBER
}

export function isLikesEditFeed(feed) {
    return feed === TYPE_MATCH ||
        feed === TYPE_I_LIKE
}

export function isDatingFeed(feed) {
    return feed === TYPE_REAL_DATING ||
        feed === TYPE_MATCH
}

export function isLoadOnScroll(feed) {
    return feed !== TYPE_CYBER || feed === TYPE_LIVE_ONLY
}

export function isOneColumnFeed(feed) {
    if (isMobileSkin() && (
        feed === TYPE_VIP ||
        feed === TYPE_VIDEO_ONLY ||
        feed === TYPE_SUBS_POSTS
    )) {
        return  true
    }
    return isLikesEditFeed(feed) ||
        feed === TYPE_SPONSORS ||
        feed === TYPE_REAL_DATING ||
        feed === TYPE_FANS
}

export default usePostersStore
