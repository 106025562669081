import { state } from 'cerebral'

export const selectSponsors = [
  ({ store, get }) => {
    if (!get(state`sponsors.selectedSponsors`)) {
      store.set('sponsors.selectedSponsors', true)
      const filters = get(state`posts.filters`)
      filters.map(item => {
        const groupId = Object.keys(item)[0]
        item[groupId].map(i => {
          if (i.selected === true) {
            i.selected = false  
          }  
        })
      })
      store.set('posts.filters', filters)
      store.set('posts.selectedTags', [])
      store.set('posts.filterCounter', get(state`posts.filterCounter`) + 1)
    } else {
      store.set('sponsors.selectedSponsors', false)
      store.set('sponsors.data', [])
      store.set('sponsors.page', 0)
      store.set('posts.filterCounter', get(state`posts.filterCounter`) + 1)
    }
  },  
]

export const deactivateSponsors = [
  ({ store, get }) => {
    store.set('sponsors.selectedSponsors', false)
    store.set('sponsors.data', [])
    store.set('sponsors.page', 0)
    store.set('posts.filterCounter', get(state`posts.filterCounter`) + 1)
  }
]
